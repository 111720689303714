import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-radio',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ejs-radiobutton
      *ngFor="let option of to.options | formlySelectOptions: field | async"
      [formControl]="$any(formControl)"
      [label]="option.label"
      [value]="option.value"
      [name]="field.name || id"
      cssClass="e-success"
      [class.ng-dirty]="showError"
      (onChange)="to.change && to.change(field, $event)"
    ></ejs-radiobutton>
  `,
  styles: [
    `
      :host {
        display: grid;
        gap: 6px;
      }
    `
  ]
})
export class FormlyFieldRadio extends FieldType {
  // TODO: WHY?
  defaultOptions = {
    props: { options: [] },
  };
}
