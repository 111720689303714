import { AbstractControl } from '@angular/forms';
import { translate } from '@ngneat/transloco';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Adresa, Dite, MonthFlags, Uplatneni } from '@tpa/shared';

//// helper fuxtion
const groupBy = <T, K extends keyof any>(
  list: T[],
  getKey: (item: T) => K
): Record<K, T[]> =>
  list.reduce((previous, currentItem) => {
    const group = getKey(currentItem);
    if (!previous[group]) previous[group] = [];
    previous[group].push(currentItem);
    return previous;
  }, {} as Record<K, T[]>);
///


export function validWagePaymentMethod(control: AbstractControl, formState: FormlyFieldConfig) {
  const options = formState?.props.options as { label: string, value: string }[] | undefined | null ?? [];

  const opt = !!options.find(x => x.value == control?.value)

  /*
   NOTE: empty array is workaround to employee card overview where option is empty
    setting in company force user to have at lease one option co empty array means
    only user readonly view
  */
  if (opt || options.length === 0)
    return null

  return {
    fieldMatch: {
      message: `${translate('TPA.EmployeeCardForm.ChooseValidOption',
      )}`,
    },
  };
}


export function validateMoreOfOneChildInChildren(control: AbstractControl) {
  const deti = control.value as Dite[];
  if (deti == null || deti.length === 0) return null;

  const grouped = groupBy(deti, (p) => p.rodneCisloADatumNarozeni?.rodneCislo ??
    `${p.jmeno}_${p.prijmeni}_${p.rodneCisloADatumNarozeni?.datumNarozeni}`
    ?? '');

  const validationTest = Object.keys(grouped)
    .map((key) => {
      const dite = grouped[key];
      return {
        jmeno: dite[0].jmeno,
        prijmeni: dite[0].prijmeni,
        vicekrat: dite.length > 1,
      };
    })
    .find((p) => p.vicekrat == true);

  if (validationTest == null) {
    return null;
  }

  return {
    fieldMatch: {
      message: `${translate('TPA.SameChildMistake{0}{1}', {
        '0': validationTest.jmeno,
        '1': validationTest.prijmeni,
      })}`,
    },
  };
}

/**
 * Validace pro hlidani uplnatneni slevy na
 * stejné díte v jednom mesíci podle RD
 */

export function validateOneChildApplyInAMonth(control: AbstractControl) {
  const uplatneni = control.value as Uplatneni[];
  if (!!uplatneni || uplatneni.length == 0) return null;
  const slevy = uplatneni
    .map((p) => p?.mesice)
    .filter((a) => a !== undefined) as MonthFlags[];
  const validationTest =
    slevy.reduce((a, b) => a | b) == slevy.reduce((a, b) => a + b);
  if (validationTest == null) return null;
  if (!validationTest) {
    return {
      fieldMatch: {
        message: `${translate('TPA.ChildCannotSameMonth')}`,
      },
    };
  } else {
    return null;
  }
}


export function validationAtLeastOneItem(control: AbstractControl) {
  const value = control.value as any[];
  if (value && value.length && value.length > 0) return null;

  return {
    fieldMatch: {
      message: `${translate('TPA.AtLeastOneItem')}`,
    },
  };
}
/**
 * Validace pro hlidani uplnatneni slevy na
 * stejné díte v jednom mesíci podle RD
 */

export function validateCzechRepublicAddress(control: AbstractControl) {
  const adress = control.value as Adresa;
  if (adress.zeme !== 'CZ') {
    return {
      fieldMatch: {
        message: `${translate('TPA.AddressMustBeCzechRepublic')}`,
      },
    };
  } else {
    return null;
  }
}

/**
 * Validace pro hlidani uplnatneni slevy na
 * stejné díte v jednom mesíci podle RD
 */

export function validateNonRezidentHasToBeOutsideCzech(
  control: AbstractControl
) {
  const rezidentura = control?.parent?.value as
    | null
    | undefined
    | {
      rezidentura: 'true' | 'false' | 'konzultace' | undefined | null;
      rezidenturaVeState: string | undefined | null;
    };


  if (
    rezidentura?.rezidentura == 'false' &&
    rezidentura?.rezidenturaVeState == 'CZ'
  ) {
    return {
      fieldMatch: {
        message: `${translate('TPA.PickDifferentStateForNonResident')}`,
      },
    };
  } else {
    return null;
  }
}
