import { InfoPrintTemplate } from "../models/info-template.model";
import { EducationLevelPipe, GenderPipe, HealthDisadvantagePipe, MaritalStatusPipe, SalaryPipe, YesOrNoPipe } from "../pipes";
import { EmpTabsFormModelV2 } from "./employee.types";
import { formatDate, getCountry, toPrintAddress } from './print-formatters.util';

interface Address {
  mesto: string;
  zeme: string;
  ulice: string;
  popCislo: string;
  orCislo: string;
  psc: string;
}

export const employeeCardToInfoTemplate = (
  data: EmpTabsFormModelV2 | null): InfoPrintTemplate => {
  if (data === null || data === undefined) {
    return null;
  }



  const salaryPipe = new SalaryPipe()
  const educationPipe = new EducationLevelPipe();
  const healthPipe = new HealthDisadvantagePipe();
  const genderPipe: GenderPipe = new GenderPipe();
  const maritalStatusPipe: MaritalStatusPipe = new MaritalStatusPipe();
  const yesornoPipe: YesOrNoPipe = new YesOrNoPipe();
  return {
    jmeno: data.personalPart?.personalInfo?.jmeno ?? '',
    prijmeni: data.personalPart?.personalInfo?.prijmeni ?? '',
    rodnePrijmeni: data.personalPart?.personalInfo?.rodnePrijmeni ?? '',
    datumNarozeni: formatDate(data.personalPart?.personalInfo?.rodneCisloADatumNarozeni?.datumNarozeni) ?? '',
    mistoNarozeni: data.personalPart?.personalInfo?.mistoNarozeni ?? '',
    rodneCislo: data.personalPart?.personalInfo?.rodneCisloADatumNarozeni?.rodneCislo,
    pohlavi: genderPipe.transform(data.personalPart?.personalInfo?.pohlavi) ?? '',
    rodinnyStav: maritalStatusPipe.transform(data.personalPart?.personalInfo?.rodinnyStav) ?? '',
    vzdelani: educationPipe.transform(data.personalPart?.personalInfo?.vzdelani) ?? '',
    statniPrislusnost: getCountry(data.personalPart?.personalInfo?.statniPrislusnost),
    permanentniAdresa: toPrintAddress(data.personalPart?.permanentniAdresa),
    kontaktniAdresa: addressesEqual(data.personalPart?.permanentniAdresa, data.personalPart?.kontaktniAdresa) ? toPrintAddress(data.personalPart?.kontaktniAdresa) : null,
    typVyplaty: salaryPipe.transform(data.personalPart?.wage?.salaryForm) ?? '',
    typVyplatyHotovost: data.personalPart?.wage?.salaryForm === 'cash',
    ucet: data.personalPart?.wage?.cisloUctu + '/' + data.personalPart?.wage?.idUstavu,
    zdravotniPojistovna: data.personalPart?.health?.zdravPojistovna + '',
    cisloPojistence: data.personalPart?.health?.cisloPojistence ?? '',
    zdravotniZnevyhodneni: healthPipe.transform(data.personalPart?.health?.zdravotniZnevyhodneni) ?? '',
    dalsiZamestnavatel: data.personalPart?.dalsiPracovniPomer?.dalsiPracovniPomer
      ? {
        nazev: data.personalPart?.dalsiPracovniPomer?.nazevDalsihoZamestnavatele ?? '',
        sidlo: data.personalPart?.dalsiPracovniPomer?.sidloDalsihoZamestnavatele ?? ''
      }
      : null,
    exekuce: yesornoPipe.transform(data.personalPart?.execution?.exekuce)
  }
}

function addressesEqual(a1: Partial<Address>, a2: Partial<Address>) {
  if (a1.ulice == a2.ulice &&
    a1.mesto == a2.mesto &&
    a1.orCislo == a2.orCislo &&
    a1.popCislo == a2.popCislo &&
    a1.psc == a2.psc &&
    a1.zeme == a2.zeme) {
    return true
  }
  return false
}