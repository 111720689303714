import { ChangeDetectionStrategy, Component, inject, ChangeDetectorRef, TemplateRef, AfterContentInit } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'template-wrapper',
  template: `

    <ng-container *ngIf="templateRef">
      <ng-container *ngTemplateOutlet="templateRef!"></ng-container>
    </ng-container>
    <ng-container #fieldComponent></ng-container>
  `,
  styles: [``],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplateWrapperComponent extends FieldWrapper implements AfterContentInit {
  cdr = inject(ChangeDetectorRef);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  templateRef: TemplateRef<any> | undefined;

  ngAfterContentInit() {
    setTimeout(() => {
      this.templateRef = this.props['templateWrapper']?.templateRef();
      this.cdr.markForCheck();
    });
  }
}
