export type TaxDeclaration2024PrintTemplate = {
  data: TaxDeclaration2024PrintTemplateData
  text: TaxDeclaration2024PrintTemplateText
}


export type TaxDeclaration2024PrintTemplateData = {
  rok: string
  dodatecneCinim: boolean
  nazevPlatceDane: string
  adresaPlatceDane: string
  identifikacePoplatnika: {
    prijmeni: string
    jmeno: string
    rodneCislo: string
    adresaBydliste: string
  }
  nerezident: {
    datumNarozeni: string
    cisloATypDokladu: string
    dokladStat: string
    identifikace: string
    statRezidence: string
  } | null
  slevy: {
    poplatnik: string // X or XD + months (9-12)
    zakladniInvalidita: string // X or XD + months (9-12)
    rozsirenaInvalidita: string // X or XD + months (9-12)
    ztpp: string // X or XD + months (9-12)
  }
  detiUplatnuji: boolean;
  vyzivovaneDeti: {
    jmeno: string
    prijmeni: string
    rodneCislo: string
    uplatnujiNarok: string // 1 2 3 N
    ztpp: string
    dospelost: string
    dodatecneMesice: string
  }[] | null
  vyzivujeJinyPoplatnik: boolean
  jinyPoplatnik: {
    jmeno: string,
    prijmeni: string
    rodneCislo: string
    adresa: string
    uplatnuje: string // ANO | NE
    zamestnani: string // ANO | NE
    zamestnavatel: {
      nazev: string
      adresa: string
    } | null
  } | null
  podpisPlatce: {
    jmenoAPrijmeni: string
    datum: string
  }
  podpisMzdovka: {
    jmenoAPrijmeni: string
    datum: string
  } | null
  zmeny: {
    druh: string,
    mesic: string
    oznamenaPoplatnikemADatum: string
    overeniPlatcemADatum: string
  }[] | null
  vyzivovaneDetiZmena: {
    jmeno: string
    prijmeni: string
    rodneCislo: string
    uplatnujiNarok: string // 1 2 3 N
    ztpp: string
    dospelost: string
  }[] | null
}

export type TaxDeclaration2024PrintTemplateText = {
  heading: string
  declaration: string
  additionally: string
  additionallySecond: string
  taxpayer: {
    name: string,
    address: string
    identification: string
    surname: string
    firstName: string
    birthNumber: string
    permanentResidence: string
    nonresident: string
    birthDate: string
    numberAndTypeOfDocument: string
    documentIssuerCountry: string
    taxId: string
    residenceCountry: string
    firstNote: string
    secondNote: string
  }
  discounts: {
    title: string
    taxpayer: string
    basicInvalidity: string
    advancedInvalidity: string
    ztpp: string
    firstNote: string
  }
  children: {
    additionally: string
    additionallySecond: string
    paragraph: string
    firstName: string
    surname: string
    birthNumber: string
    apply: string
    ztpp: string
    adult: string
    months: string
    firstNote: string
    secondNote: string
    thirdNote: string
  }
  otherTaxpayer: {
    firstParagraph: string
    secondParagraph: string
    no: string
    firstName: string
    surname: string
    birthNumber: string
    address: string
    apply: string
    employment: string
    employerName: string
    employerAddress: string
    firstNote: string
    secondNote: string
    thirdNote: string
    thirdParagraph: string
    fourthParagraph: string
  }
  signature: {
    title: string
    firstParagraph: string
    taxpayer: string,
    admin: string
    term: string
    taxpayerSigned: string
    adminSigned: string
    additionally: string
    firstNote: string
    secondNote: string
  }
  changes: {
    title: string
    firstParagraph: string
    type: string
    month: string
    announced: string
    authenticated: string
    firstNote: string
    secondParagraph: string
    firstName: string
    surname: string
    birthNumber: string
    apply: string
    ztpp: string
    adult: string
    secondNote: string
    caution: string
  }
}

export const taxDeclaration2024CzechModel: TaxDeclaration2024PrintTemplateText = {
  heading: 'PROHLÁŠENÍ',
  declaration: 'poplatníka daně z příjmů fyzických osob ze závislé činnosti <br /> pro zdaňovací období (pro část zdaňovacího období)',
  additionally: 'Prohlášení k dani činím / dodatečně činím',
  additionallySecond: 'podle § 38k zákona č. 586/1992 Sb., o daních z příjmů, ve znění pozdějších předpisů (dále jen „zákon“) pro plátce daně (zaměstnavatele)',
  taxpayer: {
    name: 'Název plátce daně',
    address: 'Adresa',
    identification: 'Identifikace poplatníka ',
    surname: 'Příjmení',
    firstName: 'Jméno(-a)',
    birthNumber: 'Rodné číslo',
    permanentResidence: 'Adresa bydliště (místo trvalého pobytu)',
    nonresident: 'Daňový nerezident ČR dále vyplní:',
    birthDate: 'Datum narození',
    numberAndTypeOfDocument: 'Číslo a typ dokladu prokazující totožnost poplatníka',
    documentIssuerCountry: 'Stát, který tento doklad vydal',
    taxId: 'Identifikace pro daňové účely ve státu daňové rezidence',
    residenceCountry: 'Stát, jehož jste daňovým rezidentem',
    firstNote: 'použijte např. při dalším nástupu ke stejnému plátci daně v průběhu uvedeného zdaňovacího období (např „od září RRRR“)',
    secondNote: 'označte „ <strong> XD </strong>“, pokud činíte prohlášení k dani dodatečně podle § 38k odst. 7 zákona v rámci žádosti o roční zúčtování záloh a daňového zvýhodnění'
  },
  discounts: {
    title: 'Podle § 35ba odst. 1 zákona uplatňuji',
    taxpayer: '<strong> Základní slevu na poplatníka </strong> (písm. a)',
    basicInvalidity: '<p> <strong> Základní slevu na invaliditu </strong> (písm. c) </p> <p class="text-sm">a prohlašuji, že mi byl přiznán invalidní důchod pro invaliditu I. nebo II. stupně z důchodového pojištění podle zákona o důchodovém pojištění nebo že mi zanikl nárok na invalidní důchod pro invaliditu I. nebo II. stupně z důvodu souběhu nároku na výplatu tohoto invalidního důchodu a starobního důchodu</p>',
    advancedInvalidity: ' <p> <strong> Rozšířenou slevu na invaliditu </strong> (písm. d) </p> <p class="text-sm">a prohlašuji, že mi byl přiznán invalidní důchod pro invaliditu III. stupně nebo jiný důchod z důchodového pojištění podle zákona o důchodovém pojištění, u něhož jednou z podmínek přiznání je, že jsem invalidní ve III. stupni, zanikl mi nárok na invalidní důchod pro invaliditu III. stupně z důvodu souběhu nároku na výplatu invalidního důchodu pro invaliditu III. stupně a starobního důchodu nebo že jsem podle zvláštních předpisů invalidní ve III. stupni, avšak žádost o invalidní důchod pro invaliditu III. stupně mi byla zamítnuta z jiných důvodů než proto, že nejsem invalidní ve III. stupni </p>',
    ztpp: '<p> <strong> Slevu na držitele průkazu ZTP/P </strong> (písm. e) </p> <p class="text-sm">a prohlašuji, že mi byl přiznán nárok na průkaz ZTP/P</p>',
    firstNote: 'označte „<strong>X</strong>“ slevu na dani, kterou uplatňujete. Uplatňujete-li slevu na dani až dodatečně, tj. v rámci ročního zúčtování záloh a daňového zvýhodnění, označte předmětnou slevu na dani „<strong>XD</strong>“ a do stejného políčka uveďte i kalendářní měsíce, za které příslušnou slevu na dani dodatečně uplatňujete (např. „XD 9–12“)'
  },
  children: {
    additionally: 'Podle § 35c a § 35d zákona uplatňuji /',
    additionallySecond: 'dodatečně uplatňuji <sup> 5) </sup> daňové zvýhodnění',
    paragraph: '<p> na mnou vyživované dítě/děti podle § 35c odst. 6 zákona a podle § 38k odst. 4 písm. c) zákona uvádím v níže uvedené tabulce, <strong>jaký je počet těchto vyživovaných dětí</strong> žijících v mé společně hospodařící domácnosti na území členského státu EU nebo EHP a současně uvádím,<strong> na které děti uplatňuji/ neuplatňuji</strong> daňové zvýhodnění ve výši náležející podle § 35c odst. 1 zákona </p>',
    firstName: 'Jméno(-a)',
    surname: 'Příjmení',
    birthNumber: 'Rodné číslo',
    apply: 'Uplatňuji/neuplatňuji nárok ve výši na',
    ztpp: 'ZTP/P',
    adult: 'Zletilé dítě',
    months: 'Dodatečně <br /> za kalendářní <br /> měsíce',
    firstNote: 'tabulku vyplňte pouze v případě, že uplatňujete daňové zvýhodnění. Do tabulky vyplňte všechny Vámi vyživované děti žijící ve Vaší společně hospodařící domácnosti, a to bez ohledu na jejich další uplatnění. Dále děti, na které uplatňujete daňové zvýhodnění označte podle výše nároku: na jedno dítě označte „<strong>1.</strong>“, na druhé dítě označte „<strong>2.</strong>“, na třetí a každé další vyživované dítě označte „<strong>3.</strong>“, a děti, na které daňové zvýhodnění neuplatňujete, označte „<strong>N</strong>“',
    secondNote: 'dále u jednotlivých uplatňovaných dětí označte „<strong>X</strong>“ splněnou podmínku – tzn. u sloupce ZTP/P vyznačte, kterému z dětí byl přiznán nárok na průkaz ZTP/P a u sloupce „Zletilé dítě“ označte „<strong>X</strong>“, které z dětí je zletilé (do 26 let) a není mu přiznán invalidní důchod pro invaliditu III. stupně a splňuje další podmínky uvedené v § 35c odst. 6 písm. b) bod 1. až 3. zákona',
    thirdNote: 'pokud uplatňujete daňové zvýhodnění až dodatečně v rámci žádosti o roční zúčtování záloh a daňového zvýhodnění, pak v nadpisu této části označte „<strong>XD</strong>“, a zároveň vyplňte v posledním sloupci tabulky kalendářní měsíce, za které dodatečně uplatňujete daňové zvýhodnění ( např. 9–12)'
  },
  otherTaxpayer: {
    firstParagraph: '<h2 class="mb-1 text-center text-2xl font-bold">Podle § 38k odst. 4 zákona prohlašuji, že:</h2> <p>a) současně <strong>neuplatňuji</strong> za stejné zdaňovací období ani za stejný kalendářní měsíc zdaňovacího období <strong>nárok na slevy na dani podle § 35ba zákona</strong> u jiného plátce daně a že současně na stejné období kalendářního roku jsem neučinil(-a) u jiného plátce daně prohlášení k dani,</p> <p> b) současně za stejné zdaňovací období ani za stejný kalendářní měsíc zdaňovacího období <strong>neuplatňuji daňové zvýhodnění</strong> na mnou vyživované dítě u jiného plátce daně a že daňové zvýhodnění na to samé vyživované dítě neuplatňuje jiná osoba, </p>',
    secondParagraph: '<p> c) v rámci téže společně hospodařící domácnosti <strong>vyživuje tytéž mnou vyživované děti i jiný poplatník ANO',
    no: 'NE',
    firstName: 'Jméno(-a)',
    surname: 'Příjmení',
    birthNumber: 'Rodné číslo',
    address: 'Adresa bydliště (místo trvalého pobytu)',
    apply: 'Uplatňuje daňové zvýhodnění ANO/NE',
    employment: 'Zaměstnání ANO/NE',
    employerName: 'Název plátce daně',
    employerAddress: 'Adresa plátce daně',
    firstNote: 'pokud daňové zvýhodnění neuplatňujete, bod c) ani tabulku nevyplňujte',
    secondNote: 'za předpokladu, že ve společně hospodařící domácnosti vyživuje děti pouze jeden poplatník, který zároveň uplatňuje daňové zvýhodnění, označte křížkem variantu <strong>NE a tabulku nevyplňujte</strong>. Tabulku vyplňte vždy, pokud křížkem označíte variantu <strong>ANO</strong>,tzn., že v téže společně hospodařící domácnosti vyživuje děti i jiný poplatník. Uveďte identifikační údaje druhého poplatníka a vyberte zvolenou variantu – zda druhý z poplatníků uplatňuje či neuplatňuje daňové zvýhodnění',
    thirdNote: 'pokud je druhý z poplatníků také zaměstnán (ve sloupci „Zaměstnání“ je uvedeno „ANO“), pak bez ohledu na skutečnost, zda tento poplatník u svého plátce daně (zaměstnavatele) uplatňuje či neuplatňuje daňové zvýhodnění – uveďte vždy název a adresu tohoto plátce daně',
    thirdParagraph: '<strong>Dojde-li během zdaňovacího období ke změně skutečností</strong> rozhodných pro výpočet záloh na daň a daně nebo ke změně podmínek pro poskytnutí slev na dani, nebo daňového zvýhodnění, oznámím v souladu s § 38k odst. 8 zákona tuto změnu plátci daně (použijte např. změnové tabulky) nejpozději poslední den kalendářního měsíce, v němž změna nastala nebo v němž bylo o změně rozhodnuto.',
    fourthParagraph: 'Jsem si <strong>vědom(-a)</strong> následků nepravdivého prohlášení, a jsem si vědom(-a) toho, že vzniklý rozdíl z nesprávně sražené zálohy na daň z příjmů fyzických osob ze závislé činnosti a neoprávněně vyplaceného daňového bonusu, který vznikl mým zaviněním, bude v plné výši spolu s úrokem z prodlení sražen z mé mzdy.',
  },
  signature: {
    title: 'Podpisová část',
    firstParagraph: 'Potvrzuji pravdivost a úplnost údajů uvedených v tomto prohlášení a prokazuji je příslušnými doklady podle § 38l zákona',
    taxpayer: 'Prokazatelně učiněné prohlášení poplatníkem <sup>8)</sup> (písemně, elektronicky) a datum',
    admin: 'Ověření plátcem daně <sup>9)</sup> (písemně, elektronicky) a datum',
    term: 'Na uvedené zdaňovací období',
    taxpayerSigned: 'elektronicky podepsáno aplikací HR Portál',
    adminSigned: 'Elektronicky ověřeno aplikací HR Portál',
    additionally: 'Dodatečně za uvedené zdaňovací období (podle § 38k odst. 7 zákona)',
    firstNote: 'rozumí se projev vůle poplatníka, buď písemně (podpis) nebo elektronicky',
    secondNote: 'plátce daně ověřil nárok na slevy na dani podle § 35ba zákona a nárok na daňové zvýhodnění podle § 35c a § 35d zákona'
  },
  changes: {
    title: 'Změnová část',
    firstParagraph: 'Podle § 38k odst. 8 zákona oznamuji změny v již uvedených skutečnostech pro uvedené zdaňovací období',
    type: 'Druh změny',
    month: 'Kalendářní měsíc, v němž změna nastala nebo v němž bylo o změně rozhodnuto',
    announced: 'Změna oznámena poplatníkem <sup>10)</sup> (písemně, elektronicky) a datum',
    authenticated: 'Ověření plátcem daně <sup>9) 10)</sup>(písemně, elektronicky) a datum',
    firstNote: 'rozumí se podpis včetně datumu podpisu a vztahuje se i ke změnové tabulce na daňové zvýhodnění',
    secondParagraph: 'V případě, že <strong>u daňového zvýhodnění</strong> dochází v průběhu roku ke změně ve vztahu k nároku na „jedno dítě, druhé dítě, třetí a každé další vyživované dítě“, vyplňte současně s oznámenou změnou i níže uvedenou změnovou tabulku a aktualizujte výčet všech Vámi vyživovaných dětí (ze strany 1) podle § 35c odst. 6 zákona žijících ve Vaší společně hospodařící domácnosti',
    firstName: 'Jméno(-a)',
    surname: 'Příjmení',
    birthNumber: 'Rodné číslo',
    apply: 'Uplatňuji/neuplatňuji nárok ve výši na',
    ztpp: 'ZTP/P',
    adult: 'Zletilé dítě',
    secondNote: 'výši Vámi uplatňovaného nového nároku na daňové zvýhodnění označte: na jedno dítě „<strong>1.</strong>“, na druhé dítě „<strong>2.</strong>“, na třetí a každé další vyživované dítě „<strong>3.</strong>“, a dítě, na které daňové zvýhodnění neuplatňujete, označte „<strong>N</strong>“',
    caution: '<strong>Upozornění:</strong> V případě, že v souladu s § 38k odst. 7 zákona uplatňujete slevy na dani podle § 35ba odst. 1 písm. a, c, d, e) příp. daňové zvýhodnění dodatečně v rámci žádosti o roční zúčtování záloh a daňového zvýhodnění, pak označte dodatečně uplatněnou slevu na dani (případně daňové zvýhodnění) „XD“ a zároveň uveďte kalendářní měsíce, za které dodatečně uplatňujete (např. 9–12). Kalendářní měsíce neuvádějte u slevy na poplatníka. Pro účely dodatečného uplatnění lze použít i nový tiskopis MFin 5457. Nepoužívejte změnovou část, která je určena pouze pro změny, které nastaly v průběhu zdaňovacího období, tzn. před žádostí o roční zúčtování záloh a daňového zvýhodnění.'
  }
}