import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'formly-wrapper-panel',
  template: `

      <div class="e-card">
        <div class="e-card-content">
          <ng-container #fieldComponent></ng-container>
        </div>
      </div>

  `,
  styles: [``],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WhiteBoxWrapperComponent extends FieldWrapper { }
