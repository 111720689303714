export const RodneCislo = (label: string) => ({
  type: 'input',
  props: {
    label: label,
    placeholder: 'XXXXXX/XXXX'
  },
  validators: {
    validation: [{ name: 'validateRodneCislo' }]
  }
});

export const specialCharsParser = (value: string | null | undefined) => {
  if (!value) return value;
  value = value.replace(/[\t\r\n]/g, '');
  value = value.trim();
  return value;
};
