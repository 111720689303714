import { FormlyConfig, FormlyFieldConfig } from '@ngx-formly/core';

import { DateOnly, RodneCislo } from '@tpa/api';
import { Observable } from 'rxjs';
import { boolean, z } from 'zod';


// TPA origin
export const VariantaNull: EmpTabsFormModelV2 = {
  dodatecneAtributy: {},
  personalPart: {
    health: {},
    personalInfo: {},
    execution: {
      exekuce: null,
    },
  },
  taxDeclaration: {
    rezidentura: {},
    taxDeclaration: {
      monthDiscount: {},
      partner: {},
      children: {
        deti: [],
      },
    },
  },
};

// Multistep guide default
export const EMP_TABS_INIT_DATA: EmpTabsFormModelV2 = {
  pre: true,
  ...VariantaNull,
};

export function isObject(x: any) {
  return x != null && typeof x === 'object';
}

export interface IFormError {
  parentKey: string;
  control: string;
  message: Observable<string> | string | undefined;
  field: FormlyFieldConfig;
  index: number;
}

export function getFormValidationErrors(
  fields: FormlyFieldConfig[],
  config: FormlyConfig,
  parentKey: string = ''
): IFormError[] {
  const result: IFormError[] = [];

  fields.forEach((field) => {
    !field.hide && field.formControl.markAsTouched()
    // Object.keys(form.controls).forEach((key) => {
    if (field.fieldGroup) {
      if (!field.props?.disabled && !field.hide) {
        result.push(
          ...getFormValidationErrors(
            field.fieldGroup,
            config,
            parentKey + '.' + field.key
          )
        );
      }
    }
    const error = errorMessage(field, config);
    if (error) {
      if (!field.props?.disabled && !field.hide) {
        result.push({
          parentKey: parentKey,
          control:
            (field.key || field.props?.label || field.props?.description) + '',
          field: field,
          message: error,
          index: 0,
        });
      }
    }
    // });
  });

  return result;
}

export function errorMessage(field: FormlyFieldConfig, config: FormlyConfig) {
  const fieldForm = field.formControl;
  if (fieldForm)
    for (const error in fieldForm.errors) {
      // eslint-disable-next-line no-prototype-builtins
      if (fieldForm.errors.hasOwnProperty(error)) {
        let message = config.getValidatorMessage(error);
        if (isObject(fieldForm.errors[error])) {
          if (fieldForm.errors[error].errorPath) {
            return undefined;
          }

          if (fieldForm.errors[error].message) {
            message = fieldForm.errors[error].message;
          }
        }
        if (field.validation?.messages?.[error]) {
          message = field.validation.messages[error];
        }

        if (field.validators?.[error]?.message) {
          message = field.validators[error].message;
        }

        if (field.asyncValidators?.[error]?.message) {
          message = field.asyncValidators[error].message;
        }

        if (typeof message === 'function') {
          return message(fieldForm.errors[error], field);
        }

        return message;
      }
    }

  return undefined;
}



export const zDodatecenAtributy = z.object({
  nazev: z.string().optional().nullable(),
  value: z.string().optional().nullable(),
  type: z.string().optional().nullable(),
  placeholder: z.string().optional().nullable(),
});

export const zAdresa = z.object({
  ulice: z.string().nullish(),
  popCislo: z.string().nullish(),
  orCislo: z.string().nullish(),
  mesto: z.string().nullish(),
  psc: z.string().nullish(),
  zeme: z.string().nullish(),
}).default({
  ulice: null,
  popCislo: null,
  orCislo: null,
  mesto: null,
  psc: null,
  zeme: null
});

export type zAdresa = z.infer<typeof zAdresa>;

export const zDuchody = z.object({
  pobiramDuchod: z.boolean().optional().nullable(),
  predcasny: z.boolean().optional().nullable(),
  starobni: z.boolean().optional().nullable(),
  invalidni: z.boolean().optional().nullable(),
  jiny: z.string().optional().nullable(),
  jinyDuchod: z.boolean().optional().nullable(),
});

export const zZdravotniPojistovnaAStav = z.object({
  zdravPojistovna: z.number().nullish(),
  jinaZdravPojistovna: z.string().nullish(),
  cisloPojistence: z.string().nullish(),
  zpsZtpp: z.number().nullish(),
  duchody: zDuchody.optional(),
  ztpp: z.boolean().nullish(),
  a1: z.boolean().nullish(),
  zdravotniZnevyhodneni: z.string().nullish(),
});

export const zDoklad = z.object({
  cisloPasu: z.any().optional().nullable(),
  //expiracePasu: z.date().optional().nullable(),
  expiracePasu: z.string().or(z.date()).optional().nullable(),
  identifikace: z.number().optional().nullable(),
});

export const zUplatneni = z.object({
  narokVeVysi: z.string().optional().nullable(),
  ztpp: z.boolean().optional().nullable(),
  zletileDite: z.boolean().optional().nullable(),
  mesice: z.number().optional().nullable(),
});



export type zDite = z.infer<typeof zDite>;

export const zDite = z.object({
  jmeno: z.string().optional().nullable(),
  prijmeni: z.string().optional().nullable(),
  rodneCisloADatumNarozeni: RodneCislo.nullish(),
  uplatneni: z.array(zUplatneni).optional().nullable(),
});




export const zEmpTabsFormModelV2 = z.object({
  pre: z.boolean().optional(),
  personalPart: z
    .object({
      personalInfo: z
        .object({
          jmeno: z.string().optional().nullable(),
          prijmeni: z.string().optional().nullable(),
          email: z.string().optional().nullable(),
          permanentniAdresa: zAdresa.nullish(),
          kontaktniAdresa: zAdresa.nullish(),
          prechodnaAdresa: zAdresa.nullish(),
          titulpred: z.string().optional().nullable(),
          titulza: z.string().optional().nullable(),
          rodnePrijmeni: z.string().optional().nullable(),
          rodneCisloADatumNarozeni: RodneCislo.nullish(),
          mistoNarozeni: z.string().optional().nullable(),
          rodinnyStav: z.string().nullish(),
          vzdelani: z.string().nullish(),
          pocetVychDeti: z.number().optional().nullable(),
          statniPrislusnost: z.string().optional().nullable(),
          druhaStatniPrislusnostCheck: z.boolean().optional().nullable(),
          druhaStatniPrislusnost: z.any().optional().nullable(),
          pohlavi: z.string().nullish(),
        })
        .optional()
        .nullable(),

      // allowContactAddress?: boolean | null;
      // allowTemporaryAddress?: boolean | null;
      permanentniAdresa: zAdresa.nullish(),
      kontaktniAdresa: zAdresa.nullish(),
      prechodnaAdresa: zAdresa.nullish(),
      mamCeskouPrechodnouAdresu: boolean().optional().nullish(),

      allowContactAddress: z.boolean().optional(),
      allowTemporaryAddress: z.boolean().optional(),

      wage: z
        .object({
          salaryForm: z.string().nullish(),
          cisloUctu: z.string().nullish(),
          idUstavu: z.string().nullish(),
          iban: z.string().nullish(),
        })
        .nullable()
        .optional(),

      health: zZdravotniPojistovnaAStav.optional().nullable(),

      idcard: z
        .object({
          typDokladu: z.string().nullish(),
          vydalStat: z.string().nullish(),
          doklad: zDoklad.nullish(),
          cisloDokladu: z.string().nullish(),
          datumExpirace: z.string().or(z.date()).nullish(),
        })
        .optional(),

      execution: z
        .object({
          exekuce: z.boolean().nullish(),
        })
        .optional()
        .nullable(),
      dalsiPracovniPomer: z
        .object({
          dalsiPracovniPomer: z.boolean().nullish(),
          nazevDalsihoZamestnavatele: z.string().nullish(),
          sidloDalsihoZamestnavatele: z.string().nullish(),
        })
        .nullable()
        .optional(),
    })
    .optional(),
  //taxDeclaration optional
  taxDeclaration: z
    .object({
      rezidentura: z
        .object({
          rezidentura: z.string().or(z.boolean()).nullish(),
          rezidenturaVeState: z.string().nullish(),
          rezidenturaText: z.string().nullish(),
          rezidenturaVyhodnoceni: z.string().nullish(),
          identifikace: z.string().nullish(),
          zdrzovaniVCR: z.boolean().nullish(),
          studiumVCR: z.boolean().nullish(),
        })
        .nullable()
        .optional(),
      taxDeclaration: z
        .object({
          monthDiscount: z
            .object({
              //vsechno objekty optional
              zakladniSlevaNaPoplatnika: z
                .object({ value: z.boolean().nullish() })
                .nullable()
                .optional(),
              rozsirenaSlevaNaInvalidituMesice: z
                .object({ value: z.number().nullish() })
                .nullable()
                .optional(),
              zakladniSlevaNaInvalidituMesice: z
                .object({ value: z.number().nullish() })
                .nullable()
                .optional(),
              slevaNaDrziteleZTPPMesice: z
                .object({ value: z.number().nullish() })
                .nullable()
                .optional(),
              slevaNaStudentaMesice: z
                .object({ value: z.number().nullish() })
                .nullable()
                .optional(),
            })
            .nullable()
            .optional(),
          children: z
            .object({
              deti: z.array(zDite).optional(),
              // ????? vubec nevim k cemu to je
              // jineProhlaseni?: boolean;
            })
            .nullable()
            .optional(),
          partner: z
            .object({
              dalsiPoplatnik: z.boolean().nullish(),
              jmenoPoplatnika: z.string().nullish(),
              prijmeniPoplatnika: z.string().nullish(),
              rodneCisloADatumNarozeniPoplatnika: RodneCislo.nullish(),
              trvalyPobytPoplatnika: zAdresa.nullish(),

              uplatnujePoplatnik: z.boolean().nullish(),
              zamestnaniPoplatnika: z.boolean().nullish(),

              platceDanePoplatnika: z.string().nullish(),
              adresaPlatceDanePoplatnika: zAdresa.nullish(),
            })
            .nullable()
            .optional(),
          soucasneNeuplatnuji: z.boolean().nullish(),

          //affidavit optional a nullable
          affidavit: z
            .nullable(
              z
                .object({
                  chciProhlaseni: z.boolean().nullish(),
                  prohlaseniKProhlaseni: z.boolean().nullish(),
                })
                .optional()
                .nullable()
            )
            .optional(),
        })
        .nullable()
        .optional(),

      datumNastupu: DateOnly.nullish(),
      datumUkonceni: DateOnly.nullish(),
    })
    .nullable()
    .optional(),
  pravdivostUdaju: z.boolean().nullish(),
  dodatecneAtributy: z.any().nullish(),
});

// interface ServerHeoModel {
//   jmeno?: string;
//   prijmeni?: string;
//   email?: string;
//   permanentniAdresa?: Adresa;
//   kontaktniAdresa?: Adresa;
//   prechodnaAdresa?: Adresa;
//   titulpred?: string;
//   titulza?: string;
//   rodnePrijmeni?: string;
//   rodneCislo?: string;
//   datumNarozeni?: string;
//   mistoNarozeni?: string;
//   rodinnyStav?: string;

//   vzdelani?: string;
//   pocetVychDeti?: number;
//   statniPrislusnost?: string;
//   cisloUctu?: string;
//   idUstavu?: string;
//   iban?: string;
//   cisloDokladu?: string;
//   datumExpirace?: string;
//   chciProhlaseni?: boolean;
//   datumNastupu?: string;
// }

export const zServerHeoModel = z.object({
  jmeno: z.string().nullish(),
  prijmeni: z.string().nullish(),
  email: z.string().nullish(),
  permanentniAdresa: zAdresa.nullish(),
  kontaktniAdresa: zAdresa.nullish(),
  prechodnaAdresa: zAdresa.nullish(),
  mamCeskouPrechodnouAdresu: boolean().nullish(),
  titulpred: z.string().nullish(),
  titulza: z.string().nullish(),
  rodnePrijmeni: z.string().nullish(),
  rodneCisloADatumNarozeni: RodneCislo.nullish(),
  mistoNarozeni: z.string().nullish(),
  rodinnyStav: z.string().nullish(),

  vzdelani: z.string().nullish(),
  pocetVychDeti: z.number().nullish(),
  statniPrislusnost: z.string().nullish(),
  cisloUctu: z.string().nullish(),
  idUstavu: z.string().nullish(),
  iban: z.string().nullish(),
  cisloDokladu: z.string().nullish(),
  datumExpirace: DateOnly.nullish(),
  chciProhlaseni: z.boolean().nullish(),
  datumNastupu: z.string().nullish(),
  datumUkonceni: z.string().nullish(),
  pohlavi: z.string().nullish(),
});

// interface ServerAppModel {
//   druhaStatniPrislusnost?: any;
//   rezidentura?: string;
//   rezidenturaVeState?: string;
//   rezidenturaText?: string;
//   rezidenturaVyhodnoceni?: string;
//   zdravotniPojistovnaAStav?: ZdravotniPojistovnaAStav;
//   doklad?: Doklad;
//   dalsiPracovniPomer?: boolean | null;
//   exekuce?: boolean | null;
//   jineProhlaseni?: boolean;
//   deti?: Dite[];
//   druhaStatniPrislusnostCheck?: boolean;
//   pohlavi?: string;
//   zdrzovaniVCR?: boolean | null;
//   studiumVCR?: boolean | null;
//   salaryForm?: string;
//   prohlaseniKProhlaseni?: boolean;
//   nazevDalsihoZamestnavatele?: string | null;
//   sidloDalsihoZamestnavatele?: string | null;
//   dalsiPoplatnik?: boolean;

//   jmenoPoplatnika?: string;
//   prijmeniPoplatnika?: string;
//   rodneCisloPoplatnika?: string;
//   trvalyPobytPoplatnika?: string;
//   uplatnujePoplatnik?: boolean;
//   zamestnaniPoplatnika?: boolean;
//   platceDanePoplatnika?: string;
//   adresaPlatceDanePoplatnika?: string;
//   soucasneNeuplatnuji?: boolean;
//   pravdivostUdaju?: boolean;
//   typDokladu?: string;
//   vydalStat?: string;
//   identifikace?: string | null;
//   /// mesicni slevy
//   zakladniSlevaNaPoplatnika?: boolean;
//   rozsirenaSlevaNaInvalidituMesice?: MonthFlags;
//   zakladniSlevaNaInvalidituMesice?: MonthFlags;
//   slevaNaDrziteleZTPPMesice?: MonthFlags;
//   slevaNaStudentaMesice?: MonthFlags;
// }

export const zServerAppModel = z.object({
  druhaStatniPrislusnost: z.string().nullish(),
  rezidentura: z.string().or(z.boolean()).nullish(),
  rezidenturaVeState: z.string().nullish(),
  rezidenturaText: z.string().nullish(),
  rezidenturaVyhodnoceni: z.string().nullish(),
  zdravotniPojistovnaAStav: zZdravotniPojistovnaAStav.nullish(),
  doklad: zDoklad.nullish(),
  dalsiPracovniPomer: z.boolean().nullish(),
  exekuce: z.boolean().nullish(),
  jineProhlaseni: z.boolean().nullish(),
  deti: zDite.array().nullish(),
  druhaStatniPrislusnostCheck: z.boolean().nullish(),
  // pohlavi: z.string().nullish,
  zdrzovaniVCR: z.boolean().nullish(),
  studiumVCR: z.boolean().nullish(),
  salaryForm: z.string().nullish(),
  prohlaseniKProhlaseni: z.boolean().nullish(),
  nazevDalsihoZamestnavatele: z.string().nullish(),
  sidloDalsihoZamestnavatele: z.string().nullish(),
  dalsiPoplatnik: z.boolean().nullish(),

  jmenoPoplatnika: z.string().nullish(),
  prijmeniPoplatnika: z.string().nullish(),
  rodneCisloADatumNarozeniPoplatnika: RodneCislo.nullish(),
  trvalyPobytPoplatnika: zAdresa.nullish(),
  uplatnujePoplatnik: z.boolean().nullish(),
  zamestnaniPoplatnika: z.boolean().nullish(),
  platceDanePoplatnika: z.string().nullish(),
  adresaPlatceDanePoplatnika: zAdresa.nullish(),
  soucasneNeuplatnuji: z.boolean().nullish(),
  pravdivostUdaju: z.boolean().nullish(),
  typDokladu: z.string().nullish(),
  vydalStat: z.string().nullish(),
  identifikace: z.string().nullish(),
  zakladniSlevaNaPoplatnika: z.boolean().nullish(),
  rozsirenaSlevaNaInvalidituMesice: z.number().nullish(),
  zakladniSlevaNaInvalidituMesice: z.number().nullish(),
  slevaNaDrziteleZTPPMesice: z.number().nullish(),
  slevaNaStudentaMesice: z.number().nullish(),
});
export type EmpTabsFormModelV2 = z.infer<typeof zEmpTabsFormModelV2>;

export type DodatecenAtributy = z.infer<typeof zDodatecenAtributy>;
export type Adresa = z.infer<typeof zAdresa>;
export type Duchody = z.infer<typeof zDuchody>;
export type ZdravotniPojistovnaAStav = z.infer<
  typeof zZdravotniPojistovnaAStav
>;
export type Doklad = z.infer<typeof zDoklad>;
export type Uplatneni = z.infer<typeof zUplatneni>;
export type Dite = z.infer<typeof zDite>;
export type ServerHeoModel = z.infer<typeof zServerHeoModel>;
export type ServerAppModel = z.infer<typeof zServerAppModel>;

export const MY_TEST_CARD = {
  attachments: null,
  karta: {
    jmeno: 'a',
    prijmeni: 'a',
    email: 'ala',
    permanentniAdresa: {
      ulice: 'sssasdf',
      popCislo: '767',
      orCislo: '14',
      mesto: 'Praha 4',
      psc: '140 00',
      zeme: 'CZ',
    },
    kontaktniAdresa: {
      ulice: 'sssasdf',
      popCislo: '767',
      orCislo: '14',
      mesto: 'Praha 4',
      psc: '140 00',
      zeme: 'CZ',
    },
    prechodnaAdresa: {
      ulice: null,
      popCislo: null,
      orCislo: null,
      mesto: null,
      psc: null,
      zeme: null,
    },
    titulpred: 'MgA.',
    titulza: null,
    rodnePrijmeni: 's',
    rodneCisloADatumNarozeni: {
      ceskeRodneCislo: true,
      rodneCislo: '745222/0215'
    },
    datumNarozeni: '1974-02-22T00:00:00Z',
    mistoNarozeni: 'Praha',
    rodinnyStav: 'married',
    pohlavi: 'man',
    vzdelani: 'T',
    // pocetVychDeti: 0,
    statniPrislusnost: 'CZ',
    cisloUctu: '1110169143',
    idUstavu: '0800',
    iban: null,
    cisloDokladu: null,
    datumExpirace: null,
    chciProhlaseni: true,
    datumNastupu: null,
  },
  dodatecneAtributy: {},
  app: {
    rezidentura: 'true',
    rezidenturaVeState: 'CZ',
    rezidenturaText: null,
    rezidenturaVyhodnoceni: 'true',
    zdrzovaniVCR: true,
    studiumVCR: null,
    identifikace: null,
    doklad: null,
    typDokladu: null,
    vydalStat: null,
    dalsiPracovniPomer: null,
    exekuce: false,
    salaryForm: 'cash',
    pohlavi: 'man',
    prohlaseniKProhlaseni: true,
    soucasneNeuplatnuji: false,
    zdravotniPojistovnaAStav: {
      zdravPojistovna: 111,
      cisloPojistence: '9206260184',
      ztpp: false,
      zdravotniZnevyhodneni: 'no',
    },
    zakladniSlevaNaPoplatnika: true,
    rozsirenaSlevaNaInvalidituMesice: null,
    zakladniSlevaNaInvalidituMesice: 4095,
    slevaNaDrziteleZTPPMesice: null,
    slevaNaStudentaMesice: 4095,
  },
};
