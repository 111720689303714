import { Component } from '@angular/core';
import {
  FieldType,
  FormlyExtension,
  FormlyFieldConfig
} from '@ngx-formly/core';
import { isValid, parseISO } from 'date-fns';
import { deti } from '../../form-parts-definition';

@Component({
  selector: 'tpa-children',
  templateUrl: './children.component.html',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'tpa-wrapper__control' },
})
export class ChildrenComponent extends FieldType implements FormlyExtension {
  defaultOptions = {
    className: 'p-col-12',
    props: {
      translate: true,
      open: true,
    },
  };
  parseISO = parseISO;
  isValid = isValid;

  onPopulate(field: FormlyFieldConfig) {
    if (field.fieldGroup) {
      return;
    }

    field.fieldGroup = [
      {
        className: 'p-col-12',
        fieldGroup: deti(),
      },
    ];
  }
}
