import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-checkbox',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-container *transloco="let t">
      <ejs-checkbox
        [label]="
          to.checkboxLabelT
            ? t(to.checkboxLabelT['key'], to.checkboxLabelT['params'])
            : to.checkboxLabel
        "
        [formControl]="$any(formControl)"
        [formlyAttributes]="field"
        [cssClass]="to['cssClass'] ? to['cssClass'] + ' e-primary' : 'e-primary'"
        [class.ng-dirty]="showError"
        (onChange)="to.change && to.change(field, $event)"

      >
      </ejs-checkbox>
      <p class="mt-[11px] ml-[28px]" (click)="toggle()" *ngIf="to['secondaryText']">
        {{ to.secondaryText }}
      </p>
      <p class="mt-[11px] ml-[28px]" (click)="toggle()" *ngIf="to['tercialyText']">
        {{ to.tercialyText }}
      </p>
      <ejs-tooltip
        *ngIf="
          (to.tooltip || to.tooltipT) && to.showSecondTooltipNextToCheckbox
        "
        [content]="
          to.tooltipT
            ? t(to.tooltipT['key'], to.tooltipT['params'])
            : to.tooltip
        "
        target=".tooltip-buble"
      >
        <i
          class="fal fa-info-circle tooltip tooltip-buble"
          *ngIf="
            (to.tooltip || to.tooltipT) && to.showSecondTooltipNextToCheckbox
          "
          tooltipPosition="top"
        ></i>
      </ejs-tooltip>
    </ng-container>
  `,
  styles: [

    '.checkbox-field{padding-top: .3em; padding-bottom: .3em, } ejs-checkbox{margin-top: 6px; display:block;} .fa-info-circle{margin-left: 6px;  vertical-align: baseline; cursor: pointer;} ::ng-deep formly-field-checkbox ejs-checkbox > label {display: flex !important} ::ng-deep formly-field-checkbox .e-label {display: block  !important; max-width: calc(100% - 30px); padding-left: 6px; margin-left: 0 !important;}',
  ],
})
export class FormlyFieldCheckbox extends FieldType {
  defaultOptions = {
    props: {
      showSecondTooltipNextToCheckbox: true,
      // NOTE: possibility to add reverse-checkbox
      cssClass: ""
    },
  };


  toggle() {
    this.formControl.setValue(!this.formControl.value);
  }
}
