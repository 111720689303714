import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { LetModule, PushModule } from '@ngrx/component';
import {
  AdminCoreModule,
  APP_CONFIG_PATH,
  APP_NAV_MENU,
  AuthorizeHttpInterceptor,
  ToastModule,
} from '@ralba/core';
import { RalbaFormlyModule } from '@ralba/formly';
import { NgxModuleCoreModule } from '@ralba/toolbox';
import { ApiModule as TPAApiModule } from '@tpa/api';
import { CoreModule as TPACoreModule } from '@tpa/core';
import { ChangeLangaugeModule } from '@tpa/features/profile';
import { FeaturesTwoFaAdsModule } from '@tpa/features/two-fa-ads';
import { SharedModule } from '@tpa/shared';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { CONTAINERS } from './containers';
import { LayoutRootComponent } from './root/layout.component';

import localeCzech from '@angular/common/locales/cs';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { FORMLY_CONFIG } from '@ngx-formly/core';
import { HandlebarsModule } from '@ralba/handlebars';
import { RalbaUiModule } from '@ralba/ui';
import {
  registerTranslateExtension,
  TpaFormlyModule,
} from '@tpa/formly';
import { AppEffects } from './app.effects';

registerLocaleData(localeCzech);

@NgModule({
  declarations: [AppComponent, LayoutRootComponent, ...CONTAINERS],
  imports: [
    HandlebarsModule,
    BrowserModule,
    BrowserAnimationsModule,
    LetModule,
    PushModule,
    HttpClientModule,
    AdminCoreModule.forRoot({
      navMenuItems: [],
      appTitle: 'HRPortal',
    }),
    AppRoutingModule,
    StoreDevtoolsModule.instrument({
      name: 'HRPortal',
      maxAge: 25,
    }),
    RalbaFormlyModule,
    TPACoreModule.forRoot(),
    TPAApiModule,
    ToastModule,
    RalbaUiModule,
    NgxModuleCoreModule,
    ChangeLangaugeModule,
    TranslocoModule,
    SharedModule,
    TranslocoLocaleModule.init(),
    EffectsModule.forFeature([AppEffects]),
    FeaturesTwoFaAdsModule,
    TpaFormlyModule,
  ],
  providers: [
    { provide: APP_CONFIG_PATH, useValue: `assets/${environment.appConfig}` },
    {
      provide: APP_NAV_MENU,
      useValue: [],
    },
    {
      provide: DEFAULT_CURRENCY_CODE, useValue: 'CZK'
    },
    {
      provide: LOCALE_ID, useValue: 'cs-CZ'
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizeHttpInterceptor,
      multi: true,
    },
    {
      provide: FORMLY_CONFIG,
      multi: true,
      useFactory: registerTranslateExtension,
      deps: [TranslocoService],
    },
    {
      provide: 'Window',
      useFactory: () => {
        if (typeof window !== 'undefined') {
          return window;
        } else {
          return {
            scrollTo: { y: 0 },
          };
        }
      },
    },
  ],

  bootstrap: [AppComponent],
})
export class AppModule { }
