import { formatCurrency, getCurrencySymbol } from "@angular/common";
import { RodneCislo, } from "@tpa/api";
import { zAdresa } from "@tpa/shared";
import moment from "moment";
import { countryOptions } from "./countries.options";

export function formatAsCurrency(value: string | number) {
  if (+value <= 0 || value === '' || !value) { return '' }
  return formatCurrency(+value, 'cs-CZ', getCurrencySymbol('CZK', 'wide', 'cs-CZ'), 'CZK', '1.0-0')
}

export function birthNumberOrBirthDate(value: RodneCislo): string {
  return value.ceskeRodneCislo ? value.rodneCislo : formatDate(value.datumNarozeni);
}

export function formatDate(birthDate?: string | null | undefined): string {
  if (birthDate === null || birthDate === undefined) return ''
  return moment(birthDate).format("DD. MM. YYYY")
}

export const getCountry = (country: string): string => {
  return countryOptions().find(x => x.value === country)?.label ?? '';
}

export function toPrintAddress(address: zAdresa | null): string {
  if (address === null || address === undefined) return '';
  let result = `${address.ulice ?? ''}`;
  result += ` ${address.popCislo ?? ''}`;
  result += address.orCislo ? `/${address.orCislo}` : '';
  result += ` ${address.mesto ?? ''}`;
  result += address.psc ? `, ${address.psc}` : '';
  result += address.zeme ? `, ${getCountry(address.zeme) ?? ''}` : '';
  return result;
}