<ng-container *ngIf="to['open'] && !formState['readonly'] && !to['readonly']; else card">
  <div style="padding: 8px">
    <p class="description description--padding">
      {{ 'TPA.ChildDiscountDescription' | transloco }}
    </p>
    <p class="description">
      {{ 'TPA.ChildrenDiscountInfo' | transloco }}
    </p>
  </div>
  <formly-field *ngFor="let field of field.fieldGroup" [field]="field"></formly-field>
</ng-container>
<ng-template #card>
  <div class="tpa-wrapper__content" style="width: 100%">
    <div class="p-grid p-grid--with-bottom">
      <div class="p-col-12" *ngFor="let dite of model.deti">
        <div class="e-card" style="
            background: #fde2df !important;
            border-color: #fde2df !important;
          ">
          <div class="e-card-header">
            <h3 *ngIf="to.label" class="h4">
              <ng-container *ngIf="
                  dite.jmeno || dite.prijmeni || dite.rodneCislo;
                  else notApply
                ">
                <p>
                  {{ dite.jmeno }} {{ dite.prijmeni }}
                  (<span
                    *ngIf="isValid(parseISO(dite?.rodneCisloADatumNarozeni?.datumNarozeni)); else stringVersion">{{dite?.rodneCisloADatumNarozeni?.datumNarozeni
                    | translocoDate: { dateStyle: 'medium' } }}</span><ng-template #stringVersion>{{
                    dite?.rodneCisloADatumNarozeni?.datumNarozeni }}</ng-template><span
                    *ngIf="dite?.rodneCisloADatumNarozeni?.ceskeRodneCislo"> -
                    {{dite?.rodneCisloADatumNarozeni?.rodneCislo}}</span>)


                </p>
              </ng-container>
            </h3>
          </div>
          <div class="e-card-content">
            <div class="p-grid p-grid--with-bottom">
              <div *ngFor="let u of dite.uplatneni" class="p-col-12">
                <div class="e-card">
                  <div class="e-card-content">
                    <p>
                      {{ 'TPA.ForMonths' | transloco }}
                      {{ u.mesice | monthPipe | nullToDash }}
                    </p>
                    <p>
                      {{ 'TPA.ClaimAmount' | transloco }}:
                      {{ u.narokVeVysi | claim | nullToDash }}
                    </p>
                    <p>
                      {{ 'TPA.Ztpp' | transloco }}:
                      {{ u.ztpp | yesOrNo | nullToDash }}
                    </p>
                    <p>
                      {{ 'TPA.AdultChild' | transloco }}:
                      {{ u.zletileDite | yesOrNo | nullToDash }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="p-col-12" *ngIf="model.deti?.length === 0">
        {{ 'TPA.NotApplied' | transloco }}
      </div>
    </div>
  </div>
</ng-template>
<!-- <pre>
    {{ this.model | json }}
  </pre
> -->

<ng-template #notApply>
  {{ 'TPA.NotApplied' | transloco }}
</ng-template>