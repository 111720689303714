import { translate } from '@ngneat/transloco';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { specialCharsParser } from './utils';

export const sharedSalary = (): FormlyFieldConfig[] => [
  {
    key: 'salaryForm',
    type: 'select',
    className: 'p-col-12',
    props: {
      label: 'TPA.SalaryForm',
      placeholder: 'TPA.SelectPlaceholder',
      required: true,
      translate: true,
    },
    expressions: {
      "props.options": (control) => {
        return [...(control.options.formState?.wageOption ?? [])];
      },
    },
    validators: {
      validation: [{ name: 'validWagePaymentMethod' }],
    },
  },
  {
    template: `<p>${translate('TPA.Guide.Step5CzechAccount')}</p>`,
    className: 'p-col-12',
    hideExpression: '(model?.salaryForm !== "bankcr") || !field.parent?.fieldGroup[0]?.props.options?.find(x => x.value == "bankcr")',
  },
  {
    key: 'cisloUctu',
    type: 'input',
    className: 'p-col',
    parsers: [specialCharsParser],
    modelOptions: {
      updateOn: 'blur',
    },
    props: {
      label: 'TPA.BankAccount',
      required: true,
      translate: true,
    },
    hideExpression: '(model?.salaryForm !== "bankcr") || !field.parent?.fieldGroup[0]?.props.options?.find(x => x.value == "bankcr")',
  },
  {
    className: 'p-fixed grow-0',
    template: '<span class="fake-label block"> </span><span class="py-2 block">/</span>',
    hideExpression: '(model?.salaryForm !== "bankcr") || !field.parent?.fieldGroup[0]?.props.options?.find(x => x.value == "bankcr")',
  },
  {
    key: 'idUstavu',
    type: 'input',
    className: 'p-col-4',
    parsers: [specialCharsParser],
    modelOptions: {
      updateOn: 'blur',
    },
    props: {
      label: 'TPA.Bank',
      required: true,
      translate: true,
    },
    hideExpression: '(model?.salaryForm !== "bankcr") || !field.parent?.fieldGroup[0]?.props.options?.find(x => x.value == "bankcr")',
  },
  {
    template: `<p>${translate('TPA.Guide.Step5AbroadAccount')}</p>`,
    className: 'p-col-12',
    hideExpression: '(model?.salaryForm !== "bankabroad") || !field.parent?.fieldGroup[0]?.props.options?.find(x => x.value == "bankabroad")',
  },
  {
    key: 'iban',
    type: 'input',
    className: 'p-col-12',
    parsers: [specialCharsParser],
    modelOptions: {
      updateOn: 'blur',
    },
    props: {
      label: 'TPA.IBAN',
      required: true,
      translate: true,
    },
    hideExpression: '(model?.salaryForm !== "bankabroad") || !field.parent?.fieldGroup[0]?.props.options?.find(x => x.value == "bankabroad")',
  },
];
