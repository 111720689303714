export const TPA_PERMISSIONS = {
  TPA: 'TPA',
  // Users
  TPA_Users: 'TPA.User',
  TPA_Users_Activate: 'TPA.User.Activate',
  TPA_Users_GetUserAccount: 'TPA.User.GetUserAccount',
  TPA_Users_GetUserCompanyAccount: 'TPA.User.GetCompannyAccounts',
  TPA_Users_ManagePassword: 'TPA.User.ManagePassword',
  TPA_Users_ManageUserRole: 'TPA.User.ManageUserRole',
  TPA_Users_TwoFactorAuth: 'TPA.User.TwoFactorAuth',
  TPA_Users_CreateEmployee: 'TPA.User.CreateEmployee',
  TPA_Users_Resend_activation_email: 'TPA.User.ResendActivationEmail',

  // Payrolls
  TPA_Payrolls: 'TPA.Payrolls.GetOwnPayrolls',
  TPA_EmployeePayrolls: 'TPA.EmployeePayrolls',
  // Attachments

  TPA_ATTACHEMNTS: 'TPA.Attachments.',
  TPA_ATTACHEMNTS_PayrollsRecap: 'TPA.Attachments.PayrollRecap',
  // TPA_ATTACHEMNTS_EmployeeCard: 'TPA.Attachments.EmployeeCard',
  TPA_ATTACHEMNTS_GeneralArtifact: 'TPA.Attachments.GeneralArtifact',

  // Chat

  TPA_CHAT: 'TPA.Chat.',
  TPA_CHAT_PayrollsRecap: 'TPA.Chat.PayrollRecap',
  TPA_CHAT_PayrollsRecap_Chatting: 'TPA.Chat.PayrollRecap.Send',
  TPA_CHAT_EmployeeCard: 'TPA.Chat.EmployeeCard',
  TPA_CHAT_EmployeeCard_Chatting: 'TPA.Chat.EmployeeCard.Send',
  TPA_CHAT_GeneralArtifact: 'TPA.Chat.GeneralArtifact',
  TPA_CHAT_GeneralArtifact_Chatting: 'TPA.Chat.GeneralArtifact.Send',
  TPA_CHAT_Rzd: 'TPA.Chat.Rzd',
  TPA_CHAT_Rzd_Chatting: 'TPA.Chat.Rzd.Send',

  // TOTO je schvalne nekonzistentni "Document" kculi Class.Name na BE
  TPA_CHAT_Documents: 'TPA.Chat.Document',
  TPA_CHAT_Documents_Chatting: 'TPA.Chat.Document.Send',
  UserDocumentsUpload: 'TPA.User.UploadDocument',


  TPA_ETL: 'TPA.ETL',

  // COMPANY
  TPA_COMPANY: 'TPA.Company',

  // Payrolls recap
  TPA_PAYROLL_RECAP: 'TPA.PayrollRecap',
  TPA_PAYROLL_RECAP_DETAIL: 'TPA.PayrollRecap.Detail',
  TPA_PAYROLL_RECAP_CREATE: 'TPA.PayrollRecap.Create',
  TPA_PAYROLL_RECAP_APPROVE: 'TPA.PayrollRecap.Approve',
  TPA_PAYROLL_RECAP_DENIE: 'TPA.PayrollRecap.Denie',
  TPA_PAYROLL_RECAP_INVALIDATE: 'TPA.PayrollRecap.Invalidate',
  TPA_PAYROLL_RECAP_TASK_NOTIFICATION_APPROVED:
    'TPA.PayrollRecap.NotificationOnApproved',

  // Employee
  TPA_EMPLOYEES_CARDS: 'TPA.EmployeeCards',
  TPA_EMPLOYEES_CARDS_DETAIL: 'TPA.EmployeeCards.Detail',
  TPA_EMPLOYEES_CARDS_CAN_RETURN: 'TPA.EmployeeCards.CanReturn',

  TPA_EMPLOYEES_CARDS_CAN_APPROVE: 'TPA.EmployeeCards.CanApprove',

  TPA_EMPLOYEES_CARDS_CAN_EDIT: 'TPA.EmployeeCards.CanEdit', //Editace karty super mzdovko,

  // General

  TPA_GENERAL_ARTIFACT: 'TPA.GeneralArtifact',
  TPA_GENERAL_ARTIFACT_DETAIL: 'TPA.GeneralArtifact.Detail',
  TPA_GENERAL_ARTIFACT_CREATE_FOR_USER: 'TPA.GeneralArtifact.CreateForUser',
  TPA_GENERAL_ARTIFACT_TOOGLE_STATE: 'TPA.GeneralArtifact.ToogleState',
  TPA_MY_ARTIFACT: 'TPA.MyArtifact',

  //RZD

  TPA_RZD: 'TPA.Rzd',
  TPA_RZD_DETAIL: 'TPA.Rzd.Detail',
  TPA_RZD_CAN_CRATE: 'TPA.CanCreateRzd',
  TPA_RZD_CAN_RETURN: 'TPA.Rzd.CanReturn',
  TPA_RZD_CAN_APPROVE: 'TPA.Rzd.CanApprove',
  TPA_RZD_CAN_EDIT_DATE: 'TPA.Rzd.CanEditRzd',

  // ZERO

  Pages_Administration_Roles: 'Pages.Administration.Roles',
  Pages_Administration_Roles_Create: 'Pages.Administration.Roles.Create',
  Pages_Administration_Roles_Edit: 'Pages.Administration.Roles.Edit',
  Pages_Administration_Roles_Delete: 'Pages.Administration.Roles.Delete',

  //added

  TPA_DOCUMENTS_HardDelete: 'TPA.Documents.HardDelete',
  TPA_DOCUMENTS_SoftDelete: 'TPA.Documents.SoftDelete',
  TPA_DOCUMENTS: 'TPA.Documents',
  TPA_DOCUMENTS_Approve: 'TPA.Documents.Approve',
  TPA_My_DOCUMENTS: 'TPA.MyDocuments',

  TPA_Announcement: 'TPA.Announcements',
};

export const TPA_FEATURES = {
  Payrolls: 'TPA.Payrolls',
  PayrollRecap: 'TPA.PayrollRecap',
  GeneralArtefact: 'TPA.GeneralArtefact',

  EmployeeCard: 'TPA.EmployeeCard',

  PersonalCardUI: 'TPA.PersonalCardUI',
  Guide: 'TPA.Guide',
  EditPersonalCardUI: 'TPA.EditPersonalCardUI',
  EmployeeTaxDeclaration: 'TPA.EmployeeTaxDeclaration',
  EmployeeTaxAnnualSettlement: 'TPA.EmployeeTaxAnnualSettlement',

  EmployeeTaxAnnualSettlementGuidedMode: 'TPA.EmployeeTaxAnnualSettlement.GuidedMode',

  Documents: 'TPA.Documents',

  UserDocumentsUI: 'TPA.UserDocumentsUI',
  PrintPDF: 'TPA.PrintPDF',
};

export const TPA_PERMISSIONS_COMPOUND = {
  CAN_VIEW_EMPLOYEE_CARD_TASKS: [
    TPA_PERMISSIONS.TPA_EMPLOYEES_CARDS_CAN_RETURN,
    TPA_PERMISSIONS.TPA_EMPLOYEES_CARDS_CAN_APPROVE,
  ],
  CAN_VISIT_USER_DETAIL: [
    TPA_PERMISSIONS.TPA_Users_GetUserAccount,
    TPA_PERMISSIONS.TPA_Users_GetUserCompanyAccount,
    TPA_PERMISSIONS.TPA_Users_ManagePassword,
    TPA_PERMISSIONS.TPA_Users_ManageUserRole,
    TPA_PERMISSIONS.TPA_Users_TwoFactorAuth,
    TPA_PERMISSIONS.TPA_GENERAL_ARTIFACT_CREATE_FOR_USER,
    TPA_PERMISSIONS.TPA_Users_Activate,
  ],
  CAN_VIEW_TASKS: [
    TPA_PERMISSIONS.TPA_EMPLOYEES_CARDS,
    TPA_PERMISSIONS.TPA_CHAT_EmployeeCard,
    TPA_PERMISSIONS.TPA_CHAT_Documents,
    TPA_PERMISSIONS.TPA_CHAT_PayrollsRecap,
    TPA_PERMISSIONS.TPA_CHAT_GeneralArtifact,
    TPA_PERMISSIONS.TPA_CHAT_Rzd,
    TPA_PERMISSIONS.TPA_PAYROLL_RECAP_CREATE,
    TPA_PERMISSIONS.TPA_PAYROLL_RECAP_INVALIDATE,
    TPA_PERMISSIONS.TPA_GENERAL_ARTIFACT,
    TPA_PERMISSIONS.TPA_RZD,
    TPA_PERMISSIONS.TPA_DOCUMENTS_Approve,
  ],
  RZD: [
    TPA_PERMISSIONS.TPA_RZD,
    TPA_PERMISSIONS.TPA_RZD_CAN_CRATE,
    TPA_PERMISSIONS.TPA_RZD_CAN_RETURN,
    TPA_PERMISSIONS.TPA_RZD_CAN_APPROVE,
    TPA_PERMISSIONS.TPA_RZD_CAN_EDIT_DATE,
  ],
};
