import { z } from 'zod';
import { DateOnly } from './date-only';

const AnnualTaxSettlementV2020Address = z.object({
  ulice: z.string().nullable(),
  popCislo: z.string().nullable(),
  orCislo: z.string().nullable(),
  mesto: z.string().nullable(),
  psc: z.string().nullable(),
  zeme: z.string().nullable()
});


export const AnnualTaxSettlementV2020 = z.object({
  version: z.literal('2020'),
  karta: z.object({
    jmeno: z.string().nullable(),
    prijmeni: z.string().nullable(),
    email: z.string().nullable(),
    permanentniAdresa: AnnualTaxSettlementV2020Address.nullable(),
    kontaktniAdresa: AnnualTaxSettlementV2020Address.nullable(),
    prechodnaAdresa: AnnualTaxSettlementV2020Address.nullable(),
    titulpred: z.string().nullable(),
    titulza: z.string().nullable(),
    rodnePrijmeni: z.string().nullable(),
    rodneCislo: z.string().nullable(),
    datumNarozeni: DateOnly,
    mistoNarozeni: z.string().nullable(),
    rodinnyStav: z.string().nullable(),
    pohlavi: z.string().nullable(),
    vzdelani: z.string().nullable(),
    pocetVychDeti: z.number().nullable(),
    statniPrislusnost: z.string().nullable(),
    cisloUctu: z.string().nullable(),
    idUstavu: z.string().nullable(),
    iban: z.string().nullable(),
    cisloDokladu: z.string().nullable(),
    chciProhlaseni: z.boolean().nullable(),
    datumExpirace: DateOnly,
    datumNastupu: DateOnly
  }),
  app: z.object({
    druhaStatniPrislusnost: z.string().nullable(),
    rezidentura: z.string().nullable(),
    rezidenturaVeState: z.string().nullable(),
    rezidenturaText: z.string().nullable(),
    rezidenturaVyhodnoceni: z.string().nullable(),
    zdravotniPojistovnaAStav: z.object({
      zdravPojistovna: z.number().nullable(),
      jinaZdravPojistovna: z.string().nullable(),
      cisloPojistence: z.string().nullable(),
      ztpp: z.boolean().nullable(),
      a1: z.boolean().nullable(),
      zdravotniZnevyhodneni: z.string().nullable(),
      zpsZtpp: z.number().nullable(),
      duchody: z.object({
        pobiramDuchod: z.boolean().nullable(),
        predcasny: z.boolean().nullable(),
        starobni: z.boolean().nullable(),
        invalidni: z.boolean().nullable(),
        jinyDuchod: z.boolean().nullable(),
        jiny: z.string().nullable()
      })
    }),
    doklad: z.object({
      cisloPasu: z.string().nullable(),
      expiracePasu: z.string().nullable(),
      identifikace: z.number().nullable()
    }).nullable(),
    deti: z.object({
      jmeno: z.string().nullable(),
      prijmeni: z.string().nullable(),
      rodneCislo: z.string().nullable(),
      uplatneni: z.object({
        narokVeVysi: z.string().nullable(),
        ztpp: z.boolean().nullable(),
        zletileDite: z.boolean().nullable(),
        mesice: z.number().nullable()
      }).array()
    }).array(),
    dalsiPracovniPomer: z.boolean().nullable(),
    exekuce: z.boolean().nullable(),
    jineProhlaseni: z.boolean().nullable(),
    druhaStatniPrislusnostCheck: z.boolean().nullable(),
    pohlavi: z.string().nullable(),
    zdrzovaniVCR: z.boolean().nullable(),
    studiumVCR: z.boolean().nullable(),
    salaryForm: z.string().nullable(),
    prohlaseniKProhlaseni: z.boolean().nullable(),
    nazevDalsihoZamestnavatele: z.string().nullable(),
    sidloDalsihoZamestnavatele: z.string().nullable(),
    dalsiPoplatnik: z.boolean().nullable(),
    jmenoPoplatnika: z.string().nullable(),
    prijmeniPoplatnika: z.string().nullable(),
    rodneCisloPoplatnika: z.string().nullable(),
    trvalyPobytPoplatnika: z.string().nullable(),
    uplatnujePoplatnik: z.boolean().nullable(),
    zamestnaniPoplatnika: z.boolean().nullable(),
    platceDanePoplatnika: z.string().nullable(),
    adresaPlatceDanePoplatnika: z.string().nullable(),
    soucasneNeuplatnuji: z.boolean().nullable(),
    pravdivostUdaju: z.boolean().nullable(),
    typDokladu: z.string().nullable(),
    vydalStat: z.string().nullable(),
    identifikace: z.string().nullable(),
    zakladniSlevaNaPoplatnika: z.boolean().nullable(),
    rozsirenaSlevaNaInvalidituMesice: z.number().nullable(),
    zakladniSlevaNaInvalidituMesice: z.number().nullable(),
    slevaNaDrziteleZTPPMesice: z.number().nullable(),
    slevaNaStudentaMesice: z.number().nullable()
  }),
  rzdData: z.object({
    dodetecneUplatneni: z.boolean().nullable(),
    zada: z.boolean().nullable(),
    potrzeni: z.boolean().nullable(),
    zuctovani: z.object({
      /*dalsiZamestnani: z.boolean().nullable(),*/
      dalsiZamestnavatele: z.object({
        nazev: z.string().nullable(),
        mesice: z.number().nullable()
      }).array(),
      manzelka: z.boolean().nullable(),
      manzelkaJmeno: z.boolean().nullable(),
      manzelkaPrijmeni: z.boolean().nullable(),
      manzelkaRodneCislo: z.boolean().nullable(),
      manzelkaMesice: z.number().nullable(),
      manzelkaZTP: z.boolean().nullable(),
      manzelkaZTPMesice: z.number().nullable(),
      skolkovneDeti: z.object({
        jmeno: z.string().nullable(),
        prijmeni: z.string().nullable(),
        rodneCislo: z.string().nullable(),
        castka: z.number().nullable()
      }).array(),
      /*exekuceDiscount: z.boolean().nullable(),*/
      exekuceDiscountCastka: z.number().nullable(),
      /*dary: z.boolean().nullable(),*/
      daryCastka: z.number().nullable(),
      /*uver: z.boolean().nullable(),*/
      uverCastka: z.number().nullable(),
      uverAdresa: z.string().nullable(),
      uverUcel: z.string().nullable(),
      /*uverJineOsobyAnoNe: z.boolean().nullable(),*/
      uverJineOsoby: z.object({
        jmeno: z.string().nullable(),
        prijmeni: z.string().nullable(),
        rodneCislo: z.string().nullable(),
        adresa: AnnualTaxSettlementV2020Address
      }).array(),
      prohlaseniUver: z.boolean().nullable(),
      prohlaseniUver2: z.boolean().nullable(),
      /*penzijni: z.boolean().nullable(),*/
      penzijniCastka: z.number().nullable(),
      /*zivotni: z.boolean().nullable(),*/
      zivotniCastka: z.number().nullable(),
      /*prispevky: z.boolean().nullable(),*/
      prispevkyCastka: z.number().nullable(),
      /*zkousky: z.boolean().nullable(),*/
      zkouskyCastka: z.number().nullable()
    })

  })
});
export type AnnualTaxSettlementV2020 = z.infer<typeof AnnualTaxSettlementV2020>
