import { translate } from '@ngneat/transloco';
import { FormlyFieldConfig } from '@ngx-formly/core';

import { countryOptions } from '@tpa/features/emp-data';
import { EmpTabsFormModelV2, MonthFlags } from '@tpa/shared';
import { isAfter, parseISO } from 'date-fns';
import { specialCharsParser } from './utils';

// Changes
// 1. param full for BaseValues is now handles by formState.full
// pocetVychDetirequired: is now handled by formState.isVychDetiRequired()
// hideContactAddress moved to formState.hideContactAddress
// hideTempAddressQuestion moved to formState.hideTempAddressQuestion
// hideTempAddress moved to formState.hideTempAddress
export const sharedQuestion = (
  config: FormlyFieldConfig
): FormlyFieldConfig => ({
  type: 'yes-or-no',
  className: 'p-col-12',
  wrappers: ['yes-or-no-form-field'],
  ...config,
  props: {
    yesText: 'TPA.Yes',
    noText: 'TPA.No',
    ...config.props,
    translate: true,
    required: true,
  },
});

export const sharedPersonalPart = (
  config: FormlyFieldConfig[]
): FormlyFieldConfig => ({
  key: 'personalPart',
  fieldGroup: config,
});

export const sharedTaxDeclarationPart = (
  config: FormlyFieldConfig[]
): FormlyFieldConfig => ({
  key: 'taxDeclaration',
  fieldGroup: config,
});

// (model: EmpTabsFormModelV2,
// formState: FormlyFormOptions,
// field: FormlyFieldConfig) =>
// export const sharedFormState = (form: BaseForm<EmpTabsFormModelV2>) => ({
export const sharedFormState = (form: { model: EmpTabsFormModelV2 }) => ({
  isVychDetiRequired: () => {
    const tDate = parseISO('1971-12-31T23:00:00Z');
    const pohlavi = form.model?.personalPart?.personalInfo.pohlavi;
    const datumNarozeni = form.model?.personalPart?.personalInfo.rodneCisloADatumNarozeni.datumNarozeni;
    return (
      pohlavi == 'woman' &&
      datumNarozeni &&
      isAfter(tDate, parseISO(datumNarozeni))
    );
  },
  hideContactAddress: () => {
    // console.log(this.model, formState, field);
    return !(form.model?.personalPart?.allowContactAddress == false);
  },
  hideTempAddressQuestion: () => {
    return form.model?.personalPart?.personalInfo?.statniPrislusnost == 'CZ';
  },
  hideTempIdCardSection: () => {
    return form.model?.personalPart?.personalInfo?.statniPrislusnost == 'CZ';
  },
  hideTempAddress: () => {
    return (
      form.model?.personalPart?.personalInfo?.statniPrislusnost == 'CZ' ||
      !(form.model?.personalPart?.allowTemporaryAddress == false)
    );
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  changeZdravPojistovna: (field?: FormlyFieldConfig, event?: any) => {
    if (event.itemData && event.itemData.value && event.itemData.value != 0) {
      // TPACHANGES: this part was rewrited
      const rc = form.model?.personalPart?.personalInfo.rodneCisloADatumNarozeni.rodneCislo;
      // const rc = field?.parent?.parent.fieldGroup[1]?.fieldGroup?.find(
      //   (p) => p.key == 'rodneCislo'
      // )?.formControl?.value;
      const cisloPojistence = field?.parent?.fieldGroup?.find(
        (p) => p.key == 'cisloPojistence'
      );
      if (rc && cisloPojistence?.formControl)
        cisloPojistence.formControl.patchValue((rc as string).replace('/', ''));
    }
  },
  hideBasedOnZakladniSlevaNaPoplatnika: () => {
    return (
      form.model?.taxDeclaration?.taxDeclaration.monthDiscount
        ?.zakladniSlevaNaPoplatnika?.value != true
    );
  },

  hideBasedOnProhlaseniKProhlaseni: () => {
    return (
      form.model?.taxDeclaration?.taxDeclaration?.affidavit?.prohlaseniKProhlaseni != true ||
      form.model?.taxDeclaration?.taxDeclaration?.affidavit?.chciProhlaseni != true
    );
  },

  hideBasedOnAreYouStudent: () => {
    if (Object.prototype.hasOwnProperty.call(form.model, 'areYouStudent')) {
      return (form.model as { areYouStudent: boolean }).areYouStudent != true;
    }
    return true;
  },
  hideBasedAreYouStudent: () => {
    if (
      Object.prototype.hasOwnProperty.call(
        form.model,
        'doYouWantToApplyForStudentDiscount'
      )
    ) {
      return (
        (form.model as { doYouWantToApplyForStudentDiscount: boolean })
          .doYouWantToApplyForStudentDiscount != true
      );
    }
    return true;
  },
  hideBasedOnChildrenDiscountQuestion: () => {
    if (
      Object.prototype.hasOwnProperty.call(
        form.model,
        'childrenDiscountQuestion'
      )
    ) {
      return (
        (form.model as { childrenDiscountQuestion: boolean })
          .childrenDiscountQuestion != true
      );
    }
    return true;
  },
  hideBasedOnChildrenArray: () => {
    if (form.model.taxDeclaration?.taxDeclaration?.children?.deti) {
      return !(
        form.model.taxDeclaration.taxDeclaration.children.deti.length > 0
      );
    }
    return true;
  },
  hideBaseddoYouWishToClaimdisabilityAllowance: () => {
    if (
      Object.prototype.hasOwnProperty.call(
        form.model,
        'doYouWishToClaimdisabilityAllowance'
      )
    ) {
      return (
        (form.model as { doYouWishToClaimdisabilityAllowance: boolean })
          .doYouWishToClaimdisabilityAllowance != true
      );
    }
    return true;
  },
  hideBaseddoYouWishToClaimTheBasicDisabilityAllowance: () => {
    if (
      Object.prototype.hasOwnProperty.call(
        form.model,
        'doYouWishToClaimTheBasicDisabilityAllowance'
      )
    ) {
      return (
        (form.model as { doYouWishToClaimTheBasicDisabilityAllowance: boolean })
          .doYouWishToClaimTheBasicDisabilityAllowance != true
      );
    }
    return true;
  },
  hideBaseddoYouWishToClaimTheBasicDisabilityAllowance2: () => {
    if (
      Object.prototype.hasOwnProperty.call(
        form.model,
        'doYouWishToClaimTheBasicDisabilityAllowance2'
      )
    ) {
      return (
        (
          form.model as {
            doYouWishToClaimTheBasicDisabilityAllowance2: boolean;
          }
        ).doYouWishToClaimTheBasicDisabilityAllowance2 != true
      );
    }
    return true;
  },
  hideBaseddoYouWishToClaimTheExtendedDisabilityAllowance: () => {
    if (
      Object.prototype.hasOwnProperty.call(
        form.model,
        'doYouWishToClaimTheExtendedDisabilityAllowance'
      )
    ) {
      return (
        (
          form.model as {
            doYouWishToClaimTheExtendedDisabilityAllowance: boolean;
          }
        ).doYouWishToClaimTheExtendedDisabilityAllowance != true
      );
    }
    return true;
  },
  hideBaseddoYouWishToClaimTheExtendedDisabilityAllowance2: () => {
    if (
      Object.prototype.hasOwnProperty.call(
        form.model,
        'doYouWishToClaimTheExtendedDisabilityAllowance2'
      )
    ) {
      return (
        (
          form.model as {
            doYouWishToClaimTheExtendedDisabilityAllowance2: boolean;
          }
        ).doYouWishToClaimTheExtendedDisabilityAllowance2 != true
      );
    }
    return true;
  },
  hideBaseddoYouWishToApplyTheDiscountToZTPPCardHolder: () => {
    if (
      Object.prototype.hasOwnProperty.call(
        form.model,
        'doYouWishToApplyTheDiscountToZTPPCardHolder'
      )
    ) {
      return (
        (form.model as { doYouWishToApplyTheDiscountToZTPPCardHolder: boolean })
          .doYouWishToApplyTheDiscountToZTPPCardHolder != true
      );
    }
    return true;
  },
  hideBaseddoYouWishToApplyTheDiscountToZTPPCardHolder2: () => {
    if (
      Object.prototype.hasOwnProperty.call(
        form.model,
        'doYouWishToApplyTheDiscountToZTPPCardHolder2'
      )
    ) {
      return (
        (
          form.model as {
            doYouWishToApplyTheDiscountToZTPPCardHolder2: boolean;
          }
        ).doYouWishToApplyTheDiscountToZTPPCardHolder2 != true
      );
    }
    return true;
  },
});

export const Tax = (): FormlyFieldConfig => ({
  wrappers: ['panel-wrapper'],
  className: 'p-col-12',
  props: {
    label: translate('TPA.ResidentureRecognition'),
  },
  fieldGroup: [
    {
      type: 'tigger-button',
      className: 'p-col-12',
      props: {
        label: translate('TPA.ResidentureBtn'),
        change: (field) => {
          const temp = field?.parent?.fieldGroup;
          if (temp) {
            temp
              .find((p) => p.key == 'rezidenturaVyhodnoceni')
              ?.formControl?.patchValue(null);
            temp
              .find((p) => p.key == 'studiumVCR')
              ?.formControl?.patchValue(null);
            temp
              .find((p) => p.key == 'zdrzovaniVCR')
              ?.formControl?.patchValue(null);
            temp
              .find((p) => p.key == 'rezidenturaVeState')
              ?.formControl?.patchValue(null);

            temp
              .find((p) => p.key == 'rezidentura')
              ?.formControl?.patchValue(null);
          }
        },
      },
      hideExpression: '(model?.rezidentura == null)',
    },
    {
      template: `
        <h3 class="h4">${translate('TPA.ResidentureQ1')}</h3>
        <p class="description">${translate('TPA.ResidentureQ1Desc')}</p>
      `,
    },
    {
      key: 'zdrzovaniVCR',
      type: 'yes-or-no',
      className: 'p-col-12',
      props: {
        required: true,
        translate: true,
        yesText: 'TPA.Yes',

        noText: 'TPA.No',
      },
      expressionProperties: {
        'props.disabled': (m, formState, field) =>
          field?.model?.rezidentura != null,
      },
    },
    /*     {
      template: `
        <h2>Jedná se o Vaše první zaměstnání v České republice?</h2>
        <p class="description">tj. v období bezprostředně před začátkem aktuálního pracovního poměru jste pracoval / vykonával činnost pro zaměstnavatele se sídlem mimo Českou republiku.</p>
      `,
      hideExpression:
        '(model?.zdrzovaniVCR == null || (model?.prvniZamestnaniVCR == null && model?.rezidentura != null))',
    },
    {
      key: 'prvniZamestnaniVCR',
      type: 'yes-or-no',
      className: 'p-col-12',
      props: {},
      hideExpression:
        '(model?.zdrzovaniVCR == null || (model?.prvniZamestnaniVCR == null && model?.rezidentura != null))',
      expressionProperties: {
        'props.disabled': (m, formState, field) =>
          field.model?.rezidentura != null,
      },
    }, */
    {
      template: `
      <h3 class="h4">${translate('TPA.ResidentureQ2')}</h3>
        <p class="description">${translate('TPA.ResidentureQ2Desc')}</p>
      `,
      hideExpression:
        /* '(model?.prvniZamestnaniVCR == null || (model?.studiumVCR == null && model?.rezidentura != null))', */
        '(model?.zdrzovaniVCR == null || (model?.studiumVCR == null && model?.rezidentura != null))',
    },
    {
      key: 'studiumVCR',
      type: 'yes-or-no',
      className: 'p-col-12',
      props: {
        required: true,
        translate: true,
        yesText: 'TPA.Yes',

        noText: 'TPA.No',
        // change: (field) => {
        //   field?.parent?.fieldGroup
        //   .find((p) => p.key == 'rezidentura')
        //   .formControl.patchValue("cz");
        // },
      },
      hideExpression:
        /* '(model?.prvniZamestnaniVCR == null || (model?.studiumVCR == null && model?.rezidentura != null))', */
        '(model?.zdrzovaniVCR == null || (model?.studiumVCR == null && model?.rezidentura != null))',
      expressionProperties: {
        'props.disabled': (m, formState, field) =>
          field?.model?.rezidentura != null,
      },
    },
    {
      className: 'p-col-12',
      template: `
        <h3 class="h4">${translate('TPA.Evaluation')}</h3>
        <p class="">${translate('TPA.EvaluationN')}</p>
      `,
      hideExpression: '(model?.rezidenturaVyhodnoceni != "false")',
    },
    {
      className: 'p-col-12',
      template: `
        <h3 class="h4">${translate('TPA.Evaluation')}</h3>
        <p class="">${translate('TPA.EvaluationR')}</p>
      `,
      hideExpression: '(model?.rezidenturaVyhodnoceni != "true")',
    },
    {
      className: 'p-col-12',
      template: `
        <h3 class="h4">${translate('TPA.Evaluation')}</h3>
        <p class="">${translate('TPA.EvaluationC')}</p>
      `,
      hideExpression: '(model?.rezidenturaVyhodnoceni != "konzultace")',
    },

    {
      key: 'rezidenturaVyhodnoceni',
      type: 'select',
      className: 'p-col-12 p-md-6',
      props: {
        placeholder: translate('TPA.SelectPlaceholder'),
        options: [
          { label: translate('TPA.Consultation'), value: 'konzultace' },
          { label: translate('TPA.Rezident'), value: 'true' },
          { label: translate('TPA.Nerezident'), value: 'false' },
        ],
        label: translate('TPA.ResidentureRecognition'),
      },
      hideExpression: true,
    },
    {
      key: 'rezidentura',
      type: 'select',
      className: 'p-col-12 p-xl',
      props: {
        required: true,
        placeholder: translate('TPA.SelectPlaceholder'),
        options: [
          {
            label: translate('TPA.Consultation'),
            value: 'konzultace',
          },
          { label: translate('TPA.Rezident'), value: 'true' },
          { label: translate('TPA.Nerezident'), value: 'false' },
        ],
        label: translate('TPA.ResidentureDeclaration'),
      },
      hideExpression: '(model?.rezidentura == null)',
    },
    {
      key: 'rezidenturaVeState',
      type: 'select',
      className: 'p-col',
      props: {
        required: true,
        placeholder: translate('TPA.SelectPlaceholder'),
        options: [...countryOptions()],
        label: translate('TPA.ResidentureCountry'),
      },
      validators: {
        validation: [{ name: 'validateNonRezidentHasToBeOutsideCzech' }],
      },
      hideExpression:
        '(model?.rezidentura == null) || (model?.rezidentura == "true") || (model?.rezidentura == "konzultace")',
    },
    {
      key: 'identifikace',
      type: 'input',
      className: 'p-col',
      parsers: [specialCharsParser],
      modelOptions: {
        updateOn: 'blur',
      },
      props: {
        label: 'TPA.ResidentureIdentification',
        translate: true,
      },
      hideExpression:
        '(model?.rezidenturaVeState == null) || (model?.rezidenturaVeState == "CZ" || model?.rezidentura === "true") ',

    },
    {
      className: 'p-col-12',
      template: `
        <p class="">${translate('TPA.RezidentInfo')}</p>
      `,
      hideExpression:
        '(model?.rezidenturaVyhodnoceni == null) || (model?.rezidentura != "true")',
    },
    {
      className: 'p-col-12',
      template: `
        <p class="">${translate('TPA.NerezidentInfo')}</p>
      `,
      hideExpression:
        '(model?.rezidenturaVyhodnoceni == null) || (model?.rezidentura != "false")',
    },
    {
      className: 'p-col-12',
      template: `
        <p class="">${translate('TPA.ConsultationInfo')}</p>
      `,
      hideExpression:
        '(model?.rezidenturaVyhodnoceni == null) || (model?.rezidentura != "konzultace")',
    },
  ],
});

export const Health = (): FormlyFieldConfig => ({
  wrappers: ['panel-wrapper'],
  className: 'p-col-12',
  key: 'zdravotniPojistovnaAStav',
  props: {
    label: translate('TPA.HealthInsuranceAndState'),
  },
  fieldGroup: [
    {
      key: 'zdravPojistovna',
      type: 'select',
      className: 'p-col-12 p-sm-8',
      props: {
        placeholder: translate('TPA.SelectPlaceholder'),
        label: translate('TPA.HealthInsurance'),
        required: true,
        options: [
          { label: 'Všeobecná zdravotní pojišťovna - 111', value: 111 },
          { label: 'Vojenská zdravotní pojišťovna - 201', value: 201 },
          { label: 'Česká průmyslová zdravotní pojišťovna - 205', value: 205 },
          {
            label: 'Oborová ZP zaměstnanců bank a pojišťoven - 207',
            value: 207,
          },
          { label: 'Zaměstnanecká pojišťovna Škoda - 209', value: 209 },
          {
            label: 'Zdravotní pojišť. Ministerstva vnitra ČR - 211',
            value: 211,
          },
          { label: 'Revírní bratrská pokladna - 213', value: 213 },
          { label: 'Zdravotní pojišťovna METAL-ALIANCE - 217', value: 217 },
          { label: 'Zdravotní pojišťovna MÉDIA - 228', value: 228 },
          { label: translate('TPA.Other'), value: 0 },
        ],
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        change: (field?: FormlyFieldConfig, event?: any) => {
          if (
            event &&
            event.itemData &&
            event.itemData.value &&
            event.itemData.value != 0
          ) {
            const temp = field?.parent?.parent;
            if (temp && temp.fieldGroup && temp.fieldGroup[1]) {
              const rc = temp.fieldGroup[1].fieldGroup?.find(
                (p) => p.key == 'rodneCislo'
              );
              if (rc && rc.formControl?.value) {
                const cisloPojistence = field?.parent?.fieldGroup?.find(
                  (p) => p.key == 'cisloPojistence'
                );
                if (cisloPojistence)
                  cisloPojistence.formControl?.patchValue(
                    (rc as string).replace('/', '')
                  );
              }
            }
          }
        },
      },
    },
    {
      key: 'jinaZdravPojistovna', //V případě "Jiná" pojišťovna string input pro další specifikaci pojišťovny.
      type: 'input',
      className: 'p-col-12 p-sm-4',
      parsers: [specialCharsParser],
      modelOptions: {
        updateOn: 'blur',
      },
      props: {
        label: translate('TPA.OtherHealthInsurance'),
        required: true,
      },
      hideExpression: '(model?.zdravPojistovna != 0)',
    },
    {
      key: 'cisloPojistence', //V případě ČR je to RČ
      type: 'input',
      className: 'p-col-12 p-sm-4',
      modelOptions: {
        updateOn: 'blur',
      },
      parsers: [specialCharsParser],
      props: {
        label: translate('TPA.InsuranceNumber'),
        required: true,
        tooltip: translate('TPA.InsuranceNumberTooltip'),
      },
    },
    {
      key: 'zdravotniZnevyhodneni',
      type: 'select',
      className: 'p-col',
      props: {
        placeholder: translate('TPA.SelectPlaceholder'),
        label: translate('TPA.HealthDisadvantage'),
        required: true,
        defaultValue: null,
        options: [
          { label: translate('TPA.No'), value: 'no' },
          { label: translate('TPA.Ozz'), value: 'ozz' },
          {
            label: translate('TPA.InvalidityOneTwo'),
            value: 'firstorseconddegreeinvalidity',
          },
          {
            label: translate('TPA.InvalidityThree'),
            value: 'thirddegreeinvalidity',
          },
        ],
      },
    },
    {
      key: 'ztpp',
      type: 'select',
      className: 'p-col-12 p-sm-4',
      props: {
        placeholder: translate('TPA.SelectPlaceholder'),
        label: translate('TPA.Ztpp'),
        required: true,
        options: [
          { label: translate('TPA.No'), value: false },
          { label: translate('TPA.Yes'), value: true },
        ],
      },
    },
    {
      key: 'a1',
      type: 'checkbox',
      className: 'p-col-12',
      props: {
        checkboxLabel: translate('TPA.A1'),
        tooltip: translate('TPA.A1Tooltip'),
      },
    },
    {
      className: 'p-col-12',
      key: 'duchody',
      fieldGroup: [
        {
          key: 'pobiramDuchod',
          type: 'checkbox',
          className: 'p-col-12',
          props: {
            checkboxLabel: translate('TPA.GotPension'),
          },
          hideExpression: '(model?.pobiramDuchod == true)',
        },
        {
          wrappers: ['panel-wrapper'],
          hideExpression: '(model?.pobiramDuchod != true)',
          fieldGroup: [
            {
              key: 'pobiramDuchod',
              type: 'checkbox',
              className: 'p-col-12',
              props: {
                checkboxLabel: translate('TPA.GotPension'),
              },
            },
            {
              key: 'predcasny',
              type: 'checkbox',
              className: 'p-col-12 p-md-6',
              props: {
                checkboxLabel: translate('TPA.EarlyRetirementPension'),
              },
            },
            {
              key: 'starobni',
              type: 'checkbox',
              className: 'p-col-12 p-md-6',
              props: {
                checkboxLabel: translate('TPA.RetirementPension'),
              },
            },
            {
              key: 'invalidni',
              type: 'checkbox',
              className: 'p-col-12 p-md-6',
              props: {
                checkboxLabel: translate('TPA.DisabilityPension'),
              },
            },
            {
              className: 'p-col-12 p-md-6',
              fieldGroup: [
                {
                  key: 'jinyDuchod',
                  type: 'checkbox',
                  className: 'p-col-12',
                  props: {
                    checkboxLabel: translate('TPA.OtherPension'),
                  },
                },
                {
                  key: 'jiny',
                  type: 'input',
                  className: 'p-col-12',
                  parsers: [specialCharsParser],
                  modelOptions: {
                    updateOn: 'blur',
                  },
                  props: {
                    label: translate('TPA.OtherPensionDescription'),
                  },
                  hideExpression: (m, formState, field) => {
                    return !(
                      m != null &&
                      m.jinyDuchod == true &&
                      m.pobiramDuchod == true
                    );
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  ],
});

export const Certificate = (): FormlyFieldConfig => ({
  wrappers: ['panel-wrapper'],
  className: 'p-col-12',
  props: {
    label: translate('TPA.IdentificationCard'),
  },
  fieldGroup: CertificateBody(),
  hideExpression: (m, formState, field) => {
    return field?.formControl?.root?.value?.personalPart?.personalInfo?.statniPrislusnost == 'CZ';
  },
});

export const CertificateBody = (): FormlyFieldConfig[] => [
  {
    key: 'cisloDokladu',
    type: 'input',
    className: 'p-col-12 p-md-6',
    parsers: [specialCharsParser],
    modelOptions: {
      updateOn: 'blur',
    },
    props: {
      label: translate('TPA.IdentificationCardNumber'),
    },
    expressionProperties: {
      'props.required': (m, formState, field) => {
        return field?.formControl?.root?.value?.personalPart?.personalInfo?.statniPrislusnost !== 'CZ';
      },
    },
  },
  {
    key: 'typDokladu',
    type: 'select',
    className: 'p-col-12 p-md-6',
    props: {
      placeholder: translate('TPA.SelectPlaceholder'),
      label: translate('TPA.IdentificationCardType'),
      required: true,
      options: [
        { label: translate('TPA.OP'), value: 'OP' },
        { label: translate('TPA.Passport'), value: 'P' },
      ],
    },
    expressionProperties: {
      'props.required': (m, formState, field) => {
        return field?.formControl?.root?.value?.personalPart?.personalInfo?.statniPrislusnost !== 'CZ';
      },
    },
  },
  {
    key: 'vydalStat',
    type: 'select',
    className: 'p-col-12 p-md-6',
    props: {
      placeholder: translate('TPA.SelectPlaceholder'),
      label: translate('TPA.IssuedByState'),
      required: true,
      options: countryOptions(),
    },
    expressionProperties: {
      'props.required': (m, formState, field) => {
        return field?.formControl?.root?.value?.personalPart?.personalInfo?.statniPrislusnost !== 'CZ';
      },
    },
  },
  {
    key: 'datumExpirace',
    type: 'date-picker',
    className: 'p-col-12 p-md-6',
    props: {
      label: translate('TPA.ExpirationDate'),
      required: true,
    },
    expressionProperties: {
      'props.required': (m, formState, field) => {
        return field?.formControl?.root?.value?.personalPart?.personalInfo?.statniPrislusnost !== 'CZ';
      },
    },
  },
];

export const CestnaProhlaseni = () => [
  {
    template: `
        <h2>5. ${translate(
          'TPA.TaxpayerDeclaration'
        )} ${new Date().getFullYear()}</h2>
        <p>${translate('TPA.WantDeclaration')}</p>
      `,
  },
  {
    key: 'chciProhlaseni',
    type: 'yes-or-no',
    className: 'p-col-12',
    props: {
      required: true,
      translate: true,
      yesText: 'TPA.Yes',
      noText: 'TPA.No',
      description: 'TPA.AffidavitDesc',
      // change: (field: FormlyFieldConfig) => {
      //   if (field?.formControl?.value == false) {
      //     const prohlaseniKProhlaseni = field?.parent?.fieldGroup?.find(
      //       (p) => p.key == 'prohlaseniKProhlaseni'
      //     );
      //     if (prohlaseniKProhlaseni)
      //       prohlaseniKProhlaseni.formControl?.patchValue(undefined);
      //   }
      // },
    },
  },
  {
    // TODO: JAkub ROK musime pořesit!!!
    template: `
        <p>${translate('TPA.NoOtherDeclaration')}</p>
      `,
    hideExpression: '(model?.chciProhlaseni != true)',
  },
  {
    key: 'prohlaseniKProhlaseni',
    type: 'yes-or-no',
    className: 'p-col-12',
    props: {
      required: true,
      translate: true,
      yesText: 'TPA.Yes',

      noText: 'TPA.No',
    },
    hideExpression: '(model?.chciProhlaseni != true)',
    validators: {
      validation: [{ name: 'requiredTrue' }],
    },
  },
];

export const CestnaProhlaseniNaKonci = (): FormlyFieldConfig => ({
  className: 'p-col-12',
  fieldGroup: [
    {
      className: 'p-col-12',
      template: `
      <br>
      <h2>
      6. ${translate('TPA.EndingStatements')} </h2>
      <p class="description">
      ${translate('TPA.ChangeInfo')}
      </p>`,
    },
    {
      className: 'p-col-12',
      template: `<p class="description">
      ${translate('TPA.ConsequencesInfo')}
      </p>`,
    },
    {
      key: 'pravdivostUdaju',
      type: 'checkbox',
      className: 'p-col-12',
      props: {
        checkboxLabel: translate('TPA.TruthDeclaration'),
      },
      validators: {
        validation: [{ name: 'texDeclarationRequiredTrue' }],
      },
      validation: {
        show: true,
      },
      expressionProperties: {
        'validation.show': (m, formState, field) => {
          return field?.parent?.parent?.formControl?.touched;
          //return true;
        },
      },
    },
  ],
});

export const ProhlaseniZakladniSlevy = (): FormlyFieldConfig => ({
  wrappers: ['panel-wrapper'],
  className: 'p-col-12',
  props: {
    label: translate('TPA.BasicDiscounts'),
  },
  validators: {
    validation: [
      {
        name: 'validateDiscountForInvalidityCollision',
        options: { errorPath: 'rozsirenaSlevaNaInvalidituMesice' },
      },
    ],
  },
  fieldGroup: [
    {
      key: 'zakladniSlevaNaPoplatnika',
      type: 'checkbox',
      className: 'p-col-12',
      defaultValue: true,
      props: {
        checkboxLabel: translate('TPA.TaypayerDiscount'),
        // required: true,
      },
    },

    {
      key: 'zakladniSlevaNaInvaliditu',
      type: 'checkbox',
      className: 'p-col-12',
      props: {
        checkboxLabel: translate('TPA.BasicDisabilitySiscount'),
        change: (field?: FormlyFieldConfig) => {
          if (field?.formControl?.value != true) {
            let a = MonthFlags.All;
            a ^= currentMonthMask(
              new Date(Date.now()).getMonth()
            ) as MonthFlags;

            const zakladniSlevaNaInvalidituMesice =
              field?.parent?.fieldGroup?.find(
                (p) => p.key == 'zakladniSlevaNaInvalidituMesice'
              );

            if (zakladniSlevaNaInvalidituMesice)
              zakladniSlevaNaInvalidituMesice.formControl?.patchValue(a);
          }
        },
      },
      expressionProperties: {
        'props.disabled': (model, formState, field) => {
          if (model?.zakladniSlevaNaInvalidituMesice)
            return model?.zakladniSlevaNaInvalidituMesice > 0;
          return false;
        },
      },
    },
    {
      className: 'p-col-12',
      template: `<p class="description">
      ${translate('TPA.BasicDisabilityInfo')}
      </p>`,
    },

    {
      key: 'zakladniSlevaNaInvalidituMesice',
      type: 'new-month-select',
      props: {
        checkboxLabel: translate('TPA.ForMonths'),
      },
      hideExpression:
        '!(model?.zakladniSlevaNaInvaliditu == true || (model?.zakladniSlevaNaInvalidituMesice && model?.zakladniSlevaNaInvalidituMesice >  0))',
    },

    {
      key: 'rozsirenaSlevaNaInvaliditu',
      type: 'checkbox',
      className: 'p-col-12',
      props: {
        checkboxLabel: translate('TPA.EnhancedDisabilitySiscount'),
        change: (field?: FormlyFieldConfig) => {
          if (field?.formControl?.value != true) {
            let a = MonthFlags.All;
            a ^= currentMonthMask(
              new Date(Date.now()).getMonth()
            ) as MonthFlags;

            const rozsirenaSlevaNaInvalidituMesice =
              field?.parent?.fieldGroup?.find(
                (p) => p.key == 'rozsirenaSlevaNaInvalidituMesice'
              );
            if (rozsirenaSlevaNaInvalidituMesice) {
              rozsirenaSlevaNaInvalidituMesice.formControl?.setValue(a);
            }
          }
        },
      },
      expressionProperties: {
        'props.disabled': (m, formState, field) => {
          if (m.rozsirenaSlevaNaInvalidituMesice)
            return m.rozsirenaSlevaNaInvalidituMesice > 0;
          return false;
        },
      },
    },
    {
      className: 'p-col-12',
      template: `<p class="description">
      ${translate('TPA.EnhancedDisabilityInfo')}
      </p>`,
    },
    {
      key: 'rozsirenaSlevaNaInvalidituMesice',
      type: 'month-select',
      props: {
        checkboxLabel: translate('TPA.ForMonths'),
      },
      validation: {
        show: true,
      },
      hideExpression:
        '!(model?.rozsirenaSlevaNaInvaliditu == true || (model?.rozsirenaSlevaNaInvalidituMesice && model?.rozsirenaSlevaNaInvalidituMesice >  0))',
    },
    {
      key: 'slevaNaDrziteleZTPP',
      type: 'checkbox',
      className: 'p-col-12',
      props: {
        checkboxLabel: translate('TPA.ZtppDiscount'),
        change: (field?: FormlyFieldConfig) => {
          if (field?.formControl?.value != true) {
            let a = MonthFlags.All;
            a ^= currentMonthMask(
              new Date(Date.now()).getMonth()
            ) as MonthFlags;

            const slevaNaDrziteleZTPPMesice = field?.parent?.fieldGroup?.find(
              (p) => p.key == 'slevaNaDrziteleZTPPMesice'
            );
            if (slevaNaDrziteleZTPPMesice)
              slevaNaDrziteleZTPPMesice.formControl?.setValue(a);
          }
        },
      },
      expressionProperties: {
        'props.disabled': (m, formState, field) => {
          if (m.slevaNaDrziteleZTPPMesice)
            return m.slevaNaDrziteleZTPPMesice > 0;
          return false;
        },
      },
    },

    {
      className: 'p-col-12',
      template: `<p class="description">
      ${translate('TPA.ZtppInfo')}
      </p>`,
    },
    {
      key: 'slevaNaDrziteleZTPPMesice',
      type: 'month-select',
      props: {
        checkboxLabel: translate('TPA.ForMonths'),
      },
      hideExpression:
        '!(model?.slevaNaDrziteleZTPP == true || (model?.slevaNaDrziteleZTPPMesice && model?.slevaNaDrziteleZTPPMesice >  0))',
    },
    {
      key: 'slevaNaStudenta',
      type: 'checkbox',
      className: 'p-col-12',
      props: {
        checkboxLabel: translate('TPA.StudentDiscount'),
        change: (field?: FormlyFieldConfig) => {
          if (field?.formControl?.value != true) {
            let a = MonthFlags.All;
            a ^= currentMonthMask(
              new Date(Date.now()).getMonth()
            ) as MonthFlags;

            const slevaNaStudentaMesice = field?.parent?.fieldGroup?.find(
              (p) => p.key == 'slevaNaStudentaMesice'
            );
            if (slevaNaStudentaMesice)
              slevaNaStudentaMesice.formControl?.setValue(a);
          }
        },
      },
      expressionProperties: {
        'props.disabled': (m, formState, field) => {
          if (m.slevaNaStudentaMesice) return m.slevaNaStudentaMesice > 0;
          return false;
        },
      },
    },
    {
      className: 'p-col-12',
      template: `<p class="description">
      ${translate('TPA.StudentInfo')}
      </p>`,
    },
    {
      key: 'slevaNaStudentaMesice',
      type: 'month-select',
      props: {
        checkboxLabel: translate('TPA.ForMonths'),
      },
      hideExpression:
        'formState.versionV2 || !(model?.slevaNaStudenta == true || (model?.slevaNaStudentaMesice && model?.slevaNaStudentaMesice >  0))',
    },
  ],
});

export const currentMonthMask = (n: number = 10) => {
  if (n == 0) return 0;
  return (((1 << (n - 1)) - 1) << 1) | 1;
};

export const uplatneniForm = (): FormlyFieldConfig => ({
  className: 'p-col-12',
  wrappers: ['panel-wrapper'],
  fieldGroup: [
    {
      key: 'narokVeVysi',
      type: 'select',
      className: 'p-col-12 p-sm-6 p-w1400-4',
      props: {
        placeholder: translate('TPA.SelectPlaceholder'),
        label: translate('TPA.ClaimAmount'),
        required: true,
        options: [
          { label: translate('TPA.ClaimAmount1'), value: 'firstchild' },
          { label: translate('TPA.ClaimAmount2'), value: 'secondchild' },
          { label: translate('TPA.ClaimAmount3'), value: 'thirdandmorechild' },
          { label: translate('TPA.ClaimAmountNo'), value: 'notapply' },
        ],
      },
    },
    {
      key: 'ztpp',
      type: 'yes-or-no',
      className: 'p-col-12 p-sm-6 p-w1400-4',
      props: {
        translate: true,
        yesText: 'TPA.Yes',
        required: true,
        noText: 'TPA.No',
        label: translate('TPA.Ztpp'),
      },
      hideExpression: "model?.narokVeVysi == 'notapply'",
    },
    {
      key: 'zletileDite',
      type: 'yes-or-no',
      className: 'p-col-12 p-sm',
      props: {
        translate: true,
        yesText: 'TPA.Yes',

        noText: 'TPA.No',
        label: translate('TPA.AdultChild'),
        required: true,
      },
      hideExpression: "model?.narokVeVysi == 'notapply'",
    },
    {
      key: 'mesice',
      type: 'month-select',
      className: 'p-col-12',
      hooks: {
        onInit: (field?: FormlyFieldConfig) => {
          //this.props.label = 'Email Address Updated';
          let a = MonthFlags.All;
          a ^= currentMonthMask(new Date(Date.now()).getMonth()) as MonthFlags;
          if (!field?.formControl?.value) field?.formControl?.setValue(a);
        },
      },
      props: {
        label: translate('TPA.ForMonths'),
        min: 1,
        required: true,
      },
      hideExpression: "model?.narokVeVysi == 'notapply'",
    },
  ],
});

export const sharedTipHintA1 = () => ({
  type: 'tip',
  className: 'p-col-12',
  props: {
    translate: true,
    label: 'TPA.HintA1',
    tagIds: ['F8ED8A9A-6A62-4802-9488-EA9733F4960E'],
  },
  expressionProperties: {
    'props.validFrom': 'formState.validFrom',
    'props.userId': 'formState.ownerId',
    hide: '!formState.displayTooltipA1',
  },
});
export const sharedTipHintZTPP = () => ({
  type: 'tip',
  className: 'p-col-12',
  props: {
    translate: true,
    label: 'TPA.HintZTPP',
    tagIds: ['C42DD17B-C3D0-4DE0-BCBF-19FCCD3EB03A'],
  },
  expressionProperties: {
    'props.validFrom': 'formState.validFrom',
    'props.userId': 'formState.ownerId',
    hide: '!formState.displayZTPP',
    //TODO: Hide
  },
});
export const sharedTipHintZZ = () => ({
  type: 'tip',
  className: 'p-col-12',
  props: {
    translate: true,
    label: 'TPA.HintZZ',
    tagIds: ['3BD837D0-733F-455F-88DF-1FC60CA66B06'],
  },
  expressionProperties: {
    'props.validFrom': 'formState.validFrom',
    'props.userId': 'formState.ownerId',
    /* hide: '!formState.a1', */
    //TODO: Hide
    hide: '!formState.displayZZ',
  },
});

export const sharedTipHintZZProProhlaseni = () => ({
  type: 'tip',
  className: 'p-col-12',
  props: {
    translate: true,
    label: 'TPA.HintZZ',
    tagIds: ['3BD837D0-733F-455F-88DF-1FC60CA66B06'],
  },
  expressionProperties: {
    'props.validFrom': 'formState.validFrom',
    'props.userId': 'formState.ownerId',
    /* hide: '!formState.a1', */
    //TODO: Hide
    hide: '!formState.displayZZProProhlaseni',
  },
});

export const sharedTipHintZTPPProProhlaseni = () => ({
  type: 'tip',
  className: 'p-col-12',
  props: {
    translate: true,
    label: 'TPA.HintZTPP',
    tagIds: ['C42DD17B-C3D0-4DE0-BCBF-19FCCD3EB03A'],
  },
  expressionProperties: {
    'props.validFrom': 'formState.validFrom',
    'props.userId': 'formState.ownerId',
    hide: '!formState.displayZTPPProProhlaseni',
    //TODO: Hide
  },
});

export const sharedTipHintDisabilityPension = () => ({
  type: 'tip',
  className: 'p-col-12',
  props: {
    translate: true,
    label: 'TPA.HintDisabilityPension',
    tagIds: ['3BEB4B6A-9FD4-4704-B4D4-AA666908B321'],
  },
  expressionProperties: {
    'props.validFrom': 'formState.validFrom',
    'props.userId': 'formState.ownerId',
    /* hide: '!formState.a1', */
    //TODO: Hide
    hide: '!formState.displayInvalidniDuchod',
  },
});
export const sharedTipHintEarlyRetirement = () => ({
  type: 'tip',
  className: 'p-col-12',
  props: {
    translate: true,
    label: 'TPA.HintEarlyRetirement',
    tagIds: ['3BEB4B6A-9FD4-4704-B4D4-AA666908B321'],
  },
  expressionProperties: {
    'props.validFrom': 'formState.validFrom',
    'props.userId': 'formState.ownerId',
    /* hide: '!formState.a1', */
    //TODO: Hide
    hide: '!formState.displayPredcasnyDuchod',
  },
});
export const sharedTipHintRetirement = () => ({
  type: 'tip',
  className: 'p-col-12',
  props: {
    translate: true,
    label: 'TPA.HintRetirement',
    tagIds: ['3BEB4B6A-9FD4-4704-B4D4-AA666908B321'],
  },
  expressionProperties: {
    'props.validFrom': 'formState.validFrom',
    'props.userId': 'formState.ownerId',
    /* hide: '!formState.a1', */
    //TODO: Hide
    hide: '!formState.displayStarobniDuchod',
  },
});
export const sharedTipHintOtherPension = () => ({
  type: 'tip',
  className: 'p-col-12',
  props: {
    translate: true,
    label: 'TPA.HintOtherPension',
    tagIds: ['3BEB4B6A-9FD4-4704-B4D4-AA666908B321'],
  },
  expressionProperties: {
    'props.validFrom': 'formState.validFrom',
    'props.userId': 'formState.ownerId',
    /* hide: '!formState.a1', */
    //TODO: Hide
    hide: '!formState.displayJinyDuchod',
  },
});
export const sharedTipHintInsolvention = () => ({
  type: 'tip',
  className: 'p-col-12',
  props: {
    translate: true,
    label: 'TPA.HintInsolvention',
    linkText: 'TPA.HintInsolventionText',
    link: 'TPA.HintInsolventionLink',
    tagIds: [
      '01A1B5E4-9C0B-4FEA-9DA4-4B67159257B7',
      '68874C53-2FDE-4E8C-BBC7-9C2CCBA3B784',
    ],
  },
  expressionProperties: {
    'props.validFrom': 'formState.validFrom',
    'props.userId': 'formState.ownerId',
    hide: '!formState.displayExekuce',
  },
});
export const sharedTipHintResident = () => ({
  type: 'tip',
  className: 'p-col-12',
  props: {
    translate: true,
    label: 'TPA.HintResident',
  },
  expressionProperties: {
    /* hide: '!formState.a1', */

    'props.validFrom': 'formState.validFrom',
    'props.userId': 'formState.ownerId',
    //TODO: Hide
    hide: '!formState.displayRezident',
  },
});
export const sharedTipHintNoresident = () => ({
  type: 'tip',
  className: 'p-col-12',
  props: {
    translate: true,
    label: 'TPA.HintNoresident',
  },
  expressionProperties: {
    /* hide: '!formState.a1', */

    'props.validFrom': 'formState.validFrom',
    'props.userId': 'formState.ownerId',
    //TODO: Hide
    hide: '!formState.displayNerezident',
  },
});
export const sharedTipHintConsultation = () => ({
  type: 'tip',
  className: 'p-col-12',
  props: {
    translate: true,
    label: 'TPA.HintConsultation',
  },
  expressionProperties: {
    /* hide: '!formState.a1', */

    'props.validFrom': 'formState.validFrom',
    'props.userId': 'formState.ownerId',
    //TODO: Hide
    hide: '!formState.displayRezidenturaPoradit',
  },
});

export const sharedTipHintStudentDiscount = () => ({
  type: 'tip',
  className: 'p-col-12',
  props: {
    translate: true,
    label: 'TPA.HintStudentDiscount',
    tagIds: ['9F1A5016-0D34-49A3-94B0-7305DA5CDD3E'],
  },
  expressionProperties: {
    'props.validFrom': 'formState.validFrom',
    'props.userId': 'formState.ownerId',
    // hide in case of form v2 (without student discount) or visibility condition
    hide: 'formState.versionV2 || !formState.displaySlevaNaStudenta',
  },
});
export const sharedTipHintNewChild = () => ({
  type: 'tip',
  className: 'p-col-12',
  props: {
    translate: true,
    label: 'TPA.HintNewChild',
    tagIds: [
      '3C6A7939-0ACA-4836-BAC2-BE23D00E9822',
      '2229EDE2-8807-4871-8B69-D6EFCE791A0D',
    ],
  },
  expressionProperties: {
    'props.validFrom': 'formState.validFrom',
    'props.userId': 'formState.ownerId',
    /* hide: '!formState.a1', */
    //TODO: Hide
    hide: '!formState.displayNoveDite',
  },
});
export const sharedTipHintChildStudent = () => ({
  type: 'tip',
  className: 'p-col-12',
  props: {
    translate: true,
    label: 'TPA.HintChildStudent',
    tagIds: ['2229EDE2-8807-4871-8B69-D6EFCE791A0D'],
  },
  expressionProperties: {
    'props.validFrom': 'formState.validFrom',
    'props.userId': 'formState.ownerId',
    /* hide: '!formState.a1', */
    //TODO: Hide
    hide: '!formState.displaydetiStudent',
  },
});
export const sharedTipHintChildZTPP = () => ({
  type: 'tip',
  className: 'p-col-12',
  props: {
    translate: true,
    label: 'TPA.HintChildZTPP',
    tagIds: ['6ED51680-6FF1-4860-BCF1-1F86E0D58CFE'],
  },
  expressionProperties: {
    'props.validFrom': 'formState.validFrom',
    'props.userId': 'formState.ownerId',
    /* hide: '!formState.a1', */
    //TODO: Hide
    hide: '!formState.displaydetiZTP',
  },
});
export const sharedTipHintChildNextTaxpayer = () => ({
  type: 'tip',
  className: 'p-col-12',
  props: {
    translate: true,
    label: 'TPA.HintChildNextTaxpayer',
    tagIds: ['84DCAE49-B3B5-4B33-89CE-DD7897922C64'],
  },
  expressionProperties: {
    'props.validFrom': 'formState.validFrom',
    'props.userId': 'formState.ownerId',
    /* hide: '!formState.a1', */
    //TODO: Hide
    hide: '!formState.displayDalsiPoplatnikPracujici',
  },
});
export const sharedTipHintChildNextTaxpayerUnemployed = () => ({
  type: 'tip',
  className: 'p-col-12',
  props: {
    translate: true,
    label: 'TPA.HintChildNextTaxpayerUnemployed',
    tagIds: ['A7674DC9-674E-4872-868E-9F094B323D77'],
  },
  expressionProperties: {
    'props.validFrom': 'formState.validFrom',
    'props.userId': 'formState.ownerId',
    /* hide: '!formState.a1', */
    //TODO: Hide
    hide: '!formState.displayDalsiPoplatnikNezamestnany',
  },
});
export const sharedTipHintChildDivorced = () => ({
  type: 'tip',
  className: 'p-col-12',
  props: {
    translate: true,
    label: 'TPA.HintChildDivorced',
    tagIds: ['DDF6A5EA-AD19-433F-85BC-84169116B039'],
  },
  expressionProperties: {
    'props.validFrom': 'formState.validFrom',
    'props.userId': 'formState.ownerId',
    /* hide: '!formState.a1', */
    //TODO: Hide
    hide: '!formState.displayrozvedenySDetma',
  },
});
export const sharedTipHintMarridWithoutSecond = () => ({
  type: 'tip',
  className: 'p-col-12',
  props: {
    translate: true,
    label: 'TPA.HintMarridWithoutSecond',
    tagIds: ['A7674DC9-674E-4872-868E-9F094B323D77'],
  },
  expressionProperties: {
    'props.validFrom': 'formState.validFrom',
    'props.userId': 'formState.ownerId',
    /* hide: '!formState.a1', */
    //TODO: Hide
    hide: '!formState.displayZenatyBezPartneraVDomacnosti',
  },
});
