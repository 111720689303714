import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  sharedQuestion,
  sharedTipHintA1,
  sharedTipHintDisabilityPension,
  sharedTipHintEarlyRetirement,
  sharedTipHintInsolvention,
  sharedTipHintOtherPension,
  sharedTipHintRetirement,
  sharedTipHintZTPP,
  sharedTipHintZZ
} from './complete-form';

/// novy zaklad
export const employeeFormPersonInfo = (
  personalData: boolean,
  editable: boolean
): FormlyFieldConfig[] => [
    {
      key: 'personalPart',
      props: {},
      // expressionProperties: {
      //   className: () => (personalData ? 'p-col-12' : 'none'),
      //   'props.disabled': () => !editable,
      // },
      expressionProperties: {
        'props:disabled': 'formState.disablePersonalPart',
      },
      fieldGroup: [
        {
          type: 'h2',
          props: {
            prefix: '1. ',
            label: 'TPA.BasicInfo',
          },
        },
        {
          type: 'personal-info',
          key: 'personalInfo',
          className: 'p-col-12',
          props: {
            translate: true,
            label: 'TPA.BasicInfo',
          },
          expressionProperties: {
            'props.readonly': 'formState.disablePersonalPart',
          },
        },

        {
          type: 'h2',
          props: {
            prefix: '2. ',
            label: 'TPA.Addresses',
          },
        },
        {
          type: 'address',
          key: 'permanentniAdresa',
          props: {
            translate: true,
            label: 'TPA.PermanentAddress',
            description: 'TPA.DocumentAccordance',
          },
          expressionProperties: {
            'props.readonly': 'formState.disablePersonalPart',
          },
        },
        {
          type: 'address',
          key: 'kontaktniAdresa',
          props: {
            translate: true,
            label: 'TPA.ContactAddress',
            actionPathKey: 'permanentniAdresa',
            actionText: 'TPA.SetUpSameAsPermanentniAdresa',
            anotherAddress: 'TPA.SetUpSameAsPermanentniAdresa',
          },

          expressionProperties: {
            'props.readonly': 'formState.disablePersonalPart',
            'props.sameAsAnotherAddress': (model, formState, field) => {
              const a = JSON.stringify(
                field?.parent?.fieldGroup?.find(
                  (p) => p.key == 'permanentniAdresa'
                )?.model
              );
              const m = JSON.stringify(model);
              return a == m;
            },

          },
        },
        sharedQuestion({
          key: 'mamCeskouPrechodnouAdresu',
          className: "pl-[10px] block p-col-12",
          props: {
            label: 'TPA.Guide.Step3DoYouHaveCheckAddress',
            translate: true,
            labelClass: 'h4',
          },
          hideExpression: (model, formState, field) =>
            field?.parent?.model?.personalInfo?.statniPrislusnost == 'CZ'
        }),
        {
          type: 'address',
          key: 'prechodnaAdresa',
          props: {
            translate: true,
            label: 'TPA.TemporaryAddress',
            actionPathKey: 'kontaktniAdresa',
            actionText: 'TPA.SetUpSameAsContactAddress',
            anotherAddress: 'TPA.SetUpSameAsContactAddress',
          },
          expressionProperties: {
            'props.readonly': 'formState.disablePersonalPart',
            'props.disabled': 'formState.disablePersonalPart',
            'props.sameAsAnotherAddress': (model, formState, field) => {
              const a = JSON.stringify(
                field?.parent?.fieldGroup?.find((p) => p.key == 'kontaktniAdresa')
                  ?.model
              );
              const m = JSON.stringify(model);
              return a == m;
            },
          },
          validators: {
            validation: [
              {
                name: 'validateCzechRepublicAddress',
                options: { errorPath: 'zeme' },
              },
            ],
          },
          hideExpression: (m, formState, field) => {
            return !field?.parent?.model?.mamCeskouPrechodnouAdresu || field?.parent?.model?.personalInfo?.statniPrislusnost == 'CZ';
          },
        },
        {
          type: 'h2',
          props: {
            prefix: '3. ',
            label: 'TPA.AdditionalInfo',
          },
        },
        {
          type: 'wage',
          key: 'wage',
          props: {
            translate: true,
            label: 'TPA.Salary',
          },
          expressionProperties: {
            'props.readonly': 'formState.disablePersonalPart',
          },
        },
        {
          type: 'health',
          key: 'health',
          props: {
            translate: true,
            label: 'TPA.HealthInsuranceAndState',
          },
          expressionProperties: {
            'props.readonly': 'formState.disablePersonalPart',
          },
        },
        sharedTipHintA1(),
        sharedTipHintZTPP(),
        sharedTipHintZZ(),
        sharedTipHintDisabilityPension(),
        sharedTipHintEarlyRetirement(),
        sharedTipHintRetirement(),
        sharedTipHintOtherPension(),

        {
          type: 'idcard',
          key: 'idcard',
          props: {
            translate: true,
            label: 'TPA.IdentificationCard',
          },
          hideExpression: (m, formState, field) => {
            return field?.parent?.model?.personalInfo.statniPrislusnost == 'CZ';
          },
          expressionProperties: {
            'props.readonly': 'formState.disablePersonalPart',
          },
        },

        {
          type: 'anotherCompany',
          key: 'dalsiPracovniPomer',
          props: {
            translate: true,
            label: 'TPA.OtherEmployers',
          },
          expressionProperties: {
            'props.readonly': 'formState.disablePersonalPart',
          },
        },
        {
          type: 'executions',
          key: 'execution',
          props: {
            translate: true,
            label: 'TPA.Execution',
          },
          expressionProperties: {
            'props.readonly': 'formState.disablePersonalPart',
          },
        },
        sharedTipHintInsolvention(),

        // Tax(),
        // {
        //   template: `<h2 class="h2-form">4.
        //       ${translate('TPA.AdditionalInfo')}
        //       </h2>`,
        // },
        // BankAccount(),
        // Health(),
        // Certificate(),
      ],
    },
  ];
