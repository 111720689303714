import moment from 'moment';
import { z } from 'zod';

export const DateOnly = z.unknown().transform(value => {
  try {

    if (!value) {
      return null
    }
    if (value instanceof Date) {
      return value.toISOString();
    }

    if (typeof value === 'string') {
      if (moment(value, moment.ISO_8601, true).isValid() && value.includes("T")) {
        return value
      }
    }
    return moment(value, 'yyyy-MM-DD').toDate().toISOString();


  } catch (error) {
    console.error(error)
    return null;
  }
});

export const RodneCislo = z.object({
  ceskeRodneCislo: z.boolean().nullish().transform(x => {
    return x === null || x === undefined ? true : x
  }),
  rodneCislo: z.string().nullish(),
  datumNarozeni: DateOnly.nullish(),
})

export type RodneCislo = z.infer<typeof RodneCislo>

export const Address = z.object({
  ulice: z.string().nullish(),
  popCislo: z.string().nullish(),
  orCislo: z.string().nullish(),
  mesto: z.string().nullish(),
  psc: z.string().nullish(),
  zeme: z.string().nullish(),
}).default({
  ulice: null,
  popCislo: null,
  orCislo: null,
  mesto: null,
  psc: null,
  zeme: null
});
