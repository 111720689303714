import { Component } from '@angular/core';
import {
  FieldType,
  FormlyExtension,
  FormlyFieldConfig
} from '@ngx-formly/core';
import { sharedSalary } from '../../form-parts-definition';
@Component({
  providers: [],
  selector: 'tpa-wage',
  templateUrl: './wage.component.html',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'tpa-wrapper__control' },
})
export class WageComponent extends FieldType implements FormlyExtension {
  defaultOptions = {
    className: 'p-col-12',
    props: {
      translate: true,
      open: false,
    },
  };

  onPopulate(field: FormlyFieldConfig) {
    if (field.fieldGroup) {
      return;
    }
    field.fieldGroup = sharedSalary();
  }
}
