import { AddressComponent } from './address/address.component';
import { AffidavitComponent } from './affidavit/affidavit.component';
import { AlwaysOpenWrapperComponent } from './always-open-wrapper/always-open-wrapper.component';
import { AnotherCompanyComponent } from './another-company/another-company.component';
import { ChangesSummaryComponent } from './changes-summary/changes-summary.component';
import { CheckboxDiscountComponent } from './checkbox-discount/checkbox-discount.component';
import { ChildrenComponent } from './children/children.component';
import { ErrorWrapperComponent } from './error-wrapper/error-wrapper.component';
import { ExecutionsComponent } from './executions/executions.component';
import { FormlyFieldH2 } from './h2/h2.component';
import { HealthComponent } from './health/health.component';
import { IdCardComponent } from './idcard/idcard.component';
import { MonthDiscountComponent } from './month-discount/month-discount.component';
import { MonthSelecetComponent } from './month-select/month-select.component';
import { FormlyFieldMonthlySelect } from './month-select/month-select.field';
import { PartWrapperComponent } from './part-wrapper/part-wrapper.component';
import { PartnerComponent } from './partner/partner.component';
import { PersonalInfoComponent } from './personal/personal.component';
import { RezidenturaComponent } from './rezidentura/rezidentura.component';
import { SummaryPersonalComponent } from './summary-personal/summary-personal.component';
import { SummaryTaxDeclarationComponent } from './summary-tax-declaration/summary-tax-declaration.component';
import { TipComponent } from './tip/tip.component';
import { WageComponent } from './wage/wage.component';
import { EmployeeCardWrapperComponent } from './wrapper/wrapper.component';
import { BirthNumberAndBirthDateComponent } from './birthnumber-and-birth-date/birthNumber-and-birth-date.component';

export const FORMLY_PARTS = [
  PersonalInfoComponent,
  TipComponent,
  MonthSelecetComponent,
  FormlyFieldMonthlySelect,
  FormlyFieldH2,
  AddressComponent,
  WageComponent,
  HealthComponent,
  IdCardComponent,
  AnotherCompanyComponent,
  ExecutionsComponent,
  RezidenturaComponent,
  MonthDiscountComponent,
  ChildrenComponent,
  AffidavitComponent,
  CheckboxDiscountComponent,
  PartnerComponent,
  ChangesSummaryComponent,
  SummaryPersonalComponent,
  SummaryTaxDeclarationComponent,
  ErrorWrapperComponent,
  BirthNumberAndBirthDateComponent
];

export const FORMLY_TYPES = [
  {
    name: 'tip',
    className: 'p-col-12',
    component: TipComponent,
  },
  {
    name: 'h2',
    className: 'p-col-12',
    component: FormlyFieldH2,
  },
  {
    name: 'personal-info',
    component: PersonalInfoComponent,
    wrappers: ['employee-card-wrapper'],
  },
  {
    name: 'address',
    component: AddressComponent,
    wrappers: ['employee-card-wrapper'],
  },
  {
    name: 'wage',
    component: WageComponent,
    wrappers: ['employee-card-wrapper'],
  },
  {
    name: 'health',
    component: HealthComponent,
    wrappers: ['employee-card-wrapper'],
  },
  {
    name: 'idcard',
    component: IdCardComponent,
    wrappers: ['employee-card-wrapper'],
  },
  {
    name: 'anotherCompany',
    component: AnotherCompanyComponent,
    wrappers: ['employee-card-wrapper'],
  },
  {
    name: 'executions',
    component: ExecutionsComponent,
    wrappers: ['employee-card-wrapper'],
  },

  {
    name: 'rezidentura',
    component: RezidenturaComponent,
    wrappers: ['employee-card-wrapper'],
  },
  {
    name: 'month-discount',
    component: MonthDiscountComponent,
    wrappers: ['employee-card-wrapper'],
  },
  {
    name: 'checkbox-discount',
    component: CheckboxDiscountComponent,
    wrappers: ['employee-card-wrapper'],
  },
  {
    name: 'children',
    component: ChildrenComponent,
    wrappers: ['employee-card-wrapper'],
  },

  {
    name: 'affidavit',
    component: AffidavitComponent,
    wrappers: ['employee-card-wrapper'],
  },
  {
    name: 'partner',
    component: PartnerComponent,
    wrappers: ['employee-card-wrapper'],
  },

  {
    name: 'new-month-select',
    className: 'p-col-12',
    component: FormlyFieldMonthlySelect,
    wrappers: ['form-field'],
  },
  {
    name: 'changes-summary',
    className: 'p-col-12',
    component: ChangesSummaryComponent,
  },
  {
    name: 'summary-personal',
    component: SummaryPersonalComponent,
    wrappers: ['employee-card-wrapper'],
  },
  {
    name: 'summary-tax-declaration',
    component: SummaryTaxDeclarationComponent,
    wrappers: ['employee-card-wrapper'],
  },

  {
    name: 'birth-number-and-birth-date',
    component: BirthNumberAndBirthDateComponent,
    /*wrappers: ['employee-card-wrapper'],*/
  },
];

export const FORMLY_WRAPPER_DECLARATION = [
  EmployeeCardWrapperComponent,
  AlwaysOpenWrapperComponent,
  PartWrapperComponent,
];

export const FORMLY_WRAPPER = [
  {
    name: 'part-wrapper',
    component: PartWrapperComponent,
  },
  {
    name: 'employee-card-wrapper',
    component: EmployeeCardWrapperComponent,
  },
  {
    name: 'always-open-wrapper',
    component: AlwaysOpenWrapperComponent,
  },
  {
    name: 'error-wrapper',
    component: ErrorWrapperComponent,
  },

];
