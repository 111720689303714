import { AnnualTaxSettlementV2024Dto, CompanyAddressDto, RzdMetadataDto } from "@tpa/api";
import { TaxDeclaration2024PrintTemplateData } from "../models";
import { MonthPipe, YesOrNoPipe } from "../pipes";
import { countryOptions } from "./countries.options";
import { birthNumberOrBirthDate, formatDate, getCountry, toPrintAddress } from "./print-formatters.util";
interface Dite {
  jmeno?: string;
  prijmeni?: string;
  rodneCisloADatumNarozeni?: {
    ceskeRodneCislo?: boolean;
    rodneCislo?: string;
    datumNarozeni?: string;
  };
  uplatneni?: {
    mesice?: number;
    ztpp?: boolean;
    narokVeVysi?: string;
    zletileDite?: boolean;
  }[];
}

export function additionalApplyTaxDeclarationToPrint(data: AnnualTaxSettlementV2024Dto | null, metadata: RzdMetadataDto | null, year: number): TaxDeclaration2024PrintTemplateData | null {
  if (data === null || data === undefined) {
    return null;
  }

  const getCountry = (country: string): string => {
    return countryOptions().find(x => x.value === country)?.label ?? '';
  }

  const monthPipe: MonthPipe = new MonthPipe();
  const yesornoPipe: YesOrNoPipe = new YesOrNoPipe()

  const cisloATypDokladu = [data.rezidentura.neRezident.cisloDokladu, data.rezidentura.neRezident.typDokladu].filter((p) => !!p).join(", ")

  return {
    rok: year.toString(),
    identifikacePoplatnika: {
      jmeno: data.osobniCast?.jmeno,
      prijmeni: data.osobniCast?.prijmeni,
      rodneCislo: birthNumberOrBirthDate(data.osobniCast?.rodneCisloADatumNarozeni),
      adresaBydliste: toPrintAddress(data.osobniCast?.permanentniAdresa),
    },
    nazevPlatceDane: metadata ? metadata.tenantName : '',
    adresaPlatceDane: metadata ? taxPayerAddress(metadata.companyAddress) : '',
    nerezident: data.rezidentura?.rezidentura !== 'true' ? {
      datumNarozeni: formatDate(data.osobniCast?.rodneCisloADatumNarozeni?.datumNarozeni),
      cisloATypDokladu,// ! Chybí v dat. modelu,,
      dokladStat: getCountry(data.rezidentura.neRezident.vydalStat), // ! Chybí v dat. modelu,
      statRezidence: getCountry(data.rezidentura?.neRezident?.rezidenturaVeState) ?? '',
      identifikace: data.rezidentura?.neRezident?.identifikace ?? ''
    } : null,
    slevy: {
      poplatnik: data.dodatecneUplatneni?.zakladniSlevaNaPoplatnika
        ? 'XD'
        : '',
      zakladniInvalidita: data.dodatecneUplatneni?.rezident?.zakladniSlevaNaInvalidituMesice
        ? 'XD ' + monthPipe.transform(data.dodatecneUplatneni?.rezident?.zakladniSlevaNaInvalidituMesice)
        : '',
      rozsirenaInvalidita: data.dodatecneUplatneni?.rezident?.rozsirenaSlevaNaInvalidituMesice
        ? 'XD ' + monthPipe.transform(data.dodatecneUplatneni?.rezident?.rozsirenaSlevaNaInvalidituMesice)
        : '',
      ztpp: data.dodatecneUplatneni?.rezident?.slevaNaDrziteleZTPPMesice
        ? 'XD ' + monthPipe.transform(data.dodatecneUplatneni?.rezident?.slevaNaDrziteleZTPPMesice)
        : '',
    },
    detiUplatnuji: getChildren(data.dodatecneUplatneni?.rezident?.deti).length > 0,
    vyzivovaneDeti: getChildren(data.dodatecneUplatneni?.rezident?.deti),
    vyzivujeJinyPoplatnik: data.dodatecneUplatneni?.rezident?.dalsiPoplatnik,
    jinyPoplatnik: data.dodatecneUplatneni?.rezident?.dalsiPoplatnik ? {
      jmeno: data.dodatecneUplatneni?.rezident?.poplatnik?.jmeno ?? '',
      prijmeni: data.dodatecneUplatneni?.rezident?.poplatnik?.prijmeni ?? '',
      rodneCislo: birthNumberOrBirthDate(data.dodatecneUplatneni?.rezident?.poplatnik?.rodneCisloADatumNarozeni),
      adresa: toPrintAddress(data.dodatecneUplatneni?.rezident?.poplatnik?.adresa),
      uplatnuje: yesornoPipe.transform(data.dodatecneUplatneni?.rezident?.poplatnik?.uplatnuje),
      zamestnani: yesornoPipe.transform(data.dodatecneUplatneni?.rezident?.poplatnik?.zamestnani),
      zamestnavatel: data.dodatecneUplatneni?.rezident?.poplatnik?.zamestnani
        ?
        {
          nazev: data.dodatecneUplatneni?.rezident?.poplatnik?.zamestnavatel?.nazev,
          adresa: toPrintAddress(data.dodatecneUplatneni?.rezident?.poplatnik?.zamestnavatel?.adresa)
        }
        : null
    } : null,
    podpisPlatce: {
      jmenoAPrijmeni: metadata && metadata.signedAt ? data.osobniCast?.jmeno + ' ' + data.osobniCast?.prijmeni : '',
      datum: metadata ? formatDate(metadata.signedAt) : ''
    },
    dodatecneCinim: true,
    podpisMzdovka: metadata.accountantName ? { jmenoAPrijmeni: metadata.accountantName, datum: formatDate(metadata.accountSignDate) } : null,
    zmeny: null,
    vyzivovaneDetiZmena: null

  }
}




function applyChildren(value: string) {
  switch (value) {
    case "firstchild":
      return "1.";
    case "secondchild":
      return "2.";
    case "thirdandmorechild":
      return "3.";
    case "notapply":
      return "N";
  }
}

function taxPayerAddress(companyAddress: CompanyAddressDto) {
  const parts: string[] = [];
  if (companyAddress.street && companyAddress.descriptiveNumber) {
    parts.push(`${companyAddress.street} ${companyAddress.descriptiveNumber}`)
  }

  if (companyAddress.orientationNumber)
    parts[0] = `${parts[0]}/${companyAddress.orientationNumber}`;

  if (companyAddress.placeOfResidence || companyAddress.postalCode) {
    const parr2 = [];
    if (companyAddress.placeOfResidence) {
      parr2.push(companyAddress.placeOfResidence)
    }
    if (companyAddress.postalCode) {
      parr2.push(companyAddress.postalCode)
    }

    if (parr2.length > 0)
      parts.push(parr2.join(" "))
  }
  if (companyAddress.country) {
    parts.push(getCountry(companyAddress.country))
  }

  return parts.join(", ");
}

function getChildren(deti?: Dite[]) {
  if (!deti) {
    return []
  }
  const monthPipe: MonthPipe = new MonthPipe();
  const arr = []
  deti?.map((dite) => dite.uplatneni?.map((uplatneni) => arr.push({
    jmeno: dite.jmeno,
    prijmeni: dite.prijmeni,
    rodneCislo: birthNumberOrBirthDate(dite.rodneCisloADatumNarozeni),
    uplatnujiNarok: applyChildren(uplatneni.narokVeVysi),
    ztpp: uplatneni.ztpp ? 'X' : '',
    dospelost: uplatneni.zletileDite ? 'X' : '',
    dodatecneMesice: monthPipe.transform(uplatneni.mesice)
  })))
  return arr
}
