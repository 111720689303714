import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';
import { Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';

@Component({
  selector: 'ral-formly-repeat',
  styles: [
    `
      :host {
        display: block;
        width: 100%;
      }
      .floatRemove {
         position: absolute;
        top: 16px;
        right: 16px;
      }
      .p-grid {
        position: relative;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-container *transloco="let t">
      <div *ngFor="let field of field.fieldGroup; let i = index" class="p-grid">
        <formly-field class="p-col" [field]="field"></formly-field>
        <div
          class="p-col-fixed {{ to.attributes.removeClass }} empty:hidden"
          *ngIf="allowRemove"
        >
        <ng-container *ngIf="i === 0 && to['allowRemoveFirst'] || i !== 0 ">
          <!-- <div class="fake-label"> -->
            <button
              class="e-btn e-lib e-danger"
              type="button"
              (click)="remove(i)"
              title="Remove"
            >
              <i class="fal fa-times"></i>
            </button>
          <!-- </div> -->
          </ng-container>
        </div>
      </div>
      <div class="ui-messages-error" *ngIf="showError">
        <div class="p-grid" *ngIf="allowAdd">
          <div class="p-col">
            <formly-validation-message
              [field]="field"
            ></formly-validation-message>
          </div>
        </div>
      </div>
      <div class="p-grid" *ngIf="allowAdd">
        <div class="p-col">
          <button
            class="e-btn e-lib {{ to.attributes.addClass }}"
            type="button"
            (click)="add(null, to.initialModel)"
          >
            {{
              to.addTextT
                ? t(to.addTextT['key'], to.addTextT['params'])
                : to.addText
            }}
          </button>
        </div>
      </div>
    </ng-container>
  `,
})
export class FormlyRepeatCheckFieldInput extends FieldArrayType implements OnInit, OnDestroy {
  // TODO: add explicit constructor

  defaultOptions = {
    props: {
      initialModel: {},
      options: [],
      addText: 'Add',
      allowRemoveFirst: true,
      attributes: {
        grid: 12,
        allowAdd: 'false',
        allowRemove: 'false',
        addClass: 'e-primary',
        removeClass: '', // floatRemove
      },
    },
  };

  get allowAdd() {
    if (this.to.change) this.to.change(this.field, 'add');
    return this.to.attributes.allowAdd === 'true';
  }

  get allowRemove() {
    if (this.to.change) this.to.change(this.field, 'remove');
    return this.to.attributes.allowRemove === 'true';
  }

  constructor(private readonly cdRef: ChangeDetectorRef) {
    super();
  }
  valueChangeSubscription: Subscription | undefined

  ngOnDestroy(): void {
    this.valueChangeSubscription?.unsubscribe();
  }

  ngOnInit(): void {

    this.valueChangeSubscription = this.formControl.valueChanges
      .pipe(startWith(null))
      .subscribe(() => {
        setTimeout(() => {
          if (this.props['allowRemoveFirst'] === false) {
            if (this.model === null || this.model === undefined || this.model.length === 0) {
              this.add(0, 0, { markAsDirty: false });
              this.formControl.markAsTouched()
            }
          }
        }, 0)
      })
  }

  onChange(value: any, checked: boolean) {
    this.formControl.markAsTouched();
  }
}
