import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
import { FormlyModule } from '@ngx-formly/core';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { RalbaUiModule } from '@ralba/ui';
import { ButtonModule, CheckBoxModule } from '@syncfusion/ej2-angular-buttons';
import { DateTimePickerModule } from '@syncfusion/ej2-angular-calendars';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { UploaderModule } from '@syncfusion/ej2-angular-inputs';
import { TreeViewModule } from '@syncfusion/ej2-angular-navigations';
import { FeatureEmployeeDocumentUploadComponent } from '@tpa/feature/employee/document-upload';
import { EmployeesFormPipesModule, SharedModule } from '@tpa/shared';

import { COMPONENTS } from './components';
import { FORMLY_FIELDS_DECLARATION, FormlyFieldsConfig } from './fields';
import {
  FORMLY_PARTS,
  FORMLY_TYPES,
  FORMLY_WRAPPER,
  FORMLY_WRAPPER_DECLARATION,
} from './formly-parts';
import {
  maxlengthValidationMessage,
  maxValidationMessage,
  minlengthValidationMessage,
  minValidationMessage,
  requiredMessage,
  requiredTrue,
  RzdDateValidator,
  taxDeclarationRequiredTrue,
  validateDate,
  validateDiscountForInvalidityCollision,
  validateDiscountsForNotRezidenci,
  validatePhoneNumber,
  validateRodneCislo,
  validateUrokType,
} from './tpa.validations';
import {
  validateCzechRepublicAddress,
  validateMoreOfOneChildInChildren,
  validateNonRezidentHasToBeOutsideCzech,
  validateOneChildApplyInAMonth,
  validationAtLeastOneItem,
  validWagePaymentMethod,
} from './validation/emp.validations';

import { FeatureDocumentsTipsListModule } from '@tpa/feature/documents-tips-list';


@NgModule({
  imports: [
    CommonModule,
    RalbaUiModule,
    ReactiveFormsModule,
    UploaderModule,
    TreeViewModule,
    FormlySelectModule,
    SharedModule,
    ButtonModule,
    DropDownListModule,
    DateTimePickerModule,
    CheckBoxModule,
    TranslocoModule,
    TranslocoLocaleModule,
    EmployeesFormPipesModule,
    FeatureDocumentsTipsListModule,

    FormlyModule.forChild({
      types: [...FormlyFieldsConfig, ...FORMLY_TYPES],
      wrappers: [...FORMLY_WRAPPER],
      validators: [
        {
          name: 'validateOneChildApplyInAMonth',
          validation: validateOneChildApplyInAMonth,
        },
        {
          name: 'validateCzechRepublicAddress',
          validation: validateCzechRepublicAddress,
        },
        {
          name: 'validateNonRezidentHasToBeOutsideCzech',
          validation: validateNonRezidentHasToBeOutsideCzech,
        },
        {
          name: 'validateMoreOfOneChildInChildren',
          validation: validateMoreOfOneChildInChildren,
        },
        {
          name: 'validateDiscountForInvalidityCollision',
          validation: validateDiscountForInvalidityCollision,
        },
        {
          name: 'validateUrokType',
          validation: validateUrokType,
        },
        {
          name: 'validateRodneCislo',
          validation: validateRodneCislo,
        },
        {
          name: 'requiredTrue',
          validation: requiredTrue,
        },
        {
          name: 'taxDeclarationRequiredTrue',
          validation: taxDeclarationRequiredTrue,
        },
        {
          name: 'validateDiscountsForNotRezidenci',
          validation: validateDiscountsForNotRezidenci,
        },
        {
          name: 'validateDate',
          validation: validateDate,
        },
        {
          name: 'validWagePaymentMethod',
          validation: validWagePaymentMethod,
        },
        { name: 'rzdDatesComp', validation: RzdDateValidator },
        { name: 'validatePhoneNumber', validation: validatePhoneNumber },
        {
          name: 'validationAtLeastOneItem',
          validation: validationAtLeastOneItem,
        },
      ],
      validationMessages: [
        { name: 'required', message: requiredMessage },
        { name: 'minLength', message: minlengthValidationMessage },
        { name: 'maxLength', message: maxlengthValidationMessage },
        { name: 'min', message: minValidationMessage },
        { name: 'max', message: maxValidationMessage },
      ],
      extras: {
        resetFieldOnHide: false,
      },
    }),
    FeatureEmployeeDocumentUploadComponent,
  ],
  declarations: [
    ...FORMLY_FIELDS_DECLARATION,
    ...COMPONENTS,
    ...FORMLY_WRAPPER_DECLARATION,
    ...FORMLY_PARTS,
  ],
  exports: [...FORMLY_FIELDS_DECLARATION, ...COMPONENTS],
})
export class TpaFormlyModule { }
