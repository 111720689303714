import { Component } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { translate } from '@ngneat/transloco';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { distinctUntilChanged, startWith, tap } from 'rxjs/operators';
import { convertPersonalIdentificationNumberDateWithoutSlash } from '../../form-parts-definition';

@Component({
  selector: 'tpa-birth-number-and-birth-date',
  template: `
    <formly-field
      *ngFor="let field of field.fieldGroup"
      [field]="field"
    ></formly-field>
  `,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'tpa-wrapper__control' },
})
export class BirthNumberAndBirthDateComponent extends FieldType {
  defaultOptions = {
    className: 'p-col-12',
    props: {
      translate: true,
      full: true,
      open: false,
    },
    fieldGroup: [
      {
        key: 'ceskeRodneCislo',
        type: 'checkbox',
        className: 'p-col-12',
        defaultValue: true,
        props: {
          cssClass: "reverse-checkbox",
        },
        hooks: {
          onInit: (field) => {
            return field.formControl?.valueChanges?.pipe(
              startWith(null),
              distinctUntilChanged(),
              tap((value) => {
                if (field.model.ceskeRodneCislo && field.model.rodneCislo) {
                  const d =
                    convertPersonalIdentificationNumberDateWithoutSlash(
                      field.model?.rodneCislo?.replace('/', '')
                    ).toISOString();
                  const datumNarozeni = field?.parent?.fieldGroup?.find(
                    (p) => p.key == 'datumNarozeni'
                  );
                  if (datumNarozeni) datumNarozeni.formControl?.setValue(d);
                }
              })
            )
          }
        },
        expressions: {
          'className': (field) => {
            return field?.parent?.props?.hideWithoutCeskeRodneCislo === true ? 'hidden' : 'p-col-12'
          },
          "props.checkboxLabel": (field: FormlyFieldConfig) => {
            return field?.parent?.props?.checkboxLabel ?? translate('TPA.IDontHaveCzechBirthNumber')
          }
        }
      },
      {
        key: 'rodneCislo',
        type: 'input',
        className: 'p-col-12 p-md-6',
        props: {
          label: translate('TPA.BirthNumber'),
          placeholder: 'XXXXXX/XXXX',
          change: (field?: FormlyFieldConfig) => {
            if (field?.formControl?.valid && field?.formControl?.value) {
              const d =
                convertPersonalIdentificationNumberDateWithoutSlash(
                  field.formControl.value.replace('/', '')
                ).toISOString();

              const datumNarozeni = field?.parent?.fieldGroup?.find(
                (p) => p.key == 'datumNarozeni'
              );
              if (datumNarozeni) datumNarozeni.formControl?.setValue(d);
            }
          },
        },
        expressions: {
          hide: (field) => {
            return !field.model?.ceskeRodneCislo;
          },
          'props.required': (field) => {
            return field.model?.ceskeRodneCislo;
          },
        },
        validators: {
          validation: [{ name: 'validateRodneCislo' }]
        }
      },
      {
        key: 'datumNarozeni',
        type: 'date-picker',
        className: 'p-col',
        // defaultValue: new Date(),
        props: {
          translate: true,
          label: 'TPA.DateOfBirth',
          required: true,
          attributes: {
            format: 'dd. MM. yyyy',
          },
        },
        validators: {
          dateIsInFeature: {
            expression: (control: AbstractControl) => {
              const value = new Date(control.value);
              if (isNaN(value.getTime())) {
                return true
              }
              return new Date() > value
            },
            message: (error: unknown, field: FormlyFieldConfig) => translate('TPA.Validation_BirthDateIsInFeature')
          }
        },
        expressions: {
          'props.disabled': (field) => {
            return field.model?.ceskeRodneCislo;
          },
          // 'className': (field) => {
          //   return field.model?.ceskeRodneCislo ? 'p-col-12 p-md-3' : 'p-col-12 p-md-6';
          // },
        },
      },

    ]
  };

  get emptyModel() {
    return !this.model || JSON.stringify(this.model) === '{}';
  }


}
