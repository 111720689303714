<ral-card [attr.data-cy]="to['label']" class="tip rounded-sm">
  <div class="e-card-header" style="width: 100%" style="background: var(--surface-b)">
    <div class="p4-grid p4-grid--with-bottom">
      <div *ngIf="to?.['tagIds']" class="p-col-12">
        <h3 class="h3">Tip</h3>
      </div>
      <div class="p-col-12">
        <p style="font-weight: normal; font-size: 15px">
          <span *ngIf="!to?.['tagIds']" class="e-header-bottom-space">
            Tip:
          </span>

          {{ to.label }}

          <a *ngIf="to['link']" [href]="to['link']" class="link" download>{{
            to['linkText']
            }}</a>
        </p>
        <div *ngIf="!to?.['tagIds']" class="e-header-bottom-space"></div>
      </div>
    </div>
  </div>
  <div *ngIf="to['tagIds']" class="e-card-content" style="background: var(--surface-b)">
    <p *ngIf="to.description" class="description">
      {{ to.description }}
    </p>
    <h3 class="h3">
      {{ 'TPA.ProvidedDocuments' | transloco }}
    </h3>
    <ng-container #fieldComponent></ng-container>
    <div *ngIf="to['tagIds'] && to['userId']; else noOwnerId">
      <div *ngFor="let tagId of to['tagIds']">
        <h3 class="h4">{{ 'TPA.' + tagId | transloco | uppercase }}</h3>
        <!-- TODO: pridat document LIST !!!!! -->
        <tpa-documents-tips-list #documentsList (linkClick)="navigateToDocument($event)" [hideHeading]="false"
          [tagId]="tagId" [userId]="to.userId" [validFrom]="to.validFrom" class="block pb-1">
        </tpa-documents-tips-list>
        <tpa-feature-employee-document-upload (uploadCompleted)="documentsList.state.fetchData()"
          [tagId]="tagId"></tpa-feature-employee-document-upload>
      </div>
    </div>
    <ng-template #noOwnerId> Chybějící owner id</ng-template>
  </div>
</ral-card>