<ng-container *ngIf="to['open'] && !formState['readonly'] && !to['readonly']; else card">
  <formly-field *ngFor="let field of field.fieldGroup" [field]="field"></formly-field>
</ng-container>
<ng-template #card>
  <div class="tpa-wrapper__content">
    <p class="description">
      {{ 'TPA.NextTaxpayer' | transloco }}
    </p>
    <p>
      <mark>
        {{ model?.dalsiPoplatnik | yesOrNo | nullToDash }}
      </mark>
    </p>
    <div *ngIf="model?.dalsiPoplatnik">
      <div class="space-12"></div>
      <p class="description">
        {{ 'TPA.NextTaxpayerApplyDiscount' | transloco }}
      </p>
      <p>
        <mark>
          {{ model?.uplatnujePoplatnik | yesOrNo | nullToDash }}
        </mark>
      </p>
      <div class="space-12"></div>

      <p>
        {{ model.jmenoPoplatnika }} {{ model.prijmeniPoplatnika }}
        (<span
          *ngIf="isValid(parseISO(model?.rodneCisloADatumNarozeniPoplatnika?.datumNarozeni)); else stringVersion">{{
          model?.rodneCisloADatumNarozeniPoplatnika?.datumNarozeni | translocoDate: { dateStyle: 'medium' } }}</span>
        <ng-template #stringVersion>{{ model?.rodneCisloADatumNarozeniPoplatnika?.datumNarozeni }}</ng-template>
        <span *ngIf="model?.rodneCisloADatumNarozeniPoplatnika?.ceskeRodneCislo"> - {{
          model?.rodneCisloADatumNarozeniPoplatnika?.rodneCislo
          }} </span>)

      </p>
      <p>
        <span>{{ model.trvalyPobytPoplatnika.ulice }}</span>
        <span> {{ model.trvalyPobytPoplatnika.popCislo }}</span>
        <span *ngIf="model.trvalyPobytPoplatnika.orCislo">/{{ model.trvalyPobytPoplatnika.popCislo }}</span>
        <span>, {{ model.trvalyPobytPoplatnika.mesto }}</span>
        <span>, {{ model.trvalyPobytPoplatnika.psc }}</span>
        <span>, {{ model.trvalyPobytPoplatnika.zeme }}</span>
      </p>

      <div class="space-12"></div>
      <p class="description">
        {{ 'TPA.NextTaxpayerIsEmployed' | transloco }}
      </p>
      <p>
        <mark>
          {{ model?.zamestnaniPoplatnika | yesOrNo | nullToDash }}
        </mark>
      </p>
      <div class="space-12"></div>

      <ng-container *ngIf="model.zamestnaniPoplatnika">
        <p>
          {{ model.platceDanePoplatnika }}
        </p>

        <p>
          <span>{{ model.adresaPlatceDanePoplatnika.ulice }}</span>
          <span> {{ model.adresaPlatceDanePoplatnika.popCislo }}</span>
          <span *ngIf="model.adresaPlatceDanePoplatnika.orCislo">/{{ model.adresaPlatceDanePoplatnika.popCislo }}</span>
          <span>, {{ model.adresaPlatceDanePoplatnika.mesto }}</span>
          <span>, {{ model.adresaPlatceDanePoplatnika.psc }}</span>
          <span>, {{ model.adresaPlatceDanePoplatnika.zeme }}</span>
        </p>
      </ng-container>
    </div>
  </div>
</ng-template>