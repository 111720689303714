import { CompanyAddressDto, EmployeeCardMetadataDto } from "@tpa/api";
import { EmpTabsFormModelV2, MonthPipe, TaxDeclaration2024PrintTemplateData, YesOrNoPipe } from "@tpa/shared";
import { countryOptions } from "./countries.options";
import { birthNumberOrBirthDate, formatDate, getCountry, toPrintAddress } from "./print-formatters.util";

interface Dite {
  jmeno?: string;
  prijmeni?: string;
  rodneCisloADatumNarozeni?: {
    ceskeRodneCislo?: boolean;
    rodneCislo?: string;
    datumNarozeni?: string;
  };
  uplatneni?: {
    mesice?: number;
    ztpp?: boolean;
    narokVeVysi?: string;
    zletileDite?: boolean;
  }[];
}

export function employeeCardToPrintTaxDeclaration(data: EmpTabsFormModelV2 | null, metadata: EmployeeCardMetadataDto | null): Partial<TaxDeclaration2024PrintTemplateData> | null {
  if (data === null || data === undefined) {
    return null;
  }

  const monthPipe: MonthPipe = new MonthPipe();
  const yesornoPipe: YesOrNoPipe = new YesOrNoPipe()
  const s = {
    rok: new Date(Date.now()).getFullYear().toString(),
    identifikacePoplatnika: {
      jmeno: data.personalPart?.personalInfo?.jmeno,
      prijmeni: data.personalPart?.personalInfo?.prijmeni,
      rodneCislo:
        birthNumberOrBirthDate(data.personalPart?.personalInfo?.rodneCisloADatumNarozeni),
      adresaBydliste: toPrintAddress(data.personalPart?.permanentniAdresa),
    },
    adresaPlatceDane: metadata ? taxPayerAddress(metadata.companyAddress) : '',
    nazevPlatceDane: metadata ? metadata.tenantName : '',
    nerezident: data.taxDeclaration?.rezidentura?.rezidentura !== 'true' ? {
      datumNarozeni: formatDate(data.personalPart?.personalInfo?.rodneCisloADatumNarozeni?.datumNarozeni) ?? '',
      cisloATypDokladu: (data.personalPart?.idcard?.cisloDokladu ?? '') + ' ' + (data.personalPart?.idcard?.typDokladu ?? ''),
      dokladStat: getCountry(data.personalPart?.idcard?.vydalStat),
      statRezidence: getCountry(data.taxDeclaration?.rezidentura?.rezidenturaVeState),
      identifikace: data.taxDeclaration?.rezidentura?.identifikace
    } : null,
    slevy: {
      poplatnik: data.taxDeclaration?.taxDeclaration?.monthDiscount?.zakladniSlevaNaPoplatnika
        ? 'X'
        : '',
      zakladniInvalidita: data.taxDeclaration?.taxDeclaration?.monthDiscount?.zakladniSlevaNaInvalidituMesice.value > 0
        ? 'X ' + monthPipe.transform(data.taxDeclaration.taxDeclaration.monthDiscount.zakladniSlevaNaInvalidituMesice.value)
        : '',
      rozsirenaInvalidita: data.taxDeclaration?.taxDeclaration?.monthDiscount?.rozsirenaSlevaNaInvalidituMesice.value > 0
        ? 'X ' + monthPipe.transform(data.taxDeclaration?.taxDeclaration?.monthDiscount?.rozsirenaSlevaNaInvalidituMesice.value)
        : '',
      ztpp: data.taxDeclaration?.taxDeclaration?.monthDiscount?.slevaNaDrziteleZTPPMesice.value > 0
        ? 'X ' + monthPipe.transform(data.taxDeclaration?.taxDeclaration?.monthDiscount?.slevaNaDrziteleZTPPMesice?.value)
        : '',
    },
    detiUplatnuji: false, // Note: Dodatečně jen v případě RZD ;;;getChildren(data.taxDeclaration?.taxDeclaration?.children?.deti).length > 0,
    vyzivovaneDeti: getChildren(data.taxDeclaration?.taxDeclaration?.children?.deti),
    vyzivujeJinyPoplatnik: data.taxDeclaration?.taxDeclaration?.partner?.dalsiPoplatnik,
    jinyPoplatnik: data.taxDeclaration?.taxDeclaration?.partner?.dalsiPoplatnik ? {
      jmeno: data.taxDeclaration?.taxDeclaration?.partner?.jmenoPoplatnika,
      prijmeni: data.taxDeclaration?.taxDeclaration?.partner?.prijmeniPoplatnika,
      rodneCislo: birthNumberOrBirthDate(data.taxDeclaration?.taxDeclaration?.partner?.rodneCisloADatumNarozeniPoplatnika),
      adresa: toPrintAddress(data.taxDeclaration?.taxDeclaration?.partner?.trvalyPobytPoplatnika),
      uplatnuje: yesornoPipe.transform(data.taxDeclaration?.taxDeclaration?.partner?.uplatnujePoplatnik),
      zamestnani: yesornoPipe.transform(data.taxDeclaration?.taxDeclaration?.partner?.zamestnaniPoplatnika),
      zamestnavatel: data.taxDeclaration?.taxDeclaration?.partner?.zamestnaniPoplatnika
        ?
        {
          nazev: data.taxDeclaration?.taxDeclaration?.partner?.platceDanePoplatnika,
          adresa: toPrintAddress(data.taxDeclaration?.taxDeclaration?.partner?.adresaPlatceDanePoplatnika)
        }
        : null
    } : null,
    podpisPlatce: {
      jmenoAPrijmeni: metadata && metadata.signedAt ? data.personalPart?.personalInfo?.jmeno + ' ' + data.personalPart?.personalInfo?.prijmeni : '',
      datum: metadata ? formatDate(metadata.signedAt) : ''
    },
    podpisMzdovka: metadata ? { jmenoAPrijmeni: metadata.accountantName, datum: formatDate(metadata.accountSignDate) } : null,
    dodatecneCinim: false,
    vyzivovaneDetiZmena: null,
    zmeny: null
  }
  return s
}



function applyChildren(value: string) {
  switch (value) {
    case "firstchild":
      return "1.";
    case "secondchild":
      return "2.";
    case "thirdandmorechild":
      return "3.";
    case "notapply":
      return "N";
  }
}

function taxPayerAddress(companyAddress: CompanyAddressDto) {
  const parts: string[] = [];
  if (companyAddress.street && companyAddress.descriptiveNumber) {
    parts.push(`${companyAddress.street} ${companyAddress.descriptiveNumber}`)
  }

  if (companyAddress.orientationNumber)
    parts[0] = `${parts[0]}/${companyAddress.orientationNumber}`;

  if (companyAddress.placeOfResidence || companyAddress.postalCode) {
    const parr2 = [];
    if (companyAddress.placeOfResidence) {
      parr2.push(companyAddress.placeOfResidence)
    }
    if (companyAddress.postalCode) {
      parr2.push(companyAddress.postalCode)
    }

    if (parr2.length > 0)
      parts.push(parr2.join(" "))
  }
  if (companyAddress.country) {
    const getCountry = (country: string): string => {
      return countryOptions().find(x => x.value === country)?.label ?? '';
    }
    parts.push(getCountry(companyAddress.country))
  }

  return parts.join(", ");
}

function getChildren(deti?: Dite[]) {
  if (!deti) {
    return []
  }
  const monthPipe: MonthPipe = new MonthPipe();
  const arr = []
  deti?.map((dite) => dite.uplatneni?.map((uplatneni) => arr.push({
    jmeno: dite.jmeno,
    prijmeni: dite.prijmeni,
    rodneCislo: birthNumberOrBirthDate(dite.rodneCisloADatumNarozeni),
    uplatnujiNarok: applyChildren(uplatneni.narokVeVysi),
    ztpp: uplatneni.ztpp ? 'X' : '',
    dospelost: uplatneni.zletileDite ? 'X' : '',
    dodatecneMesice: monthPipe.transform(uplatneni.mesice)
  })))
  return arr
}
