import { z } from 'zod';
import { Address, RodneCislo } from './date-only';


export const AnnualTaxSettlementV2024Evaluation = z.object({
  potvrtzeniOPrijmech: z.boolean().nullish(),
  vyhodnoceni: z.object({
    zadamORocniZuctovaniDane: z.boolean().nullish(),
    mamPodepsaneProglaseniPoplatnika: z.boolean().nullish(),
    nemelJsemSoubeznePrijmy: z.boolean().nullish(),
    nemamJinePrijmy: z.boolean().nullish(),
    nebylJsemOsvc: z.boolean().nullish(),
    nebyloMiVyplacenoPojistne: z.boolean().nullish(),
  }),
})

export const AnnualTaxSettlementV2024Residency = z.object({
  rezidentura: z.string().nullish(),
  neRezident: z.object({
    rezidenturaVeState: z.string().nullish(),
    identifikace: z.string().nullish(),
    vydalStat: z.string().nullish(),
    typDokladu: z.string().nullish(),
    cisloDokladu: z.string().nullish(),
  }).nullish()
});

export const AnnualTaxSettlementV2024PersonalInfo = z.object({
  jmeno: z.string().nullish(),
  prijmeni: z.string().nullish(),
  titulPred: z.string().nullish(),
  titulZa: z.string().nullish(),
  rodnePrijmeni: z.string().nullish(),
  statniPrislusnost: z.string().nullish(),
  mistoNarozeni: z.string().nullish(),
  mamDruhouStatniPrislusnost: z.boolean().nullish(),
  druhaStatniPrislusnost: z.string().nullish(),
  rodneCisloADatumNarozeni: RodneCislo,
  permanentniAdresa: Address
})


export const AnnualTaxSettlementV2024AnotherEmployments = z.object({
  typ: z.string().nullish(),
  zamestnavatel: z.object({
    nazev: z.string().nullish(),
    adresa: Address.nullish()
  }).nullish(),
  mesice: z.number().nullish(),
});

export const AnnualTaxSettlementV2024TaxDiscounts = z.object({
  dary: z.object({
    aplikuji: z.boolean().nullish(),
    hodnota: z.number().nullish().array().nullish()
  }).nullish(),
  uver: z.object({
    aplikuji: z.boolean().nullish(),
    hodnota: z.object({
      ucelUvery: z.string().nullish(),
      uplatnovanaCastka: z.number().nullish(),
      varianta: z.string().nullish(),
      adresa: Address,
      uplatnujiOstatniOsoby: z.boolean().nullish(),
      ostatniOsoby: z.object({
        jmeno: z.string().nullish(),
        prijmeni: z.string().nullish(),
        rodneCisloADatumNarozeni: RodneCislo.nullish(),
        adresa: Address.nullish()
      }).array().nullish()
    }).array().nullish().transform(x => x === null ? undefined : x)
  }).nullish(),
  penzijniPripojisteni: z.object({
    aplikuji: z.boolean().nullish(),
    hodnota: z.object({
      penzijniPripojisteni: z.number().nullish().transform(x => x === null ? undefined : x),
      doplnkovePenzijniSporeni: z.number().nullish().transform(x => x === null ? undefined : x),
      penzijniPojisteni: z.number().nullish().transform(x => x === null ? undefined : x),
    }).nullish().transform(x => x === null ? undefined : x)
  }).nullish(),
  zivotniPojesteni: z.object({ aplikuji: z.boolean().nullish(), hodnota: z.number().nullish() }).nullish(),
  dlouhodobyInvesticniProdukt: z.object({
    aplikuji: z.boolean().nullish(),
    hodnota: z.number().nullish().transform(x => x === null ? undefined : x)
  }).nullish(),
  pojisteniDlouhodobePece: z.object({
    aplikuji: z.boolean().nullish(),
    hodnota: z.number().nullish().transform(x => x === null ? undefined : x)
  }).nullish(),
  slevaNaPartnera: z.object({
    aplikuji: z.boolean().nullish(), hodnota: z.object({
      jmeno: z.string().nullish(),
      prijmeni: z.string().nullish(),
      rodneCisloADatumNarozeni: RodneCislo.nullish(),
      zaMesice: z.number().nullish(),
      ztpp: z.boolean().nullish(),
      ztppZaMesice: z.number().nullish(),
      deti: z.object({
        jmeno: z.string().nullish(),
        prijmeni: z.string().nullish(),
        rodneCisloADatumNarozeni: RodneCislo.nullish(),
      }).array().nullish()
    }).nullish().transform(x => x === null ? undefined : x)
  }).nullish(),
  exekuce: z.object({
    aplikuji: z.boolean().nullish(),
    hodnota: z.number().array().nullish().transform(x => x === null ? undefined : x)
  }).nullish()
});


export const AnnualTaxSettlementAdditionalApplication = z.object({
  dodatecneUplatneni: z.boolean().nullish(),
  zakladniSlevaNaPoplatnika: z.boolean().nullish(),
  rezident: z.object({
    zakladniSlevaNaInvalidituMesice: z.number().nullish(),
    rozsirenaSlevaNaInvalidituMesice: z.number().nullish(),
    slevaNaDrziteleZTPPMesice: z.number().nullish(),
    deti: z.object({
      jmeno: z.string().nullish(),
      prijmeni: z.string().nullish(),
      rodneCisloADatumNarozeni: RodneCislo,
      uplatneni: z.object({
        narokVeVysi: z.string().nullish(),
        ztpp: z.boolean().nullish(),
        zletileDite: z.boolean().nullish(),
        mesice: z.number().nullish(),
      }).array().nullish()
    }).array().nullish(),
    dalsiPoplatnik: z.boolean().nullish(),
    poplatnik: z.object({
      jmeno: z.string().nullish(),
      prijmeni: z.string().nullish(),
      rodneCisloADatumNarozeni: RodneCislo,
      adresa: Address,
      uplatnuje: z.boolean().nullish(),
      zamestnani: z.boolean().nullish(),
      zamestnavatel: z.object({
        nazev: z.string().nullish(),
        adresa: Address.nullish(),
      }).nullish()
    }).nullish()
  }).nullish()
})



export const AnnualTaxSettlementV2024Dto = z.object({
  version: z.literal('2024'),
  vyhodnoceni: AnnualTaxSettlementV2024Evaluation,
  osobniCast: AnnualTaxSettlementV2024PersonalInfo.nullish().transform(x => x === null ? undefined : x),
  rezidentura: AnnualTaxSettlementV2024Residency.nullish().transform(x => x === null ? undefined : x),
  dalsiZamestnavatele: AnnualTaxSettlementV2024AnotherEmployments.array().transform(x => x === null ? undefined : x),
  slevy: AnnualTaxSettlementV2024TaxDiscounts.nullish().transform(x => x === null ? undefined : x),
  dodatecneUplatneni: AnnualTaxSettlementAdditionalApplication.nullish().transform(x => x === null ? undefined : x),
})
export type AnnualTaxSettlementV2024Dto = z.infer<typeof AnnualTaxSettlementV2024Dto>
