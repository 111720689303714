import {
  Directive,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormlyConfig, FormlyFormOptions } from '@ngx-formly/core';
import { filter, tap } from 'rxjs/operators';
import { BaseForm } from './base-form';

import {
  flattenObject,
  getFormValidationErrors,
  IFormError,
  sortObjectProp,
  taxDeclarationV2,
  unFlattenObject,
  zEmpTabsFormModelV2
} from '@tpa/shared';
import moment from 'moment';

@UntilDestroy()
@Directive()
export abstract class MultiStepForm<T extends Record<string, unknown>>
  extends BaseForm<T>
  implements OnInit {
  /**
   *  Flag for skipping emit submit event to parent
   *  This is used as false when we want to just validate but not submit form
   *  @type {boolean}
   */
  get skippable(): boolean {
    return false || this.hideStep;
  }

  get hideStep(): boolean {
    return !this.options.formState.isGuideActive;
  }

  abstract nameKey: string;

  // stripMask = z.object({});
  stripMask = zEmpTabsFormModelV2;

  options: FormlyFormOptions = {
    formState: {
      stepIndex: 0,
      full: true,
      ownerId: 0,
      // condition for apply form v2 after the start of validity
      versionV2: taxDeclarationV2.isBefore(moment()),
      getForm: () => {
        this.callSubmit();
        return true;
      },
    },
  };

  @ViewChild('formElement') formElement: ElementRef | undefined;
  debounce = true;

  submitFormIfValidFlag = true;
  formErrors: IFormError[] = [];

  @Input() set value(inputValue: T) {
    // same value not patch
    // console.log(value);
    try {
      const value = JSON.stringify(sortObjectProp(flattenObject(inputValue)));
      const model = JSON.stringify(
        sortObjectProp(
          flattenObject(this.stripMask.strip().parse(this.model || {}))
        )
      );
      if (value === model) return;

      // if null
      if (inputValue != null) {
        // console.log(value, JSON.stringify(value) === JSON.st=ringify(this.model));
        // console.log('stting value to form', value);
        this.model = unFlattenObject<T>(JSON.parse(value));
      }
      if (inputValue == null) {
        console.error('Dont push null to formlyform empty object is better {}');
        this.model = {} as T;
      }

      this.loading = false;
      this.afterNewValueInput(this.model as T);
    } catch (e) {
      console.error(e, inputValue);
    }
    // reset model
    // breaks formAraaayChanges
    // this.options.resetModel && this.options.resetModel();
  }

  _isActive = false;

  @Input() set isActive(value: boolean) {
    this._isActive = value;
    this.styleHide = !value;
  }

  get isActive() {
    return this._isActive;
  }
  @HostBinding('class.hidden') styleHide = true;

  @Input() set ownerId(value: number) {
    this.options.formState.ownerId = value;
  }

  @Input() set allTooltipsExpression(value: {
    isGuideActive?: boolean;
    hideLegalTexts?: boolean;
    hidePersonalPart?: boolean;
    disablePersonalPart?: boolean;
    hideTaxDeclarationPart?: boolean;
    displayTooltipA1?: boolean;
    displayZTPP?: boolean;
    displayZZ?: boolean;
    displayInvalidniDuchod?: boolean;
    displayStarobniDuchod?: boolean;
    displayPredcasnyDuchod?: boolean;
    displayJinyDuchod?: boolean;
    displayRezident?: boolean;
    displayNerezident?: boolean;
    displayRezidenturaPoradit?: boolean;
    displayExekuce?: boolean;
    displaySlevaNaStudenta?: boolean;
    displayNoveDite?: boolean;
    displayDalsiPoplatnikPracujici?: boolean;
    displayDalsiPoplatnikNezamestnany?: boolean;
    displayrozvedenySDetma?: boolean;
    displayZenatyBezPartneraVDomacnosti?: boolean;
    displaydetiStudent?: boolean;
    displaydetiZTP?: boolean;
    displayZTPPProProhlaseni?: boolean;
    displayZZProProhlaseni?: boolean;
  }) {
    if (value)
      this.options.formState = {
        ...this.options.formState,
        isGuideActive: !!value.isGuideActive,
        hideLegalTexts: !!value.hideLegalTexts,
        hidePersonalPart: !!value.hidePersonalPart,
        disablePersonalPart: !!value.disablePersonalPart,
        hideTaxDeclarationPart: !!value.hideTaxDeclarationPart,
        displayTooltipA1: value.displayTooltipA1 || false,
        displayZTPP: value.displayZTPP || false,
        displayZZ: value.displayZZ || false,
        displayInvalidniDuchod: value.displayInvalidniDuchod || false,
        displayStarobniDuchod: value.displayStarobniDuchod || false,
        displayPredcasnyDuchod: value.displayPredcasnyDuchod || false,
        displayJinyDuchod: value.displayJinyDuchod || false,
        displayRezident: value.displayRezident || false,
        displayNerezident: value.displayNerezident || false,
        displayRezidenturaPoradit: value.displayRezidenturaPoradit || false,
        displayExekuce: value.displayExekuce || false,
        displaySlevaNaStudenta: value.displaySlevaNaStudenta || false,
        displayNoveDite: value.displayNoveDite || false,
        displayDalsiPoplatnikPracujici:
          value.displayDalsiPoplatnikPracujici || false,
        displayDalsiPoplatnikNezamestnany:
          value.displayDalsiPoplatnikNezamestnany || false,
        displayrozvedenySDetma: value.displayrozvedenySDetma || false,
        displayZenatyBezPartneraVDomacnosti:
          value.displayZenatyBezPartneraVDomacnosti || false,
        displaydetiStudent: value.displaydetiStudent || false,
        displaydetiZTP: value.displaydetiZTP || false,
        displayZTPPProProhlaseni: value.displayZTPPProProhlaseni || false,
        displayZZProProhlaseni: value.displayZZProProhlaseni || false,
      };
  }

  canDeactivateKey: string | undefined = undefined;

  constructor(model: T, protected config: FormlyConfig) {
    super();
    this.loading = true;
  }

  ngOnInit(): void {
    this.form.valueChanges
      .pipe(
        untilDestroyed(this),
        filter(() => this.isActive),
        tap(() => {
          this.forceSubmit();
        })
      )
      .subscribe();
  }

  onFormSubmit(model: T | undefined) {
    this.formErrors = [];
    if (model && this.form.valid && !this.loading) {
      if (this.submitFormIfValidFlag) this.submit(model);
    } else if (!this.submitFormIfValidFlag) {
      this.formErrors = getFormValidationErrors(this.fields, this.config);
    } else if (!this.loading) {
      this.validationFailed.emit();
      this.formErrors = getFormValidationErrors(this.fields, this.config);
    }
    this.submitFormIfValidFlag = true;
  }

  forceSubmit() {
    if (this.model) this.submit(this.model);
  }

  /**
   * This function is for modifying the model after validation before emiting
   * @param model
   */
  protected submit(model: T) {
    this.formSubmit.emit(
      JSON.parse(JSON.stringify(this.stripMask.strip().parse(model))) as T
    );
  }

  public validate(submitFormIfValid: boolean = false) {
    this.form.updateValueAndValidity()
    if (this.formElement && this.debounce) {
      if (!submitFormIfValid) this.submitFormIfValidFlag = false;
      setTimeout(() => (this.debounce = true), 500);
      this.formElement?.nativeElement.dispatchEvent(
        new Event('submit', { cancelable: true })
      );
    } else {
      // console.log('debounce');
    }
  }
}
