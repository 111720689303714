import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { FormlyModule } from '@ngx-formly/core';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { RalbaUiModule } from '@ralba/ui';
import {
  FORMLY_CORE_TYPES,
  FORMLY_CORE_WRAPPERS,
  RAL_FORMLY_INPUTS
} from './formly';
import { EmployeesFormPipesModule } from '@tpa/shared';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, FormlyModule.forChild()],
  exports: [ReactiveFormsModule, FormlyModule],
})
export class SharedRalbaFormlyModule {}

@NgModule({
  imports: [
    CommonModule,
    RalbaUiModule,
    ReactiveFormsModule,
    FormlySelectModule,
    TranslocoModule,
    EmployeesFormPipesModule,
    FormlyModule.forRoot({
      types: FORMLY_CORE_TYPES,
      wrappers: FORMLY_CORE_WRAPPERS,
    }),
  ],
  declarations: [...RAL_FORMLY_INPUTS],
})
export class RalbaFormlyModule {}
