import { translate } from '@ngneat/transloco';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { sharedAddress } from './address.form';
import { specialCharsParser } from './utils';

export const partnerForm = (guide = false): FormlyFieldConfig[] => [
  {
    key: 'dalsiPoplatnik',
    type: 'yes-or-no',
    className: 'p-col-12',
    wrappers: ['yes-or-no-form-field'],

    props: {
      translate: true,
      yesText: 'TPA.Yes',
      noText: 'TPA.No',
      label: 'TPA.NextTaxpayer',
      required: true,
      description: 'TPA.ChildrenNextTaxpayerInfo'
    },
    expressionProperties: {
      'props.required':
        '(formState.hideBasedOnProhlaseniKProhlaseni() || formState.hideBasedOnChildrenDiscountQuestion())'
    }
  },
  {
    expressions: {
      hide: (field) => field?.parent?.model?.dalsiPoplatnik != true
    },
    fieldGroup: [
      {
        key: 'jmenoPoplatnika',
        type: 'input',
        className: 'p-col-12 p-md-6',
        parsers: [specialCharsParser],
        modelOptions: {
          updateOn: 'blur'
        },
        props: {
          label: 'TPA.NextTaxpayerFirstName',
          translate: true,
          required: true
        }
      },
      {
        key: 'prijmeniPoplatnika',
        type: 'input',
        className: 'p-col-12 p-md-6',
        parsers: [specialCharsParser],
        modelOptions: {
          updateOn: 'blur'
        },
        props: {
          label: 'TPA.NextTaxpayerSurname',
          translate: true,
          required: true
        }

      },

      { key: 'rodneCisloADatumNarozeniPoplatnika', type: 'birth-number-and-birth-date' },
      {
        className: 'p-col-12',
        template: `<p class="description">${translate('TPA.NextTaxpayerPermanentAddress')}</p>`
      },
      {
        key: 'trvalyPobytPoplatnika',
        fieldGroup: sharedAddress(),
        className: 'p-col-12',
        parsers: [specialCharsParser],
        props: {
          label: 'TPA.NextTaxpayerPermanentAddress',
          translate: true,
          required: true
        }

      },
      {
        key: 'uplatnujePoplatnik',
        type: 'yes-or-no',
        className: 'p-col-12 p-md-6',
        props: {
          translate: true,
          required: true,
          yesText: 'TPA.Yes',
          noText: 'TPA.No',
          label: 'TPA.NextTaxpayerApplyDiscount'
        }
      },
      {
        key: 'zamestnaniPoplatnika',

        type: 'yes-or-no',
        className: 'p-col-12 p-md-6',
        props: {
          translate: true,
          yesText: 'TPA.Yes',
          noText: 'TPA.No',
          required: true,
          label: 'TPA.NextTaxpayerIsEmployed',
          ...(guide
            ? {
              description: 'TPA.PartnerInfoDescrioption'
            }
            : {})
        }
      },
      {
        expressions: {
          hide: (field) =>
            field?.parent?.model?.zamestnaniPoplatnika != true
        },
        fieldGroup: [
          {
            key: 'platceDanePoplatnika',
            type: 'input',
            className: 'p-col-12',
            parsers: [specialCharsParser],
            modelOptions: {
              updateOn: 'blur'
            },
            props: {
              label: 'TPA.NextTaxpayerEmployer',
              translate: true,
              required: true
            }
          },
          {
            className: 'p-col-12',
            template: `<p class="description">${translate('TPA.NextTaxpayerEmployerAddress')}</p>`
          },
          {
            key: 'adresaPlatceDanePoplatnika',
            fieldGroup: sharedAddress(),
            className: 'p-col-12',
            props: {
              label: 'TPA.NextTaxpayerEmployerAddress',
              translate: true,
              required: true
            }
          }
        ]
      }
    ]
  }

];
