import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'formly-wrapper-form-field',
  template: `
    <ng-container #fieldComponent></ng-container>
    <div class="ui-messages-error" *ngIf="showError">
      <formly-validation-message [field]="field"></formly-validation-message>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JustErrorFormWrapper extends FieldWrapper {}
