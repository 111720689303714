
import { AnnualTaxSettlementV2024Dto, CompanyAddressDto, RzdMetadataDto } from "@tpa/api";
import { AnnualTaxSettlement2024PrintTemplateData } from "../models/tax-settlement-2024-print-template.model";
import { MonthPipe } from "../pipes";
import { birthNumberOrBirthDate, formatAsCurrency, formatDate, getCountry, toPrintAddress } from "./print-formatters.util";

interface Address {
  mesto: string;
  zeme: string;
  ulice: string;
  popCislo: string;
  orCislo: string;
  psc: string;
}

export const fromTaxSettlementModelToPrintTemplate = (
  data: AnnualTaxSettlementV2024Dto | null, podpisMzdovka: RzdMetadataDto | null, year: string,
): AnnualTaxSettlement2024PrintTemplateData | null => {
  const monthPipe: MonthPipe = new MonthPipe();

  if (data === null || podpisMzdovka === null) {
    return null
  }

  return {
    verze: year,
    identifikacePoplatnika: {
      jmeno: data.osobniCast?.jmeno ?? '',
      prijmeni: data.osobniCast?.prijmeni ?? '',
      rodneCisloNeboDatumNarozeni: birthNumberOrBirthDate(data.osobniCast?.rodneCisloADatumNarozeni),
      nazev: taxPayerNameWithAddress(podpisMzdovka)
    },
    predchoziZamestnavatele: data.dalsiZamestnavatele.slice(1)?.filter((z) => z.typ === 'zamestnavatel').map((z) => ({ mesice: monthPipe.transform(z.mesice ?? ''), nazev: `${z.zamestnavatel?.nazev ?? ''} ${toPrintAddress(z.zamestnavatel.adresa)}` })) ?? [],
    slevy: {
      dary: { uplatnuji: data.slevy?.dary?.aplikuji ? 'X' : '', castka: formatAsCurrency(sum(data.slevy?.dary?.hodnota ?? [])) },
      urokyZUveru: { uplatnuji: data.slevy?.uver?.aplikuji ? 'X' : '', castka: formatAsCurrency(sum(data.slevy?.uver?.hodnota?.map((s) => s.uplatnovanaCastka ?? 0))) },
      prispevekPenzijniPripojisteni: {
        uplatnuji: (data.slevy?.penzijniPripojisteni?.aplikuji && data.slevy?.penzijniPripojisteni?.hodnota?.penzijniPripojisteni) ? 'X' : '',
        castka: formatAsCurrency(data.slevy?.penzijniPripojisteni?.hodnota?.penzijniPripojisteni)
      },
      prispevekDoplnkovePenzijniSporeni: {
        uplatnuji: (data.slevy?.penzijniPripojisteni?.aplikuji && data.slevy?.penzijniPripojisteni?.hodnota?.doplnkovePenzijniSporeni) ? 'X' : '',
        castka: formatAsCurrency(data.slevy?.penzijniPripojisteni?.hodnota?.doplnkovePenzijniSporeni)
      },
      prispevekPenzijniPojisteni: {
        uplatnuji: (data.slevy?.penzijniPripojisteni?.aplikuji && data.slevy?.penzijniPripojisteni?.hodnota?.penzijniPojisteni) ? 'X' : '',
        castka: formatAsCurrency(data.slevy?.penzijniPripojisteni?.hodnota?.penzijniPojisteni)
      },
      prispevekSoukromeZivotniPojisteni: { uplatnuji: data.slevy?.zivotniPojesteni?.aplikuji ? 'X' : '', castka: formatAsCurrency(data.slevy?.zivotniPojesteni?.hodnota) },
      prispevekDlouhodobyInvesticniProdukt: { uplatnuji: data.slevy?.dlouhodobyInvesticniProdukt?.aplikuji ? 'X' : '', castka: formatAsCurrency(data.slevy?.dlouhodobyInvesticniProdukt?.hodnota) },
      prispevekDlouhodobaPece: { uplatnuji: data.slevy?.pojisteniDlouhodobePece?.aplikuji ? 'X' : '', castka: formatAsCurrency(data.slevy?.pojisteniDlouhodobePece?.hodnota) }
    },
    uplatnujiJineOsoby: applyOtherPeople(data),
    uverAdresa: realEstateAddress(data),
    pred2021: before2021(data),
    po2021: after2021(data),
    nahradaExekutora: data.slevy?.exekuce?.aplikuji ? sum(data.slevy?.exekuce?.hodnota ?? []) : '',
    udajeOManzelovi: data.slevy?.slevaNaPartnera?.aplikuji ? {
      jmeno: data.slevy?.slevaNaPartnera?.hodnota?.jmeno ?? ' ',
      prijmeni: data.slevy?.slevaNaPartnera?.hodnota?.prijmeni ?? ' ',
      rodneCisloNeboDatumNarozeni: birthNumberOrBirthDate(data.slevy?.slevaNaPartnera?.hodnota?.rodneCisloADatumNarozeni),
      narokSplnenZaMesice: monthPipe.transform(data.slevy?.slevaNaPartnera?.hodnota?.zaMesice ?? ' '),
      ztpp: data.slevy?.slevaNaPartnera?.hodnota?.ztpp ? 'X' : ' ',
      narokSplnenZaMesiceZtpp: monthPipe.transform(data.slevy?.slevaNaPartnera?.hodnota?.ztppZaMesice ?? ' ')
    } : null,
    deti: data.slevy?.slevaNaPartnera?.aplikuji ? children(data) : [],
    dodatecneUplatneni: data.dodatecneUplatneni?.dodatecneUplatneni ? "X" : "",
    podpisPoplatnik: { jmenoAPrijmeni: (data.osobniCast?.jmeno ? data.osobniCast?.jmeno + '' : '') + ' ' + (data.osobniCast?.prijmeni ? data.osobniCast?.prijmeni + '' : ''), datum: formatDate(podpisMzdovka.signedAt) ?? '' },
    podpisMzdovka: podpisMzdovka.accountantName ? { jmenoAPrijmeni: podpisMzdovka.accountantName ?? '', datum: formatDate(podpisMzdovka.accountSignDate) ?? '' } : null
  }
}



function taxPayerNameWithAddress(metadata: RzdMetadataDto) {
  const parts = []
  if (metadata.tenantName) {
    parts.push(metadata.tenantName)
  }
  if (metadata?.companyAddress && taxPayerAddress(metadata?.companyAddress)) {
    parts.push(taxPayerAddress(metadata.companyAddress))
  }
  if (parts.length) {
    return parts.join(", ")
  }
  return ''
}

function taxPayerAddress(companyAddress: CompanyAddressDto) {
  const parts: string[] = [];
  if (companyAddress.street && companyAddress.descriptiveNumber) {
    parts.push(`${companyAddress.street} ${companyAddress.descriptiveNumber}`)
  }

  if (companyAddress.orientationNumber)
    parts[0] = `${parts[0]}/${companyAddress.orientationNumber}`;

  if (companyAddress.placeOfResidence || companyAddress.postalCode) {
    const parr2 = [];
    if (companyAddress.placeOfResidence) {
      parr2.push(companyAddress.placeOfResidence)
    }
    if (companyAddress.postalCode) {
      parr2.push(companyAddress.postalCode)
    }

    if (parr2.length > 0)
      parts.push(parr2.join(" "))
  }
  if (companyAddress.country) {
    parts.push(getCountry(companyAddress.country))
  }

  return parts.join(", ");
}


function sum(arr: number[]) {
  return arr && arr.length ? arr.reduce((acc, current) => acc + current, 0) + '' : ''
}
function applyOtherPeople(data: AnnualTaxSettlementV2024Dto) {
  const arr = []
  data.slevy?.uver?.hodnota?.forEach((h) => h.ostatniOsoby?.forEach((o) => {
    if (!arr.includes(o)) {
      arr.push({
        jmeno: o.jmeno ?? '',
        prijmeni: o.prijmeni ?? '',
        adresaBydliste: toPrintAddress(o.adresa),
        rodneCisloNeboDatumNarozeni: birthNumberOrBirthDate(o.rodneCisloADatumNarozeni)
      })
    }
  }))
  return arr
}

function realEstateAddress(data: AnnualTaxSettlementV2024Dto) {
  const arr = []
  data.slevy?.uver?.hodnota?.forEach((h) => { if (!arr.includes(h.adresa)) { arr.push(h.adresa) } })
  if (arr.length) {
    return arr.map((a) => toPrintAddress(a)).join('; ')
  }
  return ''
}


function before2021(data: AnnualTaxSettlementV2024Dto) {
  let found = false
  data.slevy?.uver?.hodnota?.forEach((h) => { if (h.varianta === 'pred_2021') { found = true } })
  if (found) {
    return 'X'
  }
  return null
}

function after2021(data: AnnualTaxSettlementV2024Dto) {
  let found = false
  data.slevy?.uver?.hodnota?.forEach((h) => { if (h.varianta === 'po_2021') { found = true } })
  if (found) {
    return 'X'
  }
  return null
}

function children(data: AnnualTaxSettlementV2024Dto) {
  return data.slevy?.slevaNaPartnera?.hodnota?.deti?.map((d) => ({ jmeno: d.jmeno ?? '', prijmeni: d.prijmeni ?? '', rodneCisloNeboDatumNarozeni: birthNumberOrBirthDate(d.rodneCisloADatumNarozeni) }))
}
