import { AnnualTaxSettlementV2020 } from './annual-tax-settlement-v2020';
import { AnnualTaxSettlementV2024Dto } from './annual-tax-settlement-v2024-dto';
import { z } from 'zod';

export { AnnualTaxSettlementV2020 } from './annual-tax-settlement-v2020';
export { AnnualTaxSettlementV2024Dto } from './annual-tax-settlement-v2024-dto';


export const AnnualTaxSettlementV = z.discriminatedUnion('version', [AnnualTaxSettlementV2020, AnnualTaxSettlementV2024Dto]);

export type AnnualTaxSettlementV = z.infer<typeof AnnualTaxSettlementV>

