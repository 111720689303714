import { Injectable } from '@angular/core';
import { EntityDto, Rest, RestService } from '@ralba/shared';
import {
  CompanyDto,
  GetTenantFeaturesEditOutput,
  UpdateCompanyInput,
  UpdateTenantFeaturesInput,
  CompanyEmployeeCardOptionsDto, TenancyRequestDto
} from '../dto';

@Injectable({ providedIn: 'root' })
export class EmployeeCardOptionsHttpApiService {
  constructor(private rest: RestService) {}

  public getEmployeeCardOptions(params: TenancyRequestDto ) {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: '/api/employee/employee-card-options/get',
      params,
    };
    return this.rest.request<
      null,
      Rest.AbpResponse<CompanyEmployeeCardOptionsDto>
    >(request);
  }

}
