import { TaxDeclaration2024PrintTemplate } from "./tax-declaration-2024-print-template.model"

export type AnnualTaxSettlementAndTaxDeclaration2024PrintTemplate = {
  taxSettlement: AnnualTaxSettlement2024PrintTemplate
  taxDeclaration: TaxDeclaration2024PrintTemplate
}

export type AnnualTaxSettlement2024PrintTemplate = {
  data: AnnualTaxSettlement2024PrintTemplateData
  text: AnnualTaxSettlement2024PrintTemplateText
}

export type AnnualTaxSettlement2024PrintTemplateData = {
  verze: string,
  identifikacePoplatnika: {
    jmeno: string,
    prijmeni: string,
    rodneCisloNeboDatumNarozeni: string
    nazev: string
  },
  predchoziZamestnavatele: {
    mesice: string
    nazev: string
  }[]
  slevy: {
    dary: {
      uplatnuji: string
      castka: string
    }
    urokyZUveru: {
      uplatnuji: string
      castka: string
    }
    prispevekPenzijniPripojisteni: {
      uplatnuji: string
      castka: string
    }
    prispevekDoplnkovePenzijniSporeni: {
      uplatnuji: string
      castka: string
    }
    prispevekPenzijniPojisteni: {
      uplatnuji: string
      castka: string
    }
    prispevekSoukromeZivotniPojisteni: {
      uplatnuji: string
      castka: string
    }
    prispevekDlouhodobyInvesticniProdukt: {
      uplatnuji: string
      castka: string
    }
    prispevekDlouhodobaPece: {
      uplatnuji: string
      castka: string
    }
  }
  uplatnujiJineOsoby: {
    jmeno: string,
    prijmeni: string
    rodneCisloNeboDatumNarozeni: string
    adresaBydliste: string
  }[]
  uverAdresa: string
  pred2021: string | null
  po2021: string | null
  nahradaExekutora: string
  udajeOManzelovi: {
    jmeno: string
    prijmeni: string
    rodneCisloNeboDatumNarozeni: string
    narokSplnenZaMesice: string
    ztpp: string
    narokSplnenZaMesiceZtpp: string
  } | null
  deti: {
    jmeno: string,
    prijmeni: string,
    rodneCisloNeboDatumNarozeni: string
  }[]
  dodatecneUplatneni: string
  podpisPoplatnik: {
    jmenoAPrijmeni: string
    datum: string
  }
  podpisMzdovka: {
    jmenoAPrijmeni: string
    datum: string
  } | null
}


export type AnnualTaxSettlement2024PrintTemplateText = {
  title: { request: string, taxSettlement: string }
  taxpayerIdentification: { title: string, firstName: string, surname: string, birthNumber: string, name: string, firstNote: string, secondNote: string }
  requestParagraph: string,
  previousEmployments: { title: string, months: string, identifications: string, firstNote: string },
  discounts: {
    title: string,
    apply: string,
    taxBase: string,
    applyAmount: string,
    gifts: string,
    loan: string,
    taxSupportedProducts: string,
    supplementaryPensionInsurance: string,
    pensionSavings: string,
    pensionInsurance: string,
    privateLifeInsurance: string,
    longTermInvestments: string,
    longTermCare: string,
    firstNote: string,
    secondNote: string
  }
  otherPeople: {
    title: string
    paragraph: string
    apply: string
    dontApply: string
    dontHaveLoan: string
    firstName: string
    surname: string
    birthNumber: string
    address: string
    firstNote: string
    secondNote: string
    loanAddress: string
  }
  loans: {
    before2021title: string
    before2021paragraph: string
    after2021title: string
    after2021paragraph: string
    firstNote: string
    caution: string
  }
  taxDiscounts: {
    title: string
    paragraph: string
    foreclosure: {
      title: string
      amount: string
      firstNote: string
    }
    partner: {
      title: string
      name: string
      surname: string
      birthNumber: string
      claim: string
      ztpp: string
      claimZtpp: string
      firstNote: string
      secondNote: string
      thirdNote: string
      fourthNote: string
    },
    child: {
      name: string
      surname: string
      birthNumber: string
      firstNote: string
    }
  },
  signature: {
    title: string
    firstParagraph: string
    secondParagraph: string
    additionalApply: string
    request: string
    authentication: string
    term: string,
    userSigned: string
    adminSigned: string
    firstNote: string
    secondNote: string
    thirdNote: string
  }
  otherRecords: string
}


export const taxSettlement2024CzechModel: AnnualTaxSettlement2024PrintTemplateText = {
  title: { request: 'ŽÁDOST', taxSettlement: 'o roční zúčtování záloh a daňového zvýhodnění za zdaňovací období' },
  taxpayerIdentification: {
    title: 'Identifikace poplatníka',
    firstName: 'Jméno',
    surname: 'Příjmení',
    birthNumber: 'Rodné číslo',
    name: 'Identifikace plátce daně (název)',
    firstNote: 'Uveďte kalendářní rok, za který roční zúčtování záloh a daňového zvýhodnění požadujete provést.',
    secondNote: 'U cizích státních příslušníků, kteří nemají přidělené rodné číslo v České republice, uveďte datum narození.'
  },
  requestParagraph: 'V souladu s § 38ch a § 35d zákona č. 586/1992 Sb., o daních z příjmů, ve znění pozdějších předpisů (dále jen „zákon“) žádám o provedení ročního zúčtování záloh na daň a daňového zvýhodnění za uvedené zdaňovací období a současně prohlašuji, <strong> že za uvedené zdaňovací období nejsem povinen(-na) podat přiznání k dani z příjmů fyzických osob.</strong>',
  previousEmployments: {
    title: 'Uvádím, že jsem v uvedeném zdaňovacím období pobíral(-a) příjmy ze závislé činnosti od těchto předchozích plátců daně',
    months: 'V kalendářních měsících od-do',
    identifications: 'Identifikace předchozích plátců daně',
    firstNote: 'Vyplňuje se pouze v případě, že poplatník pobíral od předchozích plátců daně (u kterých učinil prohlášení k dani) postupně příjmy, které vstupují do ročního zúčtování záloh a daňového zvýhodnění, včetně mezd zúčtovaných nebo vyplacených poplatníkovi těmito plátci dodatečně v době, kdy poplatník pro ně již nevykonával činnost (§ 38ch odst. 4 zákona), kromě příjmů zdanitelných srážkou podle zvláštní sazby daně a kromě příjmů, které nejsou předmětem daně nebo jsou od daně osvobozeny.'
  },
  discounts: {
    title: 'NEZDANITELNÉ ČÁSTI ZÁKLADU DANĚ',
    apply: 'Uplatňuji',
    taxBase: 'Nezdanitelné části základu daně podle § 15 zákona',
    applyAmount: 'Uplatňovaná částka (v Kč)',
    gifts: 'Bezúplatná plnění – dary (odst. 1)',
    loan: 'Úroky z úvěru na financování bytových potřeb (odst. 3 a 4)',
    taxSupportedProducts: 'Daňově podporované produkty spoření na stáří a pojištění dlouhodobé péče (odst. 5 a 6)',
    supplementaryPensionInsurance: 'Příspěvek na penzijní připojištění se státním příspěvkem',
    pensionSavings: 'Příspěvek na doplňkové penzijní spoření',
    pensionInsurance: 'Příspěvek na penzijní pojištění u instituce penzijního pojištění',
    privateLifeInsurance: 'Příspěvek pojistného na soukromé životní pojištění',
    longTermInvestments: 'Příspěvek na majetek připsaný ve prospěch dlouhodobého investičního produktu',
    longTermCare: 'Příspěvek pojistného na pojištění dlouhodobé péče',
    firstNote: 'označte „X“ nezdanitelnou část základu daně, kterou uplatňujete.',
    secondNote: 'Při placení příspěvků na více daňově podporovaných produktů spoření na stáří a na pojištění dlouhodobé péče nelze v celkovém úhrnu uplatňovaných částek překročit maximální zákonem stanovený limit, který v součtu činí 48 000 Kč.'
  },
  otherPeople: {
    title: 'Prohlášení poplatníka podle § 38k odst. 5 písm. e) zákona k nezdanitelné části základu daně podle § 15 odst. 3 a 4 zákona:',
    paragraph: 'odpočet úroků ze základu daně, na které uplatňuji nárok z důvodu zaplacených úroků z úvěru použitého na financování bytových potřeb z úvěru ze stavebního spoření, z hypotečního úvěru a nebo z jiného úvěru poskytnutého v souvislosti s těmito úvěry stavební spořitelnou nebo bankou za uvedené zdaňovací období',
    apply: '<s><strong>neuplatňuje</strong> jiná osoba</s> / <strong>uplatňují</strong> tyto osoby <sup>4)</sup>',
    dontApply: '<strong>neuplatňuje</strong> jiná osoba / <s><strong>uplatňují</strong> tyto osoby<sup></s> 4)</sup>',
    dontHaveLoan: '<strong>neuplatňuje</strong> jiná osoba / <strong>uplatňují</strong> tyto osoby<sup> 4)</sup>',
    firstName: 'Jméno',
    surname: 'Příjmení',
    birthNumber: 'Rodné číslo',
    address: 'Adresa bydliště (místo trvalého pobytu)',
    firstNote: 'tabulku vyplňte pouze v případě, že úroky uplatňuje i jiná osoba/osoby',
    secondNote: 'U cizích státních příslušníků, kteří nemají přidělené rodné číslo v České republice, uveďte datum narození.',
    loanAddress: 'Uveďte adresu(-y), kde se nachází bytová potřeba, na kterou byl(-y) úvěr(-y) poskytnut(-y):'
  },
  loans: {
    before2021title: 'Pro úroky z úvěrů použitých na financování bytových potřeb obstaraných před 1. lednem 2021 a refinancování těchto úvěrů',
    before2021paragraph: 'Prohlašuji, že předmět bytové potřeby podle § 15 odst. 3 zákona, ve znění zákona před 1. lednem 2021, na který uplatňuji odpočet úroků z poskytnutého úvěru, byl užíván v souladu s § 15 odst. 4 zákona ve znění zákona před 1. lednem 2021, a že částka úroků, o kterou se snižuje základ daně podle § 15 odst. 3 a 4 zákona ve znění zákona před 1. lednem 2021 v úhrnu u všech účastníků smluv o úvěrech žijících se mnou ve společně hospodařící domácnosti, nepřekročila v uplynulém zdaňovacím období částku 300 000 Kč. Při placení úroků jen po část roku uplatňovaná částka nepřekročila jednu dvanáctinu této maximální částky za každý měsíc placení úroků.',
    after2021title: 'Pro úroky z úvěrů použitých na financování bytových potřeb obstaraných od 1. ledna 2021 a refinancování těchto úvěrů',
    after2021paragraph: 'Prohlašuji, že předmět bytové potřeby podle § 4b odst. 1 zákona, na který uplatňuji odpočet úroků z poskytnutého úvěru, byl užíván v souladu s § 15 odst. 3 a 4 zákona, a že částka úroků, o kterou se snižuje základ daně podle § 15 odst. 4 zákona v úhrnu u všech účastníků smluv o úvěrech žijících se mnou ve společně hospodařící domácnosti, nepřekročila v uplynulém zdaňovacím období částku 150 000 Kč. Při placení úroků jen po část roku uplatňovaná částka nepřekročila jednu dvanáctinu této maximální částky za každý měsíc placení úroků.',
    firstNote: 'označte „X“ odpovídající variantu (úvěry).',
    caution: '<strong>Upozornění:</strong> : Při kombinaci výše uvedených úvěrů nelze překročit maximální zákonem stanovený limit, který v součtu činí 300 000 Kč.'
  },
  taxDiscounts: {
    title: 'SLEVY NA DANI',
    paragraph: 'Kromě slevy na dani a daňového zvýhodnění uplatněného v Prohlášení poplatníka daně z příjmů fyzických osob ze závislé činnosti učiněného podle § 38k odst. 4, případně odst. 7 zákona, uplatňuji za výše uvedené zdaňovací období nárok na:',
    foreclosure: {
      title: '<strong>Slevu za zastavenou exekuci</strong> podle § 35 odst. 4 zákona ve výši náhrady přiznané exekutorem při zastavení exekuce.',
      amount: 'Výše exekutorem přiznané náhrady (v Kč)',
      firstNote: 'Sleva za jednu zastavenou exekuci může maximálně činit 450 Kč (30 % z 1500 Kč).'
    },
    partner: {
      title: '<p class="mb-1"><strong>Slevu na manžela</strong> a podle 35bb zákona a prohlašuji, že:</p> <p>1) žiji ve společně hospodařící domácnosti nejen s manželem, ale i s vyživovaným dítětem, které nedovršilo věku 3 let a současně</p> <p class="mb-3">2) manžel neměl za toto zdaňovací období vlastní příjem, přesahující částku 68 000 Kč.</p>',
      name: 'Údaje o manželovi Jméno',
      surname: 'Příjmení',
      birthNumber: 'Rodné číslo',
      claim: 'Nárok splněn za',
      ztpp: 'ZTP/P',
      claimZtpp: 'Nárok splněn za',
      firstNote: 'U cizích státních příslušníků, kteří nemají přidělené rodné číslo v České republice, uveďte datum narození',
      secondNote: 'Uveďte kalendářní měsíce, na jejichž počátku byly splněny podmínky pro uplatnění slevy na manžela, např. 9–12, v případě narození dítěte 31. 8.',
      thirdNote: 'Označte „X“, pokud byl manželovi přiznán nárok na průkaz ZTP/P',
      fourthNote: 'Uveďte kalendářní měsíce, na jejichž počátku byly splněny podmínky pro dvojnásobnou výši této slevy na dani (z titulu průkazu ZTP/P), např 11–12 v případě přiznání průkazu ZTP/P v průběhu měsíce října včetně splnění podmínky společně hospodařící domácnosti s vyživovaným dítětem, které nedovršilo věku 3 let.'
    },
    child: {
      name: 'Údaje o dítěti Jméno',
      surname: 'Příjmení',
      birthNumber: 'Rodné číslo',
      firstNote: 'U dítěte, které nemá přidělené rodné číslo v České republice, uveďte datum narození.'
    }
  },
  signature: {
    title: 'Podpisová část',
    firstParagraph: 'Potvrzuji pravdivost a úplnost údajů uvedených v této žádosti o roční zúčtování záloh a daňového zvýhodnění a prokazuji je příslušnými doklady podle § 38l zákona.',
    secondParagraph: 'Prohlašuji, že jsem si vědom(-a) skutečnosti, že případný rozdíl z nesprávně sražené daně z příjmů fyzických osob ze závislé činnosti, případně neoprávněně vyplacené částky na daňovém bonusu z poskytnutého daňového zvýhodnění, který vznikl mým zaviněním, mi bude v plné výši včetně úroku z prodlení sražen na základě dohody s plátcem daně (§ 38i odst. 5 písm. a) zákona). V případě, že k dohodě s plátcem daně o sražení dlužné částky nedojde, bude vzniklý rozdíl v souladu s § 38i odst. 5 písm. b) zákona vybrán správcem daně prostřednictvím daňového přiznání podle § 38g odst. 4 zákona.',
    additionalApply: 'Současně žádám v souladu s § 38k odst. 7 zákona o dodatečné uplatnění slev na dani / daňového zvýhodnění',
    request: 'Žádost o roční zúčtování záloh a daňového zvýhodnění učiněná <sup>8a)</sup> (písemně, elektronicky) a datum',
    authentication: ' Ověření plátcem daně <sup>8b)</sup> (písemně, elektronicky) a datum',
    term: 'Za uvedené zdaňovací období',
    userSigned: 'elektronicky podepsáno aplikací HR Portál',
    adminSigned: 'Elektronicky ověřeno aplikací HR Portál',
    firstNote: 'V případě, že v souladu s § 38k odst. 7 zákona uplatňujete slevy na dani podle § 35ba odst. 1 písm. a, c, d, e) zákona příp. daňové zvýhodnění dodatečně v rámci žádosti o roční zúčtování záloh a daňového zvýhodnění, pak označte „X“ a pro uplatnění použijte tiskopis Prohlášení poplatníka daně z příjmů fyzických osob ze závislé činnosti na příslušné zdaňovací období a postupujte při vyplnění dle „upozornění“ na str. 2 tohoto tiskopisu MFin 5457.',
    secondNote: 'Rozumí se projev vůle poplatníka, buď písemně (podpis) nebo elektronicky.',
    thirdNote: 'Plátce daně ověřil nárok na touto žádostí dotčené nezdanitelné části základu daně a slevy na dani podle zákona.',

  },
  otherRecords: 'Další záznamy'
}
